import React, { useState } from "react";
import Toggle from "react-toggle";
// import { DownArrow, UpArrow } from "../../assets/icons/Icons";
// import ReactDropdown from "react-dropdown";
// import {
//   TimeOfDay,
//   convertToMinutes,
//   generateTimeOptions,
// } from "../../lib/utils/funcs";
import { EditDaySessions } from "../../services/types";
import moment from "moment";
import { VideoAvailability } from "../../lib/utils/types";
// import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
// import { useUserData } from "../../lib/contexts/UserContext";

const EditDayToggle = ({
  day,
  dayOfWeek,
  weekSchedule,
  setSchedule,
  daySchedule,
  video,
  setDayOfWeek,
  active,
  videoSchedule
}: {
  day: string;
  dayOfWeek: number;
  weekSchedule: {
    [key: number]: EditDaySessions;
  };
  setSchedule: React.Dispatch<
    React.SetStateAction<{
      [key: number]: EditDaySessions;
    }>
  >;
  daySchedule: EditDaySessions;
  video: boolean;
  setDayOfWeek: React.Dispatch<
    React.SetStateAction<number>
  >;
  active:number;
  videoSchedule: VideoAvailability[];
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  // const [active, setActive] = useState<boolean>(true);
  console.log(daySchedule);
  

  // const { userData } = useUserData();

  // const queueOptions = [
  //   { value: "Slot_Token", label: "Slot Token" },
  //   { value: "Slot", label: "Slot" },
  //   { value: "Token", label: "Token" },
  //   { value: "Session", label: "Session" },
  // ];

  // const tokenOptions = [
  //   { value: "Even", label: "Even" },
  //   { value: "Odd", label: "Odd" },
  //   { value: "Both", label: "Both" },
  //   { value: "Multiple3", label: "Multiple of 3" },
  // ];

  // const handleChange = (
  //   value: string,
  //   dayOfWeek: number,
  //   sessionType: "morning" | "afternoon" | "evening" | string,
  //   type:
  //     | "start_time"
  //     | "end_time"
  //     | "max_patients_per_slot"
  //     | "wait_time"
  //     | "queue_type"
  //     | "max_wa_tokens"
  //     | "token_type"
  // ) => {
  //   // Create a copy of the weekSchedule
  //   const updatedWeekSchedule = { ...weekSchedule };
  //   if (type === "start_time" || type === "end_time") {
  //     // Handling time updates
  //     updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = moment(
  //       value,
  //       "hh:mm A"
  //     ).format("HH:mm:ss");
  //   } else if (type === "queue_type" || type === "max_wa_tokens") {
  //     // Handling queue type updates or WhatsApp booking limits
  //     if (
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["queue_type"] ===
  //       "Token"
  //     ) {
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["max_wa_tokens"] =
  //         null;
  //     }

  //     if (type === "max_wa_tokens") {
  //       // Ensure that value is a valid number or handle conversion
  //       console.log("value", value);
  //       const intValue = parseInt(value, 10);
  //       if (!isNaN(intValue) && intValue >= 0) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = intValue;
  //       } else {
  //         console.warn("Invalid input for max WhatsApp patients.");
  //         return; // Exit the function early if the input is invalid
  //       }
  //     } else {
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = value;
  //     }
  //   } else {
  //     // Handling data updates (max_patients_per_slot or wait_time)
  //     if (
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"] &&
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
  //     ) {
  //       const start = convertToMinutes(
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"]
  //       );
  //       const end = convertToMinutes(
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
  //       );
  //       if (type === "max_patients_per_slot" && value) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["wait_time"] = {
  //           minutes: Number(((end - start) / Number(value)).toFixed(0)),
  //         };
  //       }
  //       if (type === "wait_time" && value) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //           "max_patients_per_slot"
  //         ] = Number(((end - start) / Number(value)).toFixed(0));
  //         if (
  //           updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //             "queue_type"
  //           ] === "Token"
  //         ) {
  //           updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //             "max_wa_tokens"
  //           ] =
  //             updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //               "max_patients_per_slot"
  //             ];
  //         }
  //       }
  //       (updatedWeekSchedule[dayOfWeek].sessions[sessionType] as any)[type] =
  //         value;
  //     }
  //   }
  //   // Update the state with the new weekSchedule
  //   setSchedule(updatedWeekSchedule);
  // };

  // const shift_start: { [key: string]: string[] } = {
  //   morning: generateTimeOptions("morningEnd"),
  //   afternoon: generateTimeOptions(
  //     "afternoon",
  //     weekSchedule[dayOfWeek].sessions.morning.end_time
  //   ),
  //   evening: generateTimeOptions(
  //     "evening",
  //     weekSchedule[dayOfWeek].sessions.afternoon.end_time
  //   ),
  // };

  // const shift_end: {
  //   [key: string]: string[];
  // } = {
  //   morning: generateTimeOptions("morningEnd"),
  //   afternoon: generateTimeOptions("afternoonEnd"),
  //   evening: generateTimeOptions("eveningEnd"),
  // };

  return (
    <>
      {(daySchedule.selected || !video) && (
        <div
          className={`bg-[#F6F6F6] rounded-lg ${
            dayOfWeek === active ? "border-[1px] border-sbTextHover" : "border-sbBorder"
          }`}
          onClick={()=>{setDayOfWeek(dayOfWeek)}}
        >
          <div
            className={` p-4`}
          >
            <div className="flex flex-row justify-between">
              <div className="flex items-center">
                {!video && (
                  <Toggle
                    icons={false}
                    checked={daySchedule.selected}
                    onChange={() => {
                      setSchedule((val) => {
                        const data = val;
                        data[dayOfWeek].selected = !data[dayOfWeek].selected;
                        return { ...data };
                      });
                      setVisible(false);
                    }}
                  />
                )}
                <p
                  className={`mx-2 ${
                    daySchedule.selected ? "text-dark" : "text-docDetail"
                  }`}
                >
                  {day}
                </p>
              </div>
              {/* {daySchedule.selected && (
                <button onClick={() => setVisible(!visible)}>
                  {visible ? <UpArrow /> : <DownArrow />}
                </button>
              )} */}
            </div>
          </div>
          {daySchedule.selected && !visible && video && (
            <div className="space-y-2 border-[1px] border-t-sbBorder rounded-b-lg p-4">
              {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id).length>0 && videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id)[0].start_time !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm">Morning Session :</p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id)[0].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id)[0].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark text-sm">
                      | {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id)[0].max_consultations_per_session}{" "}
                      patients |{" "}
                      {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["morning"].availability_id)[0].max_consultation_duration.minutes} mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Morning Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
              {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id).length>0 && videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id)[0].start_time !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm truncate">
                    Afternoon Session :
                  </p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id)[0].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id)[0].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark">
                      |{" "}
                      {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id)[0].max_consultations_per_session}{" "}
                      patients |{" "}
                      {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["afternoon"].availability_id)[0].max_consultation_duration.minutes } mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Afternoon Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
              {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id).length>0 && videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id)[0].start_time  !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm">Evening Session :</p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id)[0].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                       videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id)[0].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark">
                      | {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id)[0].max_consultations_per_session}{" "}
                      patients |{" "}
                      {videoSchedule.filter((e)=>e.availability_id===daySchedule.sessions["evening"].availability_id)[0].max_consultation_duration.minutes} mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Evening Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
            </div>
          )}
          {daySchedule.selected && !visible && !video && (
            <div className="space-y-2 border-[1px] border-t-sbBorder rounded-b-lg p-4">
              {daySchedule.sessions["morning"].start_time !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm">Morning Session :</p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        daySchedule.sessions["morning"].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        daySchedule.sessions["morning"].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark text-sm">
                      | {daySchedule.sessions["morning"].max_patients_per_slot}{" "}
                      patients |{" "}
                      {daySchedule.sessions["morning"].wait_time.minutes} mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Morning Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
              {daySchedule.sessions["afternoon"].start_time !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm truncate">
                    Afternoon Session :
                  </p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        daySchedule.sessions["afternoon"].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        daySchedule.sessions["afternoon"].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark">
                      |{" "}
                      {daySchedule.sessions["afternoon"].max_patients_per_slot}{" "}
                      patients |{" "}
                      {daySchedule.sessions["afternoon"].wait_time.minutes} mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Afternoon Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
              {daySchedule.sessions["evening"].start_time !== "" ? (
                <div className="flex flex-col md:flex-row md:space-x-1">
                  <p className="text-dark text-sm">Evening Session :</p>
                  <p className="text-docDetail text-sm">
                    <span>
                      {moment(
                        daySchedule.sessions["evening"].start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                      -{" "}
                      {moment(
                        daySchedule.sessions["evening"].end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </span>{" "}
                    <span className="text-dark">
                      | {daySchedule.sessions["evening"].max_patients_per_slot}{" "}
                      patients |{" "}
                      {daySchedule.sessions["evening"].wait_time.minutes} mins
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-dark text-sm">
                  Evening Session :{" "}
                  <span className="text-docDetail">No Session</span>
                </p>
              )}
            </div>
          )}
          {daySchedule.selected && visible && (
            <div className="p-4 space-y-4">
              {/* {Object.keys(daySchedule.sessions).map((shift: string) => {
                return (
                  <div>
                    <div className="flex items-center">
                      {!video && (
                        <Toggle
                          icons={false}
                          checked={daySchedule.sessions[shift].selected}
                          onChange={() =>
                            setSchedule((val) => {
                              var data = val;
                              data[dayOfWeek].sessions[shift].selected =
                                !data[dayOfWeek].sessions[shift].selected;
                              return { ...data };
                            })
                          }
                        />
                      )}
                      {((daySchedule.sessions[shift].selected && video) ||
                        !video) && (
                        <p
                          className={`mx-2 text-sm ${
                            daySchedule.selected
                              ? "text-dark"
                              : "text-docDetail"
                          }`}
                        >
                          {shift.charAt(0).toUpperCase() + shift.slice(1)}{" "}
                          Session
                        </p>
                      )}
                    </div>
                    {daySchedule.sessions[shift].selected && (
                      <>
                        <div className="flex flex-row items-center space-x-3 mt-4">
                          <ReactDropdown
                            key={dayOfWeek}
                            options={shift_start[shift].filter((val) => {
                              const valInMinutes = convertToMinutes(val);

                              if (
                                weekSchedule[dayOfWeek].sessions[shift].end_time
                              ) {
                                const endTimeInMinutes = convertToMinutes(
                                  moment(
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .end_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                );
                                return valInMinutes < endTimeInMinutes;
                              }

                              return true;
                            })}
                            onChange={(selectedOption) =>
                              handleChange(
                                selectedOption.value,
                                dayOfWeek,
                                shift,
                                "start_time"
                              )
                            }
                            value={
                              weekSchedule[dayOfWeek].sessions[shift].start_time
                                ? moment(
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .start_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "Select Time"
                            }
                            placeholder="Select time"
                            className="w-48"
                            controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                          />
                          <p className="text-docDetail">to</p>
                          <ReactDropdown
                            key={dayOfWeek}
                            options={shift_end[shift].filter((val) => {
                              const valInMinutes = convertToMinutes(val);

                              if (
                                weekSchedule[dayOfWeek].sessions[shift]
                                  .start_time
                              ) {
                                const endTimeInMinutes = convertToMinutes(
                                  moment(
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .start_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                );
                                return valInMinutes > endTimeInMinutes;
                              }

                              return true;
                            })}
                            onChange={(selectedOption) =>
                              handleChange(
                                selectedOption.value,
                                dayOfWeek,
                                shift,
                                "end_time"
                              )
                            }
                            value={
                              weekSchedule[dayOfWeek].sessions[shift].end_time
                                ? moment(
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .end_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "Select Time"
                            }
                            placeholder="Select time"
                            className="w-48"
                            controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                          />
                        </div>
                        {userData?.role === "MasterAdmin" && (
                          <>
                            <div className="flex flex-row space-x-4">
                              <div className="flex flex-col mt-2">
                                <p className="font-light text-modalText text-sm mb-1">
                                  Queue Type
                                </p>
                                <ReactDropdown
                                  options={queueOptions}
                                  onChange={(e) => {
                                    console.log("e.value", e.value);
                                    handleChange(
                                      e.value,
                                      dayOfWeek,
                                      shift,
                                      "queue_type"
                                    );
                                  }}
                                  value={
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .queue_type
                                  }
                                  placeholder="Select Queue Type"
                                  className="w-32 md:w-44"
                                  controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                                />
                              </div>
                              {weekSchedule[dayOfWeek].sessions[shift]
                                .queue_type === "Token" && (
                                <div>
                                  <p className="font-light text-modalText text-sm mb-1 mt-2">
                                    Max whatsapp patients
                                  </p>
                                  <input
                                    type="text"
                                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-32 md:w-44"
                                    placeholder="Enter number"
                                    max={50}
                                    maxLength={2}
                                    value={
                                      weekSchedule[dayOfWeek].sessions[shift]
                                        .max_wa_tokens || ""
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        dayOfWeek,
                                        shift,
                                        "max_wa_tokens"
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            {weekSchedule[dayOfWeek].sessions[shift]
                              .queue_type === "Token" && (
                              <div className="flex flex-col mt-2">
                                <p className="font-light text-modalText text-sm mb-1">
                                  Token Type
                                </p>
                                <ReactDropdown
                                  options={tokenOptions}
                                  onChange={(e) => {
                                    handleChange(
                                      e.value,
                                      dayOfWeek,
                                      shift,
                                      "token_type"
                                    );
                                  }}
                                  value={
                                    weekSchedule[dayOfWeek].sessions[shift]
                                      .token_type
                                  }
                                  placeholder="Select Token Type"
                                  className="w-32 md:w-44"
                                  controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                                />
                              </div>
                            )}
                          </>
                        )}

                        <div className="flex flex--row mt-2">
                          <div className="flex flex-col mt-5 md:mt-0 mr-4">
                            <p className="font-light text-modalText text-sm mb-1">
                              Number of Patients
                            </p>
                            <input
                              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-32 md:w-44"
                              placeholder="Enter number"
                              disabled
                              type="text"
                              max={50}
                              maxLength={2}
                              value={
                                weekSchedule[dayOfWeek].sessions[shift]
                                  .max_patients_per_slot
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.value,
                                  dayOfWeek,
                                  shift,
                                  "max_patients_per_slot"
                                )
                              }
                            />
                          </div>
                          <div className="flex flex-col">
                            <p className="font-light text-modalText text-sm mb-1">
                              Duration For Each Patient (mins)
                            </p>
                            <input
                              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-32 md:w-44"
                              placeholder="Enter duration"
                              // disabled
                              type="text"
                              min={1}
                              max={60}
                              maxLength={2}
                              value={
                                weekSchedule[dayOfWeek].sessions[shift]
                                  .wait_time.minutes
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.value,
                                  dayOfWeek,
                                  shift,
                                  "wait_time"
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })} */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EditDayToggle;
