import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { doctorHeaders } from "../assets/data/headers";
import Loader from "../components/molecules/Loader";
import moment from "moment";
import { getRouteSegment } from "../lib/utils/funcs";
import { Search } from "../assets/icons/Icons";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useUserData } from "../lib/contexts/UserContext";
import { getDoctorAvailability, getDoctorListByHospitalId } from "../lib/apis/doctor";
import EditDocModal from "../components/organisms/modals/EditDocModal";
import { modalStyles } from "../assets/styles/modalStyles";

const Doctors = () => {
  const id = getRouteSegment(1);
  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [mapping_id, setMappingId] = useState<string | undefined>("");
  const { userData } = useUserData();
  const {
    hospitalID,
    setHospitalID,
    doctorsData,
    setDoctorsData,
    docDetails,
    setDocDetails,
  } = useHospDocData();

  const [search, setSearch] = useState("");

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

    useEffect(() => {
      const fetchDocAvailability = async () => {      
        const res = await getDoctorAvailability(String(mapping_id));
        if (res?.status === 200) {
          setDocDetails(res.data.result.doctor_details);
          if (visible === true) {
            setEditModal(true);
          }
        }
      };
      fetchDocAvailability();
    }, [visible]);

  useEffect(() => {
    const fetchdoctorsData = async () => {
      const res = await getDoctorListByHospitalId(hospitalID);
      setDoctorsData(undefined);
      if (res?.status === 200) {
        console.log("doctorsData", res.data.result);
        setDoctorsData(res.data.result);
      } else console.log(res.data.error);
    };

    fetchdoctorsData();
  }, [hospitalID, userData]);

  // Ensure this definition is at an appropriate place in your component or script
  const uniqueSpecializations = Array.from(
    new Set(
      doctorsData
        ?.sort(
          (a, b) =>
            moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
        )
        .map((doc) => doc.specialization.toLowerCase())
    )
  );

  // Function to create content for a tab based on specialization
  const createTabContent = (specialization: string) => {
    // For "All" tab, do not filter by specialization
    const filteredDoctors =
      specialization === "all"
        ? doctorsData
        : doctorsData?.filter(
            (doc) => doc.specialization.toLowerCase() === specialization
          );

    // Further filter by search query if there's any
    const filteredAndSearchedDoctors = search
      ? filteredDoctors?.filter((doc) =>
          doc.full_name.toLowerCase().includes(search.toLowerCase())
        )
      : filteredDoctors;

    // Display message if no doctors are found
    if (
      filteredAndSearchedDoctors?.length === 0 ||
      filteredAndSearchedDoctors === undefined
    ) {
      return (
        <div className="bg-white flex justify-center items-center h-96">
          <p className="text-center">No Doctors Found.</p>
        </div>
      );
    }

    // Return a table with the filtered and searched doctors
    return (
      <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
        <Table
          type="doctor"
          headers={doctorHeaders}
          docData={filteredAndSearchedDoctors?.sort(
            (a, b) =>
              moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
          )}
          setVisible={setVisible}
          setMappingId={setMappingId}
        />
      </div>
    );
  };

  const tabsConfig = [
    {
      label: "All",
      content: createTabContent("all"), // No specialization filter for "All"
    },
    ...uniqueSpecializations.map((specialization) => ({
      label: specialization.charAt(0).toUpperCase() + specialization.slice(1), // Capitalize the first letter of each specialization
      content: createTabContent(specialization),
    })),
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Doctors"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex items-center text-sm m-3 md:m-0">
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder w-4/5 bg-white">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
      </div>
      <EditDocModal
        modalIsOpen={editModal}
        closeModal={() => {setVisible(false);setEditModal(false);}}
        customStyles={modalStyles}
        data={docDetails}
      />

      {userData === undefined && <Loader />}
    </div>
  );
};

export default Doctors;
