import React from "react";

const NotFound = ({ text }: { text: string }) => {
  return (
    <button
      className="bg-white text-onLeave rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
      type="button"
      disabled
    >
      {text}
    </button>
  );
};

export default NotFound;
