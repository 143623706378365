import React, { useState } from "react";
import Modal from "react-modal";
import ProfilePicture from "../../atoms/ProfilePicture";
import ClinicModal from "./HospModal";
import { modalStyles } from "../../../assets/styles/modalStyles";
import Loader from "../../molecules/Loader";
import { useNavigate } from "react-router-dom";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { getRouteSegment } from "../../../lib/utils/funcs";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import { useUserData } from "../../../lib/contexts/UserContext";

const SwitchBranch = ({
  closeModal,
  modalIsOpen,
  customStyles,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
}) => {
  const id = getRouteSegment(1);
  const { userData } = useUserData();
  const { allHospData, hospData, setHospitalID, setDoctorsData } =
    useHospDocData();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="p-4 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <button
          className="text-sbTextHover font-medium"
          onClick={() => setVisible(true)}
        >
          View Details
        </button>
      </div>

      {/* Body */}
      <div className="p-0">
        <div className="bg-white flex flex-col space-y-6 p-4">
          <p className="text-docDetail text-xs uppercase">Switch Branch</p>
          {allHospData?.map((hospital) => (
            <button
              key={hospital.hospital_id}
              onClick={() => {
                console.log(hospital.hospital_name);
                if (hospital.hospital_id !== id) {
                  setHospitalID(hospital.hospital_id);
                  navigate("/" + hospital.hospital_id + "/dashboard");
                  setDoctorsData(undefined);
                }
                closeModal();
              }}
              className="flex flex-row items-center"
            >
              {hospital?.logo === "test" ? (
                <ProfilePicture username={hospital.hospital_name} />
              ) : (
                <img
                  className={`w-10 h-10 rounded-full`}
                  src={hospital?.logo}
                  alt="hospital-logo"
                ></img>
              )}
              <p className="font-medium text-sm ml-2 mr-3">
                {hospital.hospital_name}
              </p>
            </button>
          ))}
          <DarkBlueButton
            name="Back To Admin"
            handleClick={() => navigate("/admin")}
          />
        </div>
        <ClinicModal
          modalIsOpen={visible}
          closeModal={() => setVisible(false)}
          customStyles={modalStyles}
        />
      </div>
      {userData === undefined && <Loader />}
    </Modal>
  );
};

export default SwitchBranch;
