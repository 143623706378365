import axios, { AxiosInstance } from "axios";
import { getCookie, setCookie } from "../utils/cookies";
import { hitRefreshToken } from "./user";
import { baseURL } from "../utils/constants";

let accessToken = String(getCookie("accessToken"));

export const apiClient: AxiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  },
});

// Update the Axios instance directly after successfully refreshing the token.
const updateApiClientToken = (newToken: string) => {
  // Update the token in the Axios instance
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  accessToken = newToken;
};

const handleRefresh = async (): Promise<string | undefined> => {
  const refreshToken = String(getCookie("refreshToken"));

  const refresh_data = await hitRefreshToken(accessToken, refreshToken);

  if (refresh_data?.status === 200) {
    console.log("Refresh");
    setCookie("accessToken", refresh_data.data.result.access_token, 30);
    setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
    updateApiClientToken(refresh_data.data.result.access_token);
    window.location.reload();
    return refresh_data.data.result.access_token;
  }
  return undefined;
};

// Adding interceptors to handle token refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (getCookie("accessToken") !== undefined && accessToken === "undefined") {
      window.location.reload();
    }
    if (error.response?.status === 403 && !originalRequest?._retry) {
      originalRequest._retry = true;

      const newToken = await handleRefresh();
      if (newToken) {
        updateApiClientToken(newToken);
        return apiClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
