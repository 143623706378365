import React from "react";
import { useNavigate } from "react-router-dom";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import WhiteButton from "./WhiteButton";

const ViewQueue = ({
  mapping_id,
  style,
  textStyle,
}: {
  mapping_id: string;
  style?: string;
  textStyle?: string;
}) => {
  const navigate = useNavigate();
  const { hospitalID } = useHospDocData();

  return (
    <>
      <WhiteButton
        name=" View Queue"
        className="hidden xl:flex hover:bg-black hover:text-white  bg-white opacity-100 w-max text-sm"
        handleClick={() => {
          navigate(`/${hospitalID}/doctors/doc/${mapping_id}`);
        }}
      />
      <WhiteButton
        name=" Queue"
        className="flex xl:hidden hover:bg-black hover:text-white  bg-white opacity-100 w-max text-sm"
        handleClick={() => {
          navigate(`/${hospitalID}/doctors/doc/${mapping_id}`);
        }}
      />
    </>
  );
};

export default ViewQueue;
