import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Option } from "react-dropdown";
import moment from "moment";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import {
  addBooking,
  getBookingListByAvailabilityId,
} from "../../../lib/apis/booking";
import {
  createPatient,
  getPatientByPhoneNo,
  getPatientRelations,
} from "../../../lib/apis/patient";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { getRouteSegment } from "../../../lib/utils/funcs";
import { useUserData } from "../../../lib/contexts/UserContext";
import Step2 from "../../molecules/AddBooking/Step2";
import {
  AddBookingState,
  PatientState,
  Relations,
} from "../../../services/types";
import Step1 from "../../molecules/AddBooking/Step1";
import Step3 from "../../molecules/AddBooking/Step3";

const AddBookingModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  session,
  options,
  queue_type,
  setSession,
  setQueue_type,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  session: Option | undefined;
  options: Option[] | undefined;
  queue_type: string;
  setSession: React.Dispatch<React.SetStateAction<Option | undefined>>;
  setQueue_type: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { userData } = useUserData();
  const { docDetails } = useHospDocData();
  const { setBookings, SelectedDate } = usePatientBooking();
  const [step, setStep] = useState(1);
  const [patient, setPatient] = useState<PatientState>({
    full_name: "",
    contact_number: "",
    gender: "Male",
    patient_id: "",
    registered: false,
  });
  const [formState, setFormState] = useState<AddBookingState>({
    comments: "",
    type: "booking",
    currentIndex: 0,
    allSlots: [""],
    slicedSlots: [""],
    allTokens: [0],
    slicedTokens: [0],
  });
  const [relations, setRelations] = useState<Relations[]>([
    {
      relationship_type: "",
      patient_id: "",
      full_name: "",
    },
  ]);

  const [bookingSlot, setBookingSlot] = useState<string>();
  const [token, setToken] = useState<number>();
  const hospital_id = getRouteSegment(1);
  const [relationStep, setRelationStep] = useState("");

  const resetStates = () => {
    setPatient({
      full_name: "",
      contact_number: "",
      gender: "Male",
      patient_id: "",
      registered: false,
    });
    setFormState({
      comments: "",
      type: "booking",
      currentIndex: 0,
      allSlots: [""],
      slicedSlots: [""],
      allTokens: [0],
      slicedTokens: [0],
    });
    setRelationStep("");
  };

  const handleTokenBooking = async (patient_id: string) => {
    console.log("token");

    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData &&
      token
    ) {
      const availability_id = String(session?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(session?.label)?.split(" - ")[0],
        booked_slot_end: String(session?.label)?.split(" - ")[1],
        booking_session_start_time: String(session?.label)?.split(" - ")[0],
        booking_session_end_time: String(session?.label)?.split(" - ")[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: docDetails?.doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
        token_number: token,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setStep(1);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );

        const booked_data = await getBookingListByAvailabilityId(
          availability_id,
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        }
      } else toast.error("Error occured while adding booking.");
    } else if (token === null) {
      toast.error("Please select a token.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSlotBooking = async (patient_id: string) => {
    console.log("slot");
    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData
    ) {
      const availability_id = String(session?.value);

      const slot = bookingSlot;

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start:
          formState.type === "walk-in" ? null : slot?.split(" - ")[0] + ":00",
        booked_slot_end:
          formState.type === "walk-in" ? null : slot?.split(" - ")[1] + ":00",
        booking_session_start_time: String(session?.label)?.split(" - ")[0],
        booking_session_end_time: String(session?.label)?.split(" - ")[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: docDetails?.doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setStep(1);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );

        const booked_data = await getBookingListByAvailabilityId(
          availability_id,
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        }
      } else toast.error("Error occured while adding booking.");
    } else if (bookingSlot === undefined && formState.type === "booking") {
      toast.error("Please select a slot.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async (patient_id: string) => {
    console.log("session");
    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session &&
      userData
    ) {
      const availability_id = String(session?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(session?.label)?.split(" - ")[0],
        booked_slot_end: String(session?.label)?.split(" - ")[1],
        booking_session_start_time: String(session?.label)?.split(" - ")[0],
        booking_session_end_time: String(session?.label)?.split(" - ")[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: docDetails?.doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setStep(1);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );

        const booked_data = await getBookingListByAvailabilityId(
          availability_id,
          SelectedDate
        );
        if (booked_data?.status === 200) {
          setBookings(booked_data.data.result);
        }
      } else toast.error("Error occured while adding booking.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      step === 1 &&
      !isNaN(Number(patient.contact_number)) &&
      String(patient.contact_number).length === 10 &&
      /^[6-9]\d{9}$/.test(patient.contact_number)
    ) {
      console.log("1");
      const res = await getPatientByPhoneNo("91" + patient.contact_number);
      if (res?.status === 200) {
        console.log("patient", res.data.result);
        const data = res.data.result[0];
        setPatient((prevState) => ({
          ...prevState,
          full_name: data.full_name?.trim(),
          contact_number: data.contact_number,
          gender: data.gender,
          patient_id: data.patient_id,
          registered: true,
        }));

        setStep(2);

        const api_data = await getPatientRelations(data.patient_id);
        if (api_data?.status === 200) {
          const relations = api_data.data.result;
          console.log("relations", relations);
          setRelations(relations);
        }
      } else {
        setPatient((prevState) => ({
          ...prevState,
          registered: false,
        }));
        setStep(3);
      }
    } else if (
      step === 1 &&
      (patient.contact_number === "" ||
        isNaN(Number(patient.contact_number)) ||
        patient.contact_number.length !== 10)
    ) {
      console.log("2");
      toast.error("Please enter a valid phone number.");
    } else if (step === 2) {
      setStep(3);
    } else if (
      step === 3 &&
      patient.registered === true &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session
    ) {
      console.log("3");
      closeModal();
      queue_type === "Token"
        ? handleTokenBooking(patient.patient_id)
        : queue_type === "Session"
        ? handleSessionBooking(patient.patient_id)
        : handleSlotBooking(patient.patient_id);
    } else if (
      step === 3 &&
      patient.registered === false &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      session
    ) {
      console.log("4");
      closeModal();
      //Create patient then make booking
      const req = {
        full_name: patient.full_name?.trim(),
        contact_number: "91" + patient.contact_number,
        gender: patient.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };
      const res = await createPatient(req);
      console.log(res.data.result);
      if (res?.status === 201) {
        setPatient((prevState) => ({
          ...prevState,
          registered: true,
        }));

        queue_type === "Token"
          ? handleTokenBooking(res.data.result.patient_id)
          : queue_type === "Session"
          ? handleSessionBooking(res.data.result.patient_id)
          : handleSlotBooking(res.data.result.patient_id);
      }
    } else if (step === 1 && !/^[6-9]\d{9}$/.test(patient.contact_number)) {
      toast.error("Please enter a valid phone number.");
    } else if (patient.full_name === "") {
      console.log("first");
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Add Booking</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            resetStates();
            setStep(1);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            {step === 1 ? (
              <Step1 patient={patient} setPatient={setPatient} />
            ) : step === 2 ? (
              <Step2
                relations={relations}
                patient={patient}
                setPatient={setPatient}
                setRelations={setRelations}
                relationStep={relationStep}
                setRelationStep={setRelationStep}
              />
            ) : (
              <Step3
                session={session}
                queue_type={queue_type}
                options={options}
                formState={formState}
                patient={patient}
                token={token}
                bookingSlot={bookingSlot}
                setFormState={setFormState}
                setPatient={setPatient}
                setSession={setSession}
                setQueue_type={setQueue_type}
                setToken={setToken}
                setBookingSlot={setBookingSlot}
                closeModal={closeModal}
                resetStates={resetStates}
                setStep={setStep}
                hospital_id={hospital_id}
              />
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          {step !== 1 && relationStep === "" && (
            <WhiteButton
              name="Back"
              handleClick={() => {
                if (step === 3 && relations[0].patient_id !== "") {
                  setStep(2);
                } else {
                  resetStates();
                  setStep(1);
                }
              }}
            />
          )}
          {options?.length !== 0 && step === 3 && (
            <DarkBlueButton
              type="submit"
              name="Save"
              handleClick={handleSubmit}
            />
          )}
          <div className="flex justify-end mr-4">
            {step !== 3 && relationStep === "" && (
              <DarkBlueButton
                type="submit"
                name="Next"
                handleClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddBookingModal;
