import React, { useEffect, useState } from "react";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import moment from "moment";

const Welcome = () => {
  const { hospData } = useHospDocData();
  const currentDate = moment().format("DD MMM YYYY");
  const [currentTime, setCurrentTime] = useState(formatTime(new Date()));

  function formatTime(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? " pm" : " am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}${ampm}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formatTime(new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="flex flex-row items-center px-4 md:px-4 lg:px-6 py-4 bg-white rounded-lg w-full"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <div className=" w-full">
        <div className="flex justify-between w-full">
          <div>
            <p className="text-[#6C6763] lg:text-md tracking-[0.4px] font-medium">
              Welcome back,{" "}
            </p>
            <p className=" font-semibold gradientText text-2xl lg:text-4xl mt-1">
              {hospData === undefined ? "" : hospData?.hospital_name}
            </p>
          </div>
          <div className="bg-[#F5F5F5] rounded-md  p-4">
            <p className="text-[#6C6763] text-[10px] lg:text-xs tracking-[0.2px] font-medium text-center">
              {currentDate}
            </p>
            <p className="text-center font-medium text-lg">{currentTime}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
