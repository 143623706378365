import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import Loader from "../components/molecules/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { getPatientReportsInAHospital } from "../lib/apis/patient";
import UploadReport from "../components/organisms/modals/UploadReport";
import { modalStyles } from "../assets/styles/modalStyles";
import { reportHeaders } from "../assets/data/headers";

export interface ReportsData {
  booking_id: string;
  patient_id: string;
  report_url: string;
  booked_date: string;
  comments: string;
  doctor_profile_picture: string;
  doctor_full_name: string;
  hospital_name: string;
  report_uploaded_on: string;
}

const Reports = () => {
  const { userData } = useUserData();
  const { patient } = usePatientBooking();

  const [visible, setVisible] = useState(false);
  const [reportsData, setReportsData] = useState<ReportsData[]>([]);
  const { hospital_id, patient_id } = useParams<{
    hospital_id: string;
    patient_id: string;
  }>();
  const [bookingId, setBookingId] = useState<string>();

  useEffect(() => {
    const fetchReports = async () => {
      if (userData) {
        try {
          const response = await getPatientReportsInAHospital(
            patient_id!,
            hospital_id!
          );
          console.log("reports_data", response.data.result);

          if (response.data.status === "SUCCESS") {
            setReportsData(response.data.result);
          }
        } catch (error) {
          console.log("Error fetching reports.");
        }
      }
    };

    fetchReports();
  }, [hospital_id, patient_id, patient, userData, reportsData.length]);

  const tabsConfig = [
    {
      label: "All Reports",
      count: `(${reportsData.length || "0"})`,
      content:
        reportsData.length === 0 ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Reports Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[65vh] overflow-y-auto">
            <Table
              type="reports"
              headers={reportHeaders}
              reportsData={reportsData}
              setBookingID={setBookingId}
              setVisible={setVisible}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header
        header
        value={patient ? `${patient.full_name}'s Reports` : "Reports"}
      />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs tabsConfig={tabsConfig} />
      </div>

      <UploadReport
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        booking_id={bookingId}
        patient_id={patient_id}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Reports;
