import React, { useState } from "react";
import Modal from "react-modal";
import { VisibilityOff, Visibility } from "../../../assets/icons/Icons";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../atoms/buttons/CloseButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import { deleteCookie } from "../../../lib/utils/cookies";
import { updateUserPassword } from "../../../lib/apis/user";
import { useUserData } from "../../../lib/contexts/UserContext";

const PasswordModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
}) => {
  const [oldPW, setOldPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [rePW, setRePW] = useState("");
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);

  const { userData } = useUserData();
  const { setHospData } = useHospDocData();
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (oldPW !== newPW) {
      if (newPW === rePW && userData) {
        const res = await updateUserPassword(userData?.user_id, newPW);
        // const res = await changePW(oldPW, newPW, hospitalID);
        if (res?.status === 200) {
          closeModal();
          setOne(false);
          setTwo(false);
          setThree(false);
          toast.success("Password changed succesfully!");
          setTimeout(() => {
            toast.error("Please sign in again with the new password.");
          }, 2000);
          setTimeout(() => {
            deleteCookie("accessToken");
            deleteCookie("refreshToken");
            deleteCookie("hospID");
            setHospData(undefined);
            navigate("/");
          }, 5000);
        } else {
          toast.error("Incorrect old password.");
        }
      } else {
        toast.error("New and re-confirmed passwords do not match.");
      }
    } else {
      toast.error("Old and new passwords cannot be the same.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <form onSubmit={(e) => handleSubmit(e)} className="mb-0">
        {/* Header */}
        <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
          <p className="text-dark text-md font-semibold">Change Password</p>
          <CloseButton
            handleClick={() => {
              closeModal();
              setOne(false);
              setTwo(false);
              setThree(false);
            }}
          />
        </div>

        {/* Body */}
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            <div>
              <p className="font-light text-modalText text-sm mb-1">
                Old Password{" "}
              </p>

              <div className="flex justify-between items-center rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full bg-white">
                <input
                  className="border-0 p-0 focus:outline-0 w-full mr-5"
                  placeholder="Enter old password"
                  type={one ? "text" : "password"}
                  value={oldPW}
                  onChange={(e) => setOldPW(e.target.value)}
                  minLength={8}
                  id="opassword"
                  required
                />
                <span onClick={() => setOne(!one)} className="cursor-pointer">
                  {one ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </div>
            <div>
              <p className="font-light text-modalText text-sm mb-1 mt-3">
                New Password{" "}
              </p>

              <div className="flex justify-between items-center rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full bg-white">
                <input
                  className="border-0 p-0 focus:outline-0 w-full mr-5"
                  placeholder="Enter new password"
                  type={two ? "text" : "password"}
                  value={newPW}
                  onChange={(e) => setNewPW(e.target.value)}
                  minLength={8}
                  id="npassword"
                  required
                />
                <span onClick={() => setTwo(!two)} className="cursor-pointer">
                  {two ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </div>
            <div>
              <p className="font-light text-modalText text-sm mb-1  mt-3">
                Confirm New Password{" "}
              </p>

              <div className="flex justify-between items-center rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full bg-white">
                <input
                  className="border-0 p-0 focus:outline-0 w-full mr-5"
                  placeholder="Re-Enter new password"
                  type={three ? "text" : "password"}
                  value={rePW}
                  onChange={(e) => setRePW(e.target.value)}
                  minLength={8}
                  id="password"
                  required
                />
                <span
                  onClick={() => setThree(!three)}
                  className="cursor-pointer"
                >
                  {three ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            className="w-full h-14"
            handleClick={() => {
              closeModal();
              setOne(false);
              setTwo(false);
              setThree(false);
            }}
          />
          <button
            className="rounded-lg opacity-80 bg-sbTextHover px-4 py-2 text-white hover:bg-queueHover w-full h-14"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PasswordModal;
