import React, { useState } from "react";
import { toast } from "react-toastify";
import { addRelation, createPatient } from "../../../lib/apis/patient";
import { PatientState } from "../../../services/types";
import { LeftArrowWithTail } from "../../../assets/icons/Icons";
import moment from "moment";

const NewPatient = ({
  quarter,
  patient,
  setRelationStep,
}: {
  quarter?: boolean;
  patient: PatientState;
  setRelationStep: React.Dispatch<React.SetStateAction<string>>;
}) => {
  interface Relation {
    full_name: string;
    contact_number: string;
    gender: string;
  }

  const [type, setType] = useState("");
  const [relationToAdd, setRelationToAdd] = useState<Relation>({
    full_name: "",
    contact_number: "",
    gender: "Male",
  });

  return (
    <div className="flex flex-col space-y-3">
      <button
        type="button"
        className="w-6"
        onClick={() => setRelationStep("add")}
      >
        <LeftArrowWithTail />
      </button>
      <div className="flex flex-col w-full">
        <p className="font-light text-modalText text-sm mb-1">Full Name*</p>
        <input
          className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
          placeholder="Enter full name"
          type="text"
          autoFocus
          required
          value={relationToAdd.full_name}
          onChange={(e) =>
            setRelationToAdd((prevState) => ({
              ...prevState,
              full_name: e.target.value,
            }))
          }
        />
      </div>
      <div className="mt-3">
        <p className="font-light text-modalText text-sm mb-1">Phone Number</p>
        <input
          className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
          placeholder="Enter phone number"
          value={relationToAdd.contact_number}
          onChange={(e) =>
            setRelationToAdd((prevState) => ({
              ...prevState,
              contact_number: e.target.value,
            }))
          }
          minLength={10}
          maxLength={10}
        />
      </div>
      <div className="flex flex-row space-x-3">
        <div className="flex flex-col w-full">
          <p className="font-light text-modalText text-sm mb-1">
            Relationship Type*
          </p>
          <select
            name="type"
            id="type"
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Select Relation</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Husband">Husband</option>
            <option value="Wife">Wife</option>
            <option value="Son">Son</option>
            <option value="Daughter">Daughter</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="flex flex-col w-full">
          <p className="font-light text-modalText text-sm mb-1 ">Gender</p>
          <div className="flex flex-row space-x-3">
            <button
              className={`${
                relationToAdd.gender?.toLowerCase() === "male"
                  ? "bg-editBG text-white"
                  : " hover:bg-purple-400 hover:text-white"
              } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
              onClick={() =>
                setRelationToAdd((prevState) => ({
                  ...prevState,
                  gender: "Male",
                }))
              }
              type="button"
            >
              Male
            </button>
            <button
              className={`${
                relationToAdd.gender?.toLowerCase() === "female"
                  ? "bg-editBG text-white"
                  : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
              } px-4 py-2 rounded-[20px]`}
              onClick={() =>
                setRelationToAdd((prevState) => ({
                  ...prevState,
                  gender: "Female",
                }))
              }
              type="button"
            >
              Female
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover !mt-5 ${
          quarter ? "w-1/4" : ""
        }`}
        onClick={async () => {
          if (relationToAdd && relationToAdd.full_name !== "" && type !== "") {
            const req1 = {
              full_name: relationToAdd.full_name,
              gender: relationToAdd.gender,
              date_of_birth: moment().format("YYYY-MM-DD"),
              manager_contact_number: patient.contact_number,
            };
            const req2 = {
              full_name: relationToAdd.full_name,
              contact_number: "91" + relationToAdd.contact_number,
              gender: relationToAdd.gender,
              date_of_birth: moment().format("YYYY-MM-DD"),
            };
            console.log(req1);
            const res = await createPatient(
              relationToAdd.contact_number === "" ? req1 : req2
            );
            if (res?.status === 201) {
              const data = res.data.result;
              console.log(data);
              const api_data = await addRelation(
                patient.patient_id,
                data.patient_id,
                type
              );
              if (api_data?.status === 200) {
                console.log(api_data.data.result);
                toast.success("Relation added successfully!");
                setRelationStep("");
              } else toast.error("Error occured while adding relation.");
            } else toast.error("Error occured while adding relation.");
          } else if (relationToAdd.full_name === "") {
            toast.error("Name is required.");
          } else if (type === "") {
            toast.error("Please select a relationship type.");
          }
        }}
      >
        Add Relation
      </button>
    </div>
  );
};

export default NewPatient;
