import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import { Booking, SessionEvent } from "../../../lib/utils/types";
import ReactDropdown from "react-dropdown";
import { generateTimeOptions } from "../../../lib/utils/funcs";
import Toggle from "react-toggle";
import {
  addUnavailablity,
  addUnavailablityRange,
  extendSession,
  getBookingListByAvailabilityId,
  getBookingRangeByHospitalId,
  updateBookingStatus,
} from "../../../lib/apis/booking";
import moment from "moment";
import { AvailabilitySlot } from "../../../services/types";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import RescheduleModal from "./RescheduleModal";
import { modalStyles } from "../../../assets/styles/modalStyles";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";

const RangeHolidayModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  session,
  startDate,
  endDate,
  docAvail,
  generateEventsForMonth,
  setEvents,
  doctor_id,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  session: SessionEvent | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  docAvail: AvailabilitySlot[] | undefined;
  generateEventsForMonth: (
    availabilityData: AvailabilitySlot[],
    start: Date,
    end: Date
  ) => Promise<SessionEvent[]>;
  setEvents: (value: React.SetStateAction<SessionEvent[]>) => void;
  doctor_id: string | undefined;
}) => {
  const [working, setWorking] = useState(true);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [time, setTime] = useState("morning");
  const [bookingId, setBookingId] = useState("");
  const [availabilityId, setAvailabilityId] = useState("");
  const [start, setStart] = useState(String(startDate));
  const [end, setEnd] = useState(String(endDate));

  const formatDate = (d: string) => {
    const dateStr = d;
    const date = new Date(dateStr);
    console.log(date);
    console.log(d);

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, "0");
    if (d === String(endDate)) {
      return `${yyyy}-${mm}-${Number(dd) - 1}`;
    }
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    setStart(moment(String(startDate)).format("YYYY-MM-DD"));
    setEnd(formatDate(String(endDate)));

    if (
      moment(String(startDate)).format("YYYY-MM-DD") ===
      formatDate(String(endDate))
    ) {
      closeModal();
    }
  }, [session, rescheduleVisible]);

  const handleCancelAll = () => {
    if (allAppointments) {
      allAppointments.map(async (data, ind) => {
        await updateBookingStatus({
          bookingId: data.booking_id,
          status: 4,
        });
        if (ind === allAppointments.length - 1) {
          toast.success("All Bookings Cancelled");
          if (docAvail !== undefined) {
            const res = await addUnavailablityRange(
              docAvail[0].mapping_id,
              moment(start).format("YYYY-MM-DD"),
              moment(end).format("YYYY-MM-DD")
            );
            console.log(res);
            if (res?.status === 200) {
              toast.success("Unavailability added!");

              closeModal();
            }
          }
        }
      });
    }
  };

  const [allAppointments, setAllAppointments] = useState<
    Booking[] | undefined
  >();
  const { hospitalID } = useHospDocData();

  function filterUniqueBookings(bookings: Booking[]): Booking[] {
    const uniqueBookings = new Map<string, Booking>();
    bookings.forEach((booking) => {
      if (!uniqueBookings.has(booking.booking_id)) {
        uniqueBookings.set(booking.booking_id, booking);
      }
    });
    return Array.from(uniqueBookings.values());
  }

  useEffect(() => {
    const fetchAllAppointments = async () => {
      const appointment_data = await getBookingRangeByHospitalId({
        hospital_id: hospitalID,
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: formatDate(String(endDate)),
        doctor_id: String(doctor_id),
      });
      console.log(appointment_data);

      if (appointment_data?.status === 200) {
        if (appointment_data.data.status === "FAILURE") {
          setAllAppointments([]);
        } else {
          setAllAppointments(
            filterUniqueBookings(appointment_data.data.result)
          );
        }
      } else {
        console.log(appointment_data);
      }
    };
    fetchAllAppointments();
  }, [rescheduleVisible]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Session Details</p>
        <CloseButton
          handleClick={() => {
            setWorking(true);
            closeModal();
          }}
        />
      </div>

      {/* Body */}
      <div className="p-0 overflow-y-auto max-h-96">
        <div className="px-6 py-5 bg-lightGrey space-y-3 pb-20">
          {working && (
            <>
              <p className="text-modalText text-sm">
                Change the session timings as required
              </p>
              <div className="flex flex-row space-x-3 !mb-5">
                <div className="flex flex-col">
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-5"
                    placeholder="Select Date"
                    type="date"
                    value={start}
                    onChange={(e) => {
                      setStart(e.target.value);
                    }}
                  />
                </div>
                <p className="flex flex-col-reverse text-docDetail mb-2">to</p>
                <div className="flex flex-col">
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-5"
                    placeholder="Select Date"
                    type="date"
                    value={moment(end).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setEnd(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <p className="text-modalText text-sm">
            Turn {working ? "off" : "on"} the toggle to set{" "}
            {working ? "un" : ""}availability
          </p>
          <div className="flex items-center">
            <p
              className={`mx-2 text-sm ${
                working ? "text-dark" : "text-docDetail"
              }`}
            >
              Doctor {working ? "Available" : "Unavailable"}
            </p>
            <Toggle
              icons={false}
              checked={working}
              onChange={() => setWorking(!working)}
            />
          </div>
          {!working &&
            allAppointments &&
            allAppointments[0].full_name !== "" &&
            allAppointments[0].gender !== "" && (
              <div className="p-2">
                <h1 className="font-bold">Bookings</h1>
                <table>
                  <tr>
                    <th className="px-0 text-md">Name</th>

                    <th>Phone Number</th>
                    {/* <th className="!relative">Action</th> */}
                  </tr>
                  {allAppointments.map((e) => (
                    <tr>
                      <td className="px-0 py-1 text-sm">
                        {e.patient_full_name}
                      </td>

                      <td className="py-1 text-sm">
                        {e.patient_contact_number === null &&
                        e.manager_contact_number !== null
                          ? e.manager_contact_number.substring(2)
                          : e.patient_contact_number.substring(2)}
                      </td>
                      <td className="py-1">
                        <DarkBlueButton
                          className="w-max text-sm !px-2"
                          name="Reschedule"
                          handleClick={() => {
                            setBookingId(e.booking_id);
                            setRescheduleVisible(true);
                            setAvailabilityId(e.availability_id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
        <WhiteButton
          name="Close"
          className="w-full h-14"
          handleClick={() => {
            setWorking(true);
            closeModal();
          }}
        />

        {allAppointments !== undefined &&
          allAppointments[0].full_name !== "" &&
          allAppointments[0].gender !== "" &&
          !working && (
            <>
              <DarkBlueButton
                className="w-full h-14 !bg-red-500"
                name="Cancel All"
                handleClick={handleCancelAll}
              />
              {/* <DarkBlueButton
                className="w-full h-14"
                name="Reschedule"
                handleClick={() => {
                  setRescheduleVisible(true);
                }}
              /> */}
            </>
          )}
        {allAppointments === undefined && !working && (
          <>
            <DarkBlueButton
              className="w-full h-14"
              name="Save"
              handleClick={async () => {
                if (docAvail !== undefined) {
                  const res = await addUnavailablityRange(
                    docAvail[0].mapping_id,
                    moment(start).format("YYYY-MM-DD"),
                    moment(end).format("YYYY-MM-DD")
                  );

                  if (res?.status === 200) {
                    toast.success("Unavailability added!");
                    closeModal();
                  }
                }
                setWorking(true);
                closeModal();
              }}
            />
          </>
        )}

        {rescheduleVisible && (
          <RescheduleModal
            modalIsOpen={rescheduleVisible}
            closeModal={() => {
              setTimeout(() => {
                setRescheduleVisible(false);
              }, 500);
            }}
            customStyles={modalStyles}
            session={availabilityId}
            booking_id={bookingId}
            type="each"
            booking_ids={[]}
            mappingId={undefined}
          />
        )}
      </div>
    </Modal>
  );
};

export default RangeHolidayModal;
