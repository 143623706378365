import React from "react";
import { PatientState } from "../../../services/types";

const Step1 = ({
  patient,
  setPatient,
}: {
  patient: PatientState;
  setPatient: React.Dispatch<React.SetStateAction<PatientState>>;
}) => {
  return (
    <div className="flex flex-col w-full">
      <p className="font-light text-modalText text-sm mb-1">Phone Number</p>
      <input
        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
        placeholder="Enter phone number"
        value={patient.contact_number}
        onChange={(e) =>
          setPatient((prevState) => ({
            ...prevState,
            contact_number: e.target.value,
          }))
        }
        minLength={10}
        maxLength={10}
        required
        autoFocus
      />
    </div>
  );
};

export default Step1;
