import axios from "axios";
import { header } from "../utils/header";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";
import { PaymentsRequestData } from "../utils/types";

//Get Payments Details By Hospital ID
export const getPaymentDetails = async (data:PaymentsRequestData) => {
  try {
    const res = await apiClient.post(`${baseURL}/payments/getPaymentDetails`,data);
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};