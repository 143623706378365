import React, { useState } from "react";
import { Close, Logo } from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import { mainItems, supportItems } from "../../assets/data/sidebar";
import SidebarButton from "../atoms/buttons/SidebarButton";
import { getRouteSegment } from "../../lib/utils/funcs";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";

const MobileSidebar = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { hospitalID, hospData } = useHospDocData();

  const [isHovered, setIsHovered] = useState("");

  return (
    <div
      className={`absolute inset-0 bg-white xl:hidden flex flex-col w-full h-full z-20 ${
        visible ? "animate-slideIn" : "animate-slideOut"
      }`}
    >
      <button
        className="m-4 flex flex-row-reverse"
        onClick={() => setVisible(false)}
      >
        <Close />
      </button>
      <div className="bg-white flex flex-col h-screen px-6">
        <div className="flex flex-col mt-10">
          <div
            className="mt-3 cursor-pointer mb-10"
            onClick={() => navigate("/" + hospitalID + "/dashboard")}
          >
            {hospData?.is_easy_heal ? (
              <img
                src={require("../../assets/images/easyHealsLogo.jpg")}
                className="!w-28 lg:w-[150px]"
                alt="EasyHeals"
              />
            ) : (
              <Logo style={"md:!w-28 lg:w-[150px]"} />
            )}
          </div>
          <p className="ml-5 mb-3 uppercase text-[11px] font-medium">Main</p>
          <div className="flex flex-col">
            {mainItems.map((item, index) => (
              <SidebarButton
                key={index}
                index={index}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                name={item.name}
                icon={item.icon}
                hoverIcon={item.hoverIcon}
                onPress={() => {
                  navigate(`/${hospitalID}/${item.name.toLowerCase()}`);
                }}
                isSelected={getRouteSegment(2) === item.name.toLowerCase()}
              />
            ))}
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <p className="ml-5 mb-3 uppercase text-[11px] font-medium">Support</p>
          <div className="flex flex-col">
            {supportItems.map((item, index) => (
              <SidebarButton
                key={index}
                index={index}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                name={item.name}
                icon={item.icon}
                hoverIcon={item.hoverIcon}
                onPress={() => {
                  navigate(
                    `/${hospitalID}/${item.name.toLowerCase().split(" ")[0]}`
                  );
                }}
                isSelected={getRouteSegment(2) === item.name.toLowerCase()}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
