import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { allpatientHeaders, patientHeaders } from "../assets/data/headers";
import Loader from "../components/molecules/Loader";
import { getDate, getRouteSegment } from "../lib/utils/funcs";
import { LeftArrow, RightArrow, Search } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import {
  getAllPatientsByHospitalID,
  getBookedPatientsByHospitalID,
  getPatientsByHospitalID,
} from "../lib/apis/patient";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { hitRefreshToken } from "../lib/apis/user";
import { getCookie, setCookie } from "../lib/utils/cookies";
import EditPatientModal from "../components/organisms/modals/EditPatientModal";
import { modalStyles } from "../assets/styles/modalStyles";
// import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import { Patient, TotalPatients } from "../lib/utils/types";
import * as XLSX from "xlsx";

type Booking = {
  booking_id: string;
  booking_creation_timestamp: string;
  appointment_date: string;
  appointment_start_time: string;
  appointment_end_time: string;
  doctor_session_start_time: string;
  doctor_session_end_time: string;
  appointment_notes: string | null;
  booking_mode: string;
  booking_status: string;
  doctor_full_name: string;
  booking_created_by: string;
  hospital_name: string;
  patient_dob: string;
  status_last_updated_at: string;
};

type PatientBooking = {
  patient_id: string;
  patient_full_name: string;
  patient_contact_number: string;
  no_of_bookings: number;
  bookings: Booking[];
};

type PatientBookingList = PatientBooking[];

interface AllPatient {
  patient_id: string;
  patient_full_name: string;
  patient_contact_number: string;
  patient_email: string | null;
  patient_gender: string;
  patient_dob: string; // Date string in ISO format
  patient_address: string | null;
  referrer_hospital_id: string | null;
}

type AllPatientData = AllPatient[];



const Patients = () => {
  const id = getRouteSegment(1);
  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, hospData } = useHospDocData();
  const { patientsData, setPatientsData } = usePatientBooking();
  const [patientName, setPatientName] = useState("");
  const [excelPatientData, setExcelPatient] = useState<PatientBookingList>();
  const [allPatientData, setAllPatient] = useState<AllPatientData>();
  const [excelallPatientData, setExcelAllPatient] = useState<AllPatientData>();

  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [allCount, setAllCount] = useState("");

  useEffect(() => {
    const fetchExcelPatientsData = async () => {
      const res = await getBookedPatientsByHospitalID(hospitalID);
      if (res?.status === 200) {
        console.log(res.data.result.patients);
        
        setExcelPatient(res.data.result.patients);
      }
    };
    fetchExcelPatientsData();
  }, []);

  const fetchAllPatientsData = async () => {
    const res = await getAllPatientsByHospitalID(
      hospitalID,
      page,
      pageSize,
      search
    );
    if (res?.status === 200) {
      setAllPatient(res.data.result.data);
      setAllCount(res.data.result.total);
      console.log(res.data.result.data);
      
    }
    const res2 = await getAllPatientsByHospitalID(
      hospitalID,
      1,
      99999999999,
      search
    );
    if (res2?.status === 200) {
      setExcelAllPatient(res2.data.result.data);
    }
  };

  const fetchPatientsData = async () => {
    const res = await getPatientsByHospitalID(
      hospitalID,
      page,
      pageSize,
      search
    );
    if (res?.status === 200) {
      setPatientsData(res.data.result);
      console.log("patientsData", res.data.result);
    } else if (res?.status === 403 && accessToken && refreshToken) {
      const refresh_data = await hitRefreshToken(accessToken, refreshToken);
      if (refresh_data?.status === 200) {
        console.log("Refresh");
        setCookie("accessToken", refresh_data.data.result.access_token, 30);
        setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
        const res = await getPatientsByHospitalID(
          hospitalID,
          page,
          pageSize,
          search
        );
        if (res?.status === 200) setPatientsData(res.data.result);
      }
    }
  };
  function calculateAge(dob:string) {
    // Parse the date of birth
    const birthDate = new Date(dob);
    const today = new Date();

    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const header = [
    // "Patient ID",
    "Name",
    "Age",
    "Contact Details",
    "No of Bookings",
    // "Booking ID",
    "Booking Creation Timestamp",
    "Appointment Date",
    "Appointment Start Time",
    "Appointment End Time",
    "Doctor Session Start Time",
    "Doctor Session End Time",
    "Appointment Notes",
    "Booking Mode",
    "Booking Status",
    "Doctor Full Name",
    "Booking Created By",
    "Hospital Name",
    "Status Last Updated At",
  ];
  const allheader = [
    // "Patient ID",
    "Patient Full Name",
    "Patient Age",
    "Patient Contact Number",
    "Patient Email",
    "Patient Gender",
    "Patient Date of Birth",
    "Patient Address",
    // "Referrer Hospital ID",
  ];

  function handleDownloadExcel() {
    // Transform patientBookingList into an array of arrays for the first sheet
    const transformedDataSheet1 = excelPatientData?.flatMap(
      ({
        patient_id,
        patient_full_name,
        patient_contact_number,
        no_of_bookings,
        bookings,
      }) =>
        bookings.map((booking) => [
          // patient_id ?? "",
          patient_full_name ?? "",
          calculateAge(getDate(new Date(booking.patient_dob).toDateString())),
          patient_contact_number ?? "",
          no_of_bookings ?? 0,
          // booking.booking_id ?? "",
          booking.booking_creation_timestamp ?? "",
          booking.appointment_date ?? "",
          booking.appointment_start_time ?? "",
          booking.appointment_end_time ?? "",
          booking.doctor_session_start_time ?? "",
          booking.doctor_session_end_time ?? "",
          booking.appointment_notes || "", // replace null with empty string
          booking.booking_mode ?? "",
          booking.booking_status ?? "",
          booking.doctor_full_name ?? "",
          booking.booking_created_by ?? "",
          booking.hospital_name ?? "",
          booking.status_last_updated_at ?? "",
        ])
    );

    const transformedDataSheet2 = excelallPatientData?.map(
      ({
        patient_id,
        patient_full_name,
        patient_contact_number,
        patient_email,
        patient_gender,
        patient_dob,
        patient_address,
        referrer_hospital_id,
      }) => [
        // patient_id ?? "",
        patient_full_name ?? "",
        calculateAge(getDate(new Date(patient_dob).toDateString())),
        patient_contact_number ?? "",
        patient_email ?? "", // Replace null with an empty string
        patient_gender ?? "",
        patient_dob ?? "", // Format date to YYYY-MM-DD
        patient_address ?? "", // Replace null with an empty string
        // referrer_hospital_id ?? "", // Replace null with an empty string
      ]
    );

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create a new worksheet for the patient bookings
    if (transformedDataSheet1) {
      const ws1 = XLSX.utils.aoa_to_sheet([header, ...transformedDataSheet1]);
      XLSX.utils.book_append_sheet(wb, ws1, "Booked Patients");
    }
    if (transformedDataSheet2) {
      const ws2 = XLSX.utils.aoa_to_sheet([
        allheader,
        ...transformedDataSheet2,
      ]);
      XLSX.utils.book_append_sheet(wb, ws2, "All Patients");
    }

    // Write the workbook to a file and download it
    XLSX.writeFile(wb, `${hospData?.hospital_name}_PatientBookings.xlsx`);
  }

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  useEffect(() => {
    fetchPatientsData();
    fetchAllPatientsData();
  }, [, userData, page, pageSize, search, visible]);

  const tabsConfig = [
    {
      label: "Booked Patients",
      count: "(" + (patientsData?.total_distinct_bookings || "0") + ")",
      content:
        patientsData === undefined ||
        patientsData?.booking_list.length === 0 ? (
          <div className="bg-white flex justify-center items-center h-96">
            <p className="text-center">No Patients Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[65vh] overflow-y-auto">
            <Table
              type="patients"
              headers={patientHeaders}
              patientData={patientsData?.booking_list}
              setVisible={setVisible}
            />
          </div>
        ),
    },
    {
      label: "All Patients",
      count: "(" + (allCount || "0") + ")",
      content:
        allPatientData === undefined || allPatientData?.length === 0 ? (
          <div className="bg-white flex justify-center items-center h-96">
            <p className="text-center">No Patients Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[65vh] overflow-y-auto">
            <Table
              type="allpatients"
              headers={allpatientHeaders}
              AllPatientData={allPatientData}
              setVisible={setVisible}
              setPatientName={setPatientName}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Patients"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex items-center text-sm m-3 md:mt-0 md:mb-[7px]">
              <button
                className="downloadBtn bg-sbTextHover hover:bg-queueHover opacity-80 !w-20"
                onClick={handleDownloadExcel}
              >
                <svg
                  className="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span className="icon2"></span>
                <span className="tooltip">Download Excel</span>
              </button>
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder w-full bg-white md:mr-5">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
        <div className="flex flex-row justify-end items-center bg-white px-2 py-1 border-[0.5px] border-doctorsBorder">
          <p className="py-2 mr-3 text-sm">Rows per page:</p>
          <select
            className="rounded-lg p-1 border-[0.5px] border-sbBorder focus:outline-none mr-6"
            name="specialization"
            onChange={(e) => setPageSize(Number(e.target.value))}
            defaultValue={5}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
          </select>
          <p className="mr-3 text-sm">Page {page}</p>
          <button
            className="mr-2"
            onClick={() => page > 1 && setPage(page - 1)}
          >
            <LeftArrow />
          </button>
          <button onClick={() => setPage(page + 1)}>
            <RightArrow />
          </button>
        </div>
      </div>

      <EditPatientModal
        modalIsOpen={visible}
        closeModal={() => {
          setTimeout(() => {
            setVisible(false);
          }, 500);
        }}
        customStyles={modalStyles}
        page={page}
        pageSize={pageSize}
        search={search}
        allPatientData={allPatientData}
        patientName={patientName}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Patients;
