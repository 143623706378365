import React from "react";

const SidebarButton = ({
  index,
  isHovered,
  setIsHovered,
  name,
  icon,
  hoverIcon,
  onPress,
  isSelected,
}: {
  index: number;
  isHovered: string;
  setIsHovered: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  icon: JSX.Element;
  hoverIcon: JSX.Element;
  onPress: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isSelected: boolean;
}) => {
  if (isSelected) {
    return (
      <button
        className="rounded-xl focus:ring-0 my-1 flex flex-row justify-start items-center px-5 py-4 md:px-4 md:py-3 lg:px-5 lg:py-4 bg-sbBgHover text-sbTextHover font-semibold"
        key={index}
        onClick={onPress}
      >
        {hoverIcon}
        <p className="ml-4 lg:text-sm">{name}</p>
      </button>
    );
  }
  return (
    <button
      className="rounded-xl bg-white focus:ring-0 text-sbText my-1 flex flex-row justify-start items-center px-5 py-4 md:px-4 md:py-3 lg:px-5 lg:py-4 hover:enabled:bg-sbBgHover hover:text-sbTextHover hover:font-semibold"
      key={index}
      onMouseEnter={() => setIsHovered(name)}
      onMouseLeave={() => setIsHovered("")}
      onClick={onPress}
    >
      {isHovered === name ? hoverIcon : icon}
      <p className="ml-4 lg:text-sm">{name}</p>
    </button>
  );
};

export default SidebarButton;
