import React, { useState } from "react";

const OverflowString = ({
  text,
  hideForMobile,
  className,
  length,
}: {
  text: string | undefined;
  hideForMobile?: boolean;
  className?: string;
  length: number;
}) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };

  const textToDisplay = showFullText
    ? text
    : text && text.length > length
    ? text.substring(0, length) + "..."
    : text;

  return (
    <span
      className={`${hideForMobile ? "hidden md:flex" : ""} ${
        className ? className : ""
      } text-docDetail text-[10px] lg:text-xs overflow-hidden cursor-pointer`}
      onClick={toggleShowText}
    >
      {textToDisplay}
    </span>
  );
};

export default OverflowString;
