import React, { useState } from "react";
import Modal from "react-modal";
import CloseButton from "../../atoms/buttons/CloseButton";
import { toast } from "react-toastify";
import axios from "axios";
import { Doctor } from "../../../lib/utils/types";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import WhiteButton from "../../atoms/buttons/WhiteButton";

const PaymentModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  booking_id,
  patient_id,
  docDetails,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  booking_id: string | undefined;
  patient_id: string | undefined;
  docDetails: Doctor | undefined;
}) => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const { hospData } = useHospDocData();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let data = {
      name: hospData?.hospital_name,
      price: Number(amount),
      number: docDetails?.contact_number,
      MID: "MID" + Date.now(),
      transactionId: "T" + Date.now(),
      booking_id,
      patient_id,
      message,
    };
    try {
      if (amount && !isNaN(Number(amount))) {
        const res = await axios.post("http://localhost:8000/api/v1/payments", {
          ...data,
        });
        if (res.data.success) {
          console.log(
            "LINK",
            res.data.data.instrumentResponse.redirectInfo.url
          );
        }
        toast.success("Payment request sent successfully.");
        modalIsOpen && closeModal();
      } else toast.error("Invalid inputs.");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <form onSubmit={(e) => handleSubmit(e)} className="mb-0">
        {/* Header */}
        <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
          <p className="text-dark text-md font-semibold">Payment Request</p>
          <CloseButton
            handleClick={() => {
              closeModal();
              setAmount("");
              setMessage("");
            }}
          />
        </div>

        {/* Body */}
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey space-y-3">
            <div>
              <p className="font-light text-modalText text-sm mb-1">Amount</p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter amount"
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                autoFocus
              />
            </div>
            <div>
              <p className="font-light text-modalText text-sm mb-1">Message</p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            handleClick={closeModal}
            className="w-full h-14"
          />
          <button
            className="rounded-lg opacity-80 bg-sbTextHover px-4 py-2 text-white hover:bg-queueHover w-full h-14"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PaymentModal;
