import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { baseURL } from "../../../lib/utils/constants";
import { getBookingListByAvailabilityId } from "../../../lib/apis/booking";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";

const UploadReport = ({
  closeModal,
  modalIsOpen,
  customStyles,
  booking_id,
  patient_id,
  availability_id,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  booking_id: string | undefined;
  patient_id: string | undefined;
  availability_id?: string | undefined;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { setBookings, SelectedDate } = usePatientBooking();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
    },
  });

  const fileList = files.map((file) => (
    <li key={file.name}>
      {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
    </li>
  ));

  const uploadFile = async (url: string, file: File) => {
    await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!booking_id || !patient_id || files.length === 0) {
      toast.error("Booking ID, Patient ID, and file are required.");
      return;
    }

    try {
      const file = files[0];

      const presignedUrlResponse = await axios.post(
        `${baseURL}/reports/put-presigned-url`,
        {
          bookingId: booking_id,
          patientId: patient_id,
        }
      );

      const putUrl = presignedUrlResponse.data.url;
      console.log("putUrl", putUrl);
      await uploadFile(putUrl, file);

      toast.success("Report uploaded successfully!");
      closeModal();
      setFiles([]);
      const booked_data = await getBookingListByAvailabilityId(
        availability_id,
        SelectedDate
      );
      if (booked_data?.status === 200) {
        setBookings(booked_data.data.result);
      } else setBookings(undefined);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Upload Report</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            setFiles([]);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            <p className="text-modalText text-sm mb-3">
              Note: Upload the patient's valid prescription from the doctor.
            </p>
            {files.length === 0 && (
              <div
                {...getRootProps({
                  className:
                    "dropzone flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500",
                })}
              >
                <input {...getInputProps()} type="file" accept=".pdf" />
                <p className="text-gray-500">
                  {isDragActive
                    ? "Drop the files here ..."
                    : "Drag 'n' drop files here, or click to select files"}
                </p>
              </div>
            )}
            {files.length !== 0 && (
              <aside>
                <h4 className="font-semibold mt-3">Uploaded file:</h4>
                {fileList}
              </aside>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            className="w-full h-14"
            handleClick={() => {
              closeModal();
              setFiles([]);
            }}
          />
          <DarkBlueButton name="Save" type="submit" className="w-full h-14" />
        </div>
      </form>
    </Modal>
  );
};

export default UploadReport;
