import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import Loader from "../components/molecules/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import {
  addRelation,
  createPatient,
  getPatientByPatientId,
  getPatientByPhoneNo,
  getPatientRelations,
} from "../lib/apis/patient";
import { relationHeaders } from "../assets/data/headers";
import { Relation } from "../lib/utils/types";
import EditRelation from "../components/organisms/modals/EditRelation";
import { modalStyles } from "../assets/styles/modalStyles";
import Modal from "react-modal";
import CloseButton from "../components/atoms/buttons/CloseButton";
import WhiteButton from "../components/atoms/buttons/WhiteButton";
import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import moment from "moment";
import { toast } from "react-toastify";
import { PatientState } from "../services/types";

export interface ReportsData {
  booking_id: string;
  patient_id: string;
  report_url: string;
  booked_date: string;
  comments: string;
  doctor_profile_picture: string;
  doctor_full_name: string;
  hospital_name: string;
  report_uploaded_on: string;
}

const PatientRelations = () => {
  const { userData } = useUserData();
  const [relModal, setRelModal] = useState<boolean>(false);
  const [addRel, setAddRel] = useState<boolean>(false);
  const { patient } = usePatientBooking();
  const { hospital_id, patient_id } = useParams<{
    hospital_id: string;
    patient_id: string;
  }>();

  const [patient2, setPatient2] = useState<PatientState>();

  useEffect(() => {
    async function getPatient() {
      const resp = await getPatientByPatientId(String(patient_id));
      console.log(resp?.data.result);
      setPatient2(resp?.data.result[0]);
    }
    getPatient();
  }, []);

  const [relations, setRelations] = useState<Relation[]>();
  const [visible, setVisible] = useState(false);

  const [relationToAdd, setRelationToAdd] = useState<{
    full_name: string;
    contact_number: string;
    gender: string;
  }>({
    full_name: "",
    contact_number: "",
    gender: "Male",
  });

  const handlePatientCheck = async (e: any) => {
    setRelationToAdd((prevState) => ({
      ...prevState,
      contact_number: e.target.value,
    }));
    if (
      !isNaN(Number(e.target.value)) &&
      String(e.target.value).length === 10 &&
      /^[6-9]\d{9}$/.test(e.target.value)
    ) {
      console.log(e.target.value);
      const resp = await getPatientByPhoneNo("91" + e.target.value);

      if (resp?.status === 200) {
        console.log(resp);

        setRelationToAdd((prevState) => ({
          ...prevState,
          full_name: resp.data.result[0].full_name,
        }));
        toast.success("Patient Found");
      }
    }
  };

  const [type, setType] = useState("");

  const handleAddRelation = async () => {
    if (relationToAdd && relationToAdd.full_name !== "" && type !== "") {
      const req1 = {
        full_name: relationToAdd.full_name,
        gender: relationToAdd.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
        manager_contact_number: patient2?.contact_number,
      };
      const req2 = {
        full_name: relationToAdd.full_name,
        contact_number: "91" + relationToAdd.contact_number,
        gender: relationToAdd.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };

      const resp = await getPatientByPhoneNo(
        "91" + relationToAdd.contact_number
      );

      if (resp?.status === 200) {
        console.log("patient", resp.data.result);
        const data = resp.data.result[0];
        const api_data = await addRelation(
          String(patient2?.patient_id),
          data.patient_id,
          type
        );
        if (api_data?.status === 200) {
          console.log(api_data.data.result);
          toast.success("Relation added successfully!");
          setRelationToAdd({
            full_name: "",
            contact_number: "",
            gender: "",
          });
          setType("");
        } else toast.error("Relation already exists with same number");
      } else {
        const res = await createPatient(
          relationToAdd.contact_number === "" ? req1 : req2
        );
        if (res?.status === 201) {
          const data = res.data.result;

          const api_data = await addRelation(
            String(patient2?.patient_id),
            data.patient_id,
            type
          );
          if (api_data?.status === 200) {
            console.log(api_data.data.result);
            toast.success("Relation added successfully!");

            setRelationToAdd({
              full_name: "",
              contact_number: "",
              gender: "",
            });
            setType("");
          } else toast.error("Error occured while adding relation.");
        } else toast.error("Error occured while adding relation.");
      }
    } else if (relationToAdd.full_name === "") {
      toast.error("Name is required.");
    } else if (type === "") {
      toast.error("Please select a relationship type.");
    }
    setAddRel(false);
    setRelModal(true);
  };

  useEffect(() => {
    const fetchRelations = async () => {
      if (userData && patient_id) {
        try {
          const res = await getPatientRelations(patient_id);
          if (res?.status === 200) {
            const relations = res.data.result;
            console.log("relations", relations);
            setRelations(relations);
          }
        } catch (error) {
          console.log("Error fetching reports.");
        }
      }
    };

    fetchRelations();
  }, [hospital_id, patient_id, patient, userData, relations?.length]);

  const tabsConfig = [
    {
      label: "All Relations",
      count: `(${
        relations?.filter((i) => i.relationship_type !== "Self").length || "0"
      })`,
      content:
        relations === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Relations Found.</p>
          </div>
        ) : (
          <div className="max-h-[65vh] md:max-h-[80vh] lg:max-h-[65vh] overflow-y-auto">
            <Table
              type="relations"
              headers={relationHeaders}
              relationsData={relations.filter(
                (i) => i.relationship_type !== "Self"
              )}
              setVisible={setVisible}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header
        header
        value={patient ? `${patient.full_name}'s Relations` : "Relations"}
      />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex justify-end items-center m-3 md:pr-5 md:mt-0 md:mb-[7px]">
              <button
                className="rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover !mt-0"
                onClick={() => {
                  setAddRel(true);
                }}
              >
                + Add Relation
              </button>
            </div>
          }
        />
      </div>
      <Modal
        isOpen={addRel}
        onRequestClose={() => {
          setAddRel(false);
        }}
        style={modalStyles}
        ariaHideApp={false}
      >
        {/* Header */}
        <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
          <p className="text-dark text-md font-semibold">Add Relation</p>
          <CloseButton
            handleClick={() => {
              setAddRel(false);
              setRelModal(true);
              setRelationToAdd({
                full_name: "",
                contact_number: "",
                gender: "",
              });
              setType("");
            }}
          />
        </div>

        {/* Body */}
        <form className="mb-0">
          <div className="p-0 max-h-96 overflow-y-auto">
            <div className="px-6 py-5 bg-lightGrey">
              <div className="flex flex-col w-full">
                <p className="text-modalText text-md mb-1 font-bold">
                  Full Name <span className="text-red-500">*</span>
                </p>
                <input
                  className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full mt-2"
                  placeholder="Enter full name"
                  type="text"
                  autoFocus
                  required
                  value={relationToAdd.full_name}
                  onChange={(e) =>
                    setRelationToAdd((prevState) => ({
                      ...prevState,
                      full_name: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="flex flex-row space-x-6 mt-2">
                <div className="flex flex-col w-[50%] mt-4">
                  <p className="text-modalText text-md mb-1 font-bold">
                    Relationship Type
                    <span className="text-red-500">*</span>
                  </p>
                  <select
                    name="type"
                    id="type"
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      if (
                        e.target.value === "Father" ||
                        e.target.value === "Brother" ||
                        e.target.value === "Husband" ||
                        e.target.value === "Son"
                      ) {
                        setRelationToAdd((prevState) => ({
                          ...prevState,
                          gender: "Male",
                        }));
                      } else if (e.target.value === "Other") {
                        setRelationToAdd((prevState) => ({
                          ...prevState,
                          gender: "Male",
                        }));
                      } else {
                        setRelationToAdd((prevState) => ({
                          ...prevState,
                          gender: "Female",
                        }));
                      }
                    }}
                  >
                    <option value="">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Husband">Husband</option>
                    <option value="Wife">Wife</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="mt-4 w-[50%]">
                  <p className="text-modalText text-md mb-1 font-bold">
                    Phone Number
                  </p>
                  <input
                    className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                    placeholder="Enter phone number"
                    value={relationToAdd.contact_number}
                    onChange={handlePatientCheck}
                    minLength={10}
                    maxLength={10}
                  />
                </div>
              </div>
              {type === "Other" && (
                <div className="flex  flex-wrap gap-4 mt-6">
                  <div
                    className={
                      relationToAdd.gender === "Male"
                        ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                        : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                    }
                    onClick={() =>
                      setRelationToAdd((prevState) => ({
                        ...prevState,
                        gender: "Male",
                      }))
                    }
                  >
                    <p className="font-normal">Male</p>
                  </div>
                  <div
                    className={
                      relationToAdd.gender === "Female"
                        ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                        : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                    }
                    onClick={() =>
                      setRelationToAdd((prevState) => ({
                        ...prevState,
                        gender: "Female",
                      }))
                    }
                  >
                    <p className="font-normal">Female</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
            <WhiteButton
              name="Cancel & Go Back"
              className="w-full h-14"
              handleClick={() => {
                setAddRel(false);
                setRelModal(true);
                setRelationToAdd({
                  full_name: "",
                  contact_number: "",
                  gender: "",
                });
                setType("");
              }}
            />
            <DarkBlueButton
              type="button"
              name="Add Relation"
              className="w-full h-14"
              handleClick={async () => {
                handleAddRelation();
              }}
            />
          </div>
        </form>
      </Modal>

      <EditRelation
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        setRelations={setRelations}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default PatientRelations;
