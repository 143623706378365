import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import { Buttons, Doctor } from "../../../lib/utils/types";
import { v4 as uuidv4 } from "uuid";
import {
  addButtonMapping,
  getButtonsByHospitalId,
} from "../../../lib/apis/button";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";

const AddButtonModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  doctors,
  setButtons,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  doctors: Doctor[] | undefined;
  setButtons: (value: React.SetStateAction<Buttons[] | undefined>) => void;
}) => {
  const [doctorName, setDoctorName] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState(doctors);

  const { hospitalID } = useHospDocData();

  useEffect(() => {
    setFilteredDoctors(doctors);
  }, [doctors]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDoctorName(value);
    const filtered = doctors?.filter((doc) =>
      doc.full_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDoctors(filtered);
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    console.log(value);
    setSelectedDoctor(value);
  };

  const handleAddButton = async () => {
    const ButtonId = uuidv4();

    const res = await addButtonMapping(ButtonId, {
      mappingIds: [selectedDoctor],
    });
    if (res?.status === 200) {
      const res = await getButtonsByHospitalId(hospitalID);
      if (res?.status === 200) {
        setButtons(res.data.result);
      }
      toast.success("Doctor added successfully!");
      closeModal();
    } else {
      toast.error("Error occured while adding doctor.");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Add Button </p>
        <CloseButton handleClick={closeModal} />
      </div>

      {/* Body */}
      <div className="p-0 max-h-96 overflow-y-auto">
        <div className="flex flex-col space-y-3 p-4 min-h-60">
          <input
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            placeholder="Filter doctors..."
            type="text"
            value={doctorName}
            onChange={handleInputChange}
          />
          <select
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            value={selectedDoctor}
            onChange={handleSelectChange}
          >
            <option disabled hidden value="">
              Select doctor
            </option>
            {filteredDoctors?.map((doc) => (
              <option key={doc.mapping_id} value={doc.mapping_id}>
                {doc.full_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
        <WhiteButton
          name="Cancel"
          className="w-full h-14"
          handleClick={closeModal}
        />
        <DarkBlueButton
          name="Save"
          className="w-full h-14"
          handleClick={handleAddButton}
        />
      </div>
    </Modal>
  );
};

export default AddButtonModal;
