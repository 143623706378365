import React from "react";
import { Close } from "../../../assets/icons/Icons";

const CloseButton = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <button
      onClick={handleClick}
      className="bg-closeBG p-1 rounded-full hover:bg-sbBorder focus:outline-none"
      type="button"
    >
      <Close />
    </button>
  );
};

export default CloseButton;
