import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import moment from "moment";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import { toast } from "react-toastify";
import {
  getPatientRelations,
  updatePatient,
  updateRelation,
} from "../../../lib/apis/patient";
import { useParams } from "react-router-dom";
import { Relation } from "../../../lib/utils/types";

const EditRelation = ({
  closeModal,
  modalIsOpen,
  customStyles,
  setRelations,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  setRelations: React.Dispatch<React.SetStateAction<Relation[] | undefined>>;
}) => {
  const { relation } = usePatientBooking();
  const { patient_id } = useParams();

  const [relationState, setRelationState] = useState({
    full_name: relation?.full_name,
    contact_number: relation?.contact_number,
    gender: relation?.gender,
    date_of_birth: relation?.date_of_birth,
    relationship_type: relation?.relationship_type,
    relationship_id: relation?.relationship_id,
    patient_id: relation?.patient_id,
  });

  useEffect(() => {
    if (relation)
      setRelationState({
        ...relation,
        full_name: relation.full_name,
        contact_number: relation.contact_number || "", // Ensure it's always a string
        gender: relation.gender,
        date_of_birth: relation.date_of_birth,
        relationship_type: relation.relationship_type,
        relationship_id: relation.relationship_id,
      });
  }, [modalIsOpen]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (
      relationState.full_name &&
      relationState.gender &&
      relationState.full_name !== "" &&
      relationState.relationship_id &&
      relationState.relationship_type &&
      relationState.patient_id &&
      patient_id
    ) {
      closeModal();

      const res = await updateRelation(
        relationState.relationship_id,
        relationState.relationship_type
      );
      console.log("updateRelation", res);

      const req = {
        full_name: relationState.full_name,
        contact_number: relationState.contact_number || null,
        gender: relationState.gender,
        date_of_birth:
          relationState.date_of_birth || moment().format("YYYY-MM-DD"),
      };

      const api_data = await updatePatient(relationState.patient_id, req);
      console.log("updatePatient", api_data);

      if (res?.status === 200 || api_data?.status === 200) {
        toast.success("Updated successfully!");
        const api_data = await getPatientRelations(patient_id);
        if (api_data?.status === 200) {
          const relations = api_data.data.result;
          console.log("relations", relations);
          setRelations(relations);
        }
      }
    } else if (relationState.full_name === "") {
      toast.error("Please enter relation name.");
    } else if (relationState.gender === "") {
      toast.error("Please enter relation gender.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Relation Details</p>
        <CloseButton handleClick={() => closeModal()} />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            <div className="flex flex-col w-full">
              <p className="font-light text-modalText text-sm mb-1">
                Full Name
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter full name"
                value={relationState.full_name}
                onChange={(e) =>
                  setRelationState((prevState) => ({
                    ...prevState,
                    full_name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">
                Relationship Type
              </p>
              <select
                name="type"
                id="type"
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                defaultValue={relationState.relationship_type}
                onChange={(e) =>
                  setRelationState((prevState) => ({
                    ...prevState,
                    relationship_type: e.target.value,
                  }))
                }
              >
                <option value="">Select Relation</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Husband">Husband</option>
                <option value="Wife">Wife</option>
                <option value="Wife">Son</option>
                <option value="Wife">Daughter</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">
                Phone Number
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter phone number"
                value={relationState.contact_number?.substring(2)}
                onChange={(e) =>
                  setRelationState((prevState) => ({
                    ...prevState,
                    contact_number: e.target.value,
                  }))
                }
                minLength={12}
                maxLength={12}
              />
            </div>
            <div className="flex flex-row mt-3">
              <div className="flex flex-col w-full mr-5">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Date of Birth
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter date of birth"
                  type="date"
                  value={moment(relationState?.date_of_birth).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) =>
                    setRelationState((prevState) => ({
                      ...prevState,
                      date_of_birth: e.target.value,
                    }))
                  }
                  min="1954-01-01"
                  max={`${moment().format("YYYY-MM-DD")}`}
                />
              </div>
              <div className="flex flex-col w-full">
                <p className="font-light text-modalText text-sm mb-1">Gender</p>
                <div className="flex flex-row space-x-3">
                  <button
                    className={`${
                      relationState.gender?.toLowerCase() === "male"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setRelationState((prevState) => ({
                        ...prevState,
                        gender: "Male",
                      }))
                    }
                    type="button"
                  >
                    Male
                  </button>
                  <button
                    className={`${
                      relationState.gender?.toLowerCase() === "female"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setRelationState((prevState) => ({
                        ...prevState,
                        gender: "Female",
                      }))
                    }
                    type="button"
                  >
                    Female
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            handleClick={() => closeModal()}
            className="w-full h-14"
          />
          <DarkBlueButton
            className="w-full h-14"
            name="Save"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </Modal>
  );
};

export default EditRelation;
