import React, { useState } from "react";
import { toast } from "react-toastify";
import { addRelation, getPatientByPhoneNo } from "../../../lib/apis/patient";
import { PatientState } from "../../../services/types";
import { LeftArrowWithTail } from "../../../assets/icons/Icons";

const ExistingPatient = ({
  quarter,
  patient,
  setRelationStep,
}: {
  quarter?: boolean;
  patient: PatientState;
  setRelationStep: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [phno, setPhno] = useState("");
  const [type, setType] = useState("");
  const [relationToAdd, setRelationToAdd] = useState<{
    name: "";
    gender: "";
    id: "";
  }>();

  return (
    <div className="flex flex-col space-y-5">
      <button
        type="button"
        className="w-6"
        onClick={() => setRelationStep("add")}
      >
        <LeftArrowWithTail />
      </button>
      <div className="flex flex-col w-full">
        <p className="font-light text-modalText text-sm mb-1">Phone Number</p>
        <input
          className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
          placeholder="Enter phone number"
          value={phno}
          onChange={(e) => setPhno(e.target.value)}
          minLength={10}
          maxLength={10}
          required
          autoFocus
        />
      </div>
      <button
        type="button"
        className={`rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover ${
          quarter ? "w-1/4" : ""
        }`}
        onClick={async () => {
          if (phno === "" || isNaN(Number(phno)) || phno.length !== 10) {
            toast.error("Please enter a valid phone number.");
          } else {
            const res = await getPatientByPhoneNo("91" + phno);
            if (res?.status === 200) {
              console.log("patient", res.data.result);
              const data = res.data.result[0];
              setRelationToAdd({
                name: data.full_name,
                gender: data.gender,
                id: data.patient_id,
              });
            } else {
              toast.error("Patient not found.");
            }
          }
        }}
      >
        Fetch details
      </button>
      {relationToAdd && (
        <>
          <div className="flex flex-row space-x-3">
            <button
              type="button"
              className=" bg-available text-white px-4 py-2 rounded-[20px] w-full"
            >
              {relationToAdd.name}, {relationToAdd.gender}
            </button>
            <div className="flex flex-col w-full">
              <p className="font-light text-modalText text-sm mb-1">
                Relationship Type
              </p>
              <select
                name="type"
                id="type"
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Relation</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Husband">Husband</option>
                <option value="Wife">Wife</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <button
            type="button"
            className={`rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover ${
              quarter ? "w-1/4" : ""
            }`}
            onClick={async () => {
              if (relationToAdd && relationToAdd.id !== "" && type !== "") {
                const res = await addRelation(
                  patient.patient_id,
                  relationToAdd?.id,
                  type
                );
                if (res?.status === 200) {
                  console.log(res.data.result);
                  setRelationStep("");
                  setRelationToAdd({
                    name: "",
                    gender: "",
                    id: "",
                  });
                  toast.success("Relation added successfully!");
                } else toast.error("Error occured while adding relation.");
              } else if (type === "")
                toast.error("Please select relationship type.");
            }}
          >
            Add Relation
          </button>
        </>
      )}
    </div>
  );
};

export default ExistingPatient;
