import axios from "axios";
import { header } from "../utils/header";
import { LoginData } from "../../services/types";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

interface ReportFields {
  hospital_id: string | undefined;
  request_type: string | undefined;
  request_subject: string | undefined;
  request_description: string | undefined;
  request_status: string | undefined;
  request_priority: string | undefined;
  email: string | undefined;
}

//Insert Support Request
export const insertSupport = async (data: ReportFields) => {
  try {
    const res = await apiClient.post(`${baseURL}/support`, {
      hospital_id: data.hospital_id,
      request_type: data.request_type,
      request_subject: data.request_subject,
      request_description: data.request_description,
      request_status: data.request_status,
      request_priority: data.request_priority,
      email: data.email,
    });
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};
