import React from "react";
import CountUp from "react-countup";

const DashboardCard2 = ({ value, name }: { value: string; name: string }) => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full  bg-white rounded-lg px-4 py-5 lg:px-5 lg:py-6"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <div className="text-center h-20">
        <p className="text-4xl lg:text-4xl text-dark tracking-[0.4px] font-bold">
          <CountUp duration={2} className="counter" end={Number(value)} />
          <span className="text-2xl text-dbGray">/{value}</span>
        </p>
        <p className="text-sm lg:text-sm text-dbGray tracking-[0.2px] mt-2 font-medium">
          {name}
        </p>
      </div>
      <p className="text-lg mt-4 md:mt-8 relative top-3 text-dbGray tracking-[0.4px] font-bold h-14 text-center">
        Available Doctors
      </p>
    </div>
  );
};

export default DashboardCard2;
