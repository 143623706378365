import axios from "axios";
import { header } from "../utils/header";
import { updateHospital } from "../utils/types";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

//Get Hospitals by User ID
export const getHospitalListByUserId = async (user_id: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/user/${user_id}/hospitals`);
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update Hospital
export const updateHosptialDetails = async ({
  hospital_id,
  hospital_name,
  hospital_timing,
  hospital_address,
}: updateHospital) => {
  const res = await apiClient.put(`${baseURL}/hospital/${hospital_id}`, {
    name: hospital_name,
    timing: hospital_timing,
    address: hospital_address,
  });
  return res;
};

//GET HOSPITAL DETAILS
export const getHosptialDetails = async (id: string | undefined) => {
  try {
    const res = await apiClient.get(`${baseURL}/hospital/${id}`);
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};
