import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Option } from "react-dropdown";
import moment from "moment";
import { useParams } from "react-router-dom";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import SelectSlot from "../../atoms/SelectSlot";
import { LeftArrow, RightArrow } from "../../../assets/icons/Icons";
import { estimateVisitTime, trialClinics } from "../../../lib/utils/constants";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import {
  getBookingListByAvailabilityId,
  getSlots,
  getTokens,
  rescheduleBooking,
} from "../../../lib/apis/booking";
import { DocAvailability } from "../../../lib/utils/types";
import { getDoctorAvailability } from "../../../lib/apis/doctor";
import NotFound from "../../atoms/buttons/NotFound";
import { getRouteSegment } from "../../../lib/utils/funcs";
import SelectToken from "../../atoms/SelectToken";
import { useUserData } from "../../../lib/contexts/UserContext";

const RescheduleModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  booking_id,
  session,
  type,
  booking_ids,
  mappingId,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  booking_id: string | undefined;
  session: string | undefined;
  type: string;
  booking_ids: any;
  mappingId: string | undefined;
}) => {
  const hospital_id = getRouteSegment(1);
  const { userData } = useUserData();
  const { setBookings, SelectedDate } = usePatientBooking();

  const [bookingDate, setBookingDate] = useState(SelectedDate);
  const [docSession, setDocSession] = useState<Option | undefined>();
  const [token, setToken] = useState<number>();
  const [allTokens, setAllTokens] = useState<Array<number>>([]);
  const [slicedTokens, setSlicedTokens] = useState<Array<number>>([]);
  const [bookingSlot, setBookingSlot] = useState<string>();
  const [allSlots, setAllSlots] = useState<Array<string>>([""]);
  const [slicedSlots, setSlicedSlots] = useState<Array<string>>([""]);
  const [allSessions, setAllSessions] = useState<Option[] | undefined>();
  const [index, setIndex] = useState<number | undefined>(
    moment(SelectedDate).day() + 1
  );
  const [queue_type, setQueue_type] = useState(
    allSessions?.filter((item) => item.value === docSession?.value)[0]?.data
      ?.queue_type
  );
  const param = useParams();
  const mapping_id =
    param.mapping_id === undefined
      ? String(mappingId)
      : String(param.mapping_id);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [sessionExp, setSessionExp] = useState(false);

    // Function to format current time as "hh:mmA"
    function formatCurrentTime(): string {
      const now = new Date();
      let hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
  
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert hour '0' to '12'
      const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();
  
      return `${hours}:${minutesStr}${ampm}`;
    }
 // Function to convert 12-hour time string to Date object
 function parseTimeCurrent(timeStr: string): Date {
  const [time, modifier] = timeStr.split(/(?<=\d)(?=[AP]M)/); // Split into time and AM/PM
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return new Date(0, 0, 0, hours, minutes);
}

// Function to convert 12-hour time string to Date object
function parseTime(timeStr: string): Date {
  const [time, modifier] = timeStr.split(/(?<=\d)(?=[AP]M)/); // Split into time and AM/PM
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return new Date(0, 0, 0, hours + 2 > 24 ? 24 : hours + 2, minutes);
}
  useEffect(() => {
    const currentTimeStr = formatCurrentTime();
    if (
      docSession &&
      docSession.value !== "" &&
      docSession.label !== "" &&
      currentTimeStr !== undefined
    ) {
      const timeToCompare = String(docSession?.label).split("-")[1];
      const currentTime = parseTimeCurrent(currentTimeStr);
      const compareTime = parseTime(timeToCompare);

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");

      if (
        currentTime > compareTime &&
        bookingDate === `${year}-${month}-${day}`
      ) {
        setSessionExp(true);
      } else {
        setSessionExp(false);
      }
    }
  },[docSession,bookingDate]);

  const showSlotsTokens = useCallback(async () => {
    if (!mapping_id || !docSession) return;
    // console.log(session);
    const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

    const data = {
      mapping_id: mapping_id,
      booked_date: bookingDate,
      number_of_people: 1,
      availability_id: docSession.value,
      start_time: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
      end_time: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
    };
    const slots_data = await getSlots(data);
    // console.log(slots_data?.data);

    if (slots_data?.status === 200 && slots_data.data.result.length !== 0) {
      const api_data: { start: string; end: string }[] = slots_data.data.result;

      // console.log("API Data:", api_data);

      const allSlots =
        bookingDate === moment().format("YYYY-MM-DD")
          ? api_data
              .filter((docSession) =>
                moment(docSession.end, "HH:mm").isAfter(moment())
              )
              .map((docSession) => `${docSession.start} - ${docSession.end}`)
          : api_data.map(
              (docSession) => `${docSession.start} - ${docSession.end}`
            );

      // console.log("All Slots:", allSlots);

      const slicedData = allSlots.slice(currentIndex, currentIndex + 10);

      // console.log("Sliced Data:", slicedData);

      if (allSlots) {
        setAllSlots(allSlots.length === 0 ? [""] : allSlots);
        setSlicedSlots(allSlots.length === 0 ? [""] : slicedData);
        setBookingSlot(slicedData.slice(0, 1)[0]);
      }
    } else if (slots_data?.data.length === 0) {
      setAllSlots([""]);
    }
    // console.log(data);
    const token_data = await getTokens(data);
    // console.log(token_data);

    if (token_data?.status === 200 && token_data.data.result.length !== 0) {
      const api_data: Array<number> = token_data.data.result;
      const allTokens =
        SelectedDate === bookingDate &&
        moment().isAfter(
          moment(String(docSession?.label).split(" - ")[1], "hh:mmA").add(
            30,
            "minutes"
          )
        )
          ? [0]
          : userData?.user_id == "5a6155df-6b50-4621-a035-c1953bb0f249"
          ? api_data.filter((token) => token % 2 !== 0)
          : api_data;

      console.log("allTokens", allTokens);

      const slicedData = allTokens.slice(currentIndex, currentIndex + 10);

      console.log("slicedData", slicedData);

      if (allTokens.length !== 0) {
        setAllTokens(allTokens);
        setSlicedTokens(slicedData);
        setToken(slicedData.slice(0, 1)[0]);
        // console.log(slicedData.slice(0, people)[0]);
      }
    }
  }, [mapping_id, docSession, SelectedDate, currentIndex]);

  useEffect(() => {
    showSlotsTokens();
    setQueue_type(
      allSessions?.filter((item) => item.value === docSession?.value)[0]?.data
        ?.queue_type
    );
  }, [modalIsOpen, docSession, showSlotsTokens]);

  useEffect(() => {
    setBookingDate(SelectedDate);
    setIndex(moment(SelectedDate).day() + 1);
  }, [modalIsOpen]);

  console.log("resource id ->", String(session));

  useEffect(() => {
    // const now = moment();
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      console.log(mapping_id);
      if (res?.status === 200) {
        const api_data: DocAvailability[] = res.data.result.doctor_availability;
        const data = api_data
          .filter((i) => i.day_of_week === index)
          .map((item) => {
            return {
              value: String(item.availability_id),
              label: `${moment(item.start_time, "HH:mm:ss").format(
                "hh:mmA"
              )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,
              data: {
                wait_time: item.wait_time.minutes,
                queue_type: item.queue_type,
              },
              start_time: item.start_time,
            };
          })
          .sort((a, b) => {
            // Sort by ascending order of time
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          });
        setAllSessions(data);
        setDocSession(data && data[0]);
      }
    };    
    if (mapping_id!=="undefined") {
      fetchDocAvailability();
    }
   
  }, [modalIsOpen, bookingDate]);
  

  const handleTokenBooking = async () => {
    if (bookingDate && docSession) {
      closeModal();

      if (type === "each") {
        if (
          token &&
          booking_id &&
          (queue_type === "Token" || queue_type === "Session")
        ) {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            token_number: token,
            queue_type: queue_type,
          };
          console.log(booking_id);
          console.log(req);

          const res = await rescheduleBooking(booking_id, req);

          if (res?.status === 200) {
            toast.success(
              `Appointment rescheduled to ${moment(bookingDate).format(
                "DD MMM YYYY"
              )}!`
            );
            setBookingDate(SelectedDate);
            setCurrentIndex(0);
            console.log(res.data);

            const booked_data = await getBookingListByAvailabilityId(
              String(session),
              SelectedDate
            );
            if (booked_data?.status === 200) {
              setBookings(booked_data.data.result);
            } else {
              setBookings(undefined);
            }
          }
        }
      } else {
        if (
          token &&
          booking_ids &&
          (queue_type === "Token" || queue_type === "Session")
        ) {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            token_number: token,
            queue_type: queue_type,
          };

          booking_ids.map(async (data: any) => {
            const res = await rescheduleBooking(data.booking_id, req);

            if (res?.status === 200) {
              toast.success(
                `Appointment rescheduled to ${moment(bookingDate).format(
                  "DD MMM YYYY"
                )}!`
              );
              setBookingDate(SelectedDate);
              setCurrentIndex(0);
              console.log(res.data);

              const booked_data = await getBookingListByAvailabilityId(
                String(session),
                SelectedDate
              );
              if (booked_data?.status === 200) {
                setBookings(booked_data.data.result);
              } else {
                setBookings(undefined);
              }
            }
          });
        }
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSlotBooking = async () => {
    if (bookingDate && docSession) {
      closeModal();

      if (type === "each") {
        if (
          bookingSlot &&
          booking_id &&
          queue_type !== "Token" &&
          queue_type !== "Session"
        ) {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");
          const [slotStart, slotEnd] = bookingSlot.split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start:
              queue_type === "Session"
                ? moment(seshStart, "hh:mmA").format("HH:mm:ss")
                : moment(slotStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end:
              queue_type === "Session"
                ? moment(seshEnd, "hh:mmA").format("HH:mm:ss")
                : moment(slotEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            queue_type: queue_type,
          };
          console.log(booking_id);
          console.log(req);

          const res = await rescheduleBooking(booking_id, req);

          if (res?.status === 200) {
            toast.success(
              `Appointment rescheduled to ${moment(bookingDate).format(
                "DD MMM YYYY"
              )}!`
            );
            setBookingDate(SelectedDate);
            setCurrentIndex(0);
            console.log(res.data);

            const booked_data = await getBookingListByAvailabilityId(
              String(session),
              SelectedDate
            );
            if (booked_data?.status === 200) {
              setBookings(booked_data.data.result);
            } else {
              setBookings(undefined);
            }
          }
        }
      } else {
        if (
          bookingSlot &&
          booking_ids &&
          queue_type !== "Token" &&
          queue_type !== "Session"
        ) {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");
          const [slotStart, slotEnd] = bookingSlot.split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start:
              queue_type === "Session"
                ? moment(seshStart, "hh:mmA").format("HH:mm:ss")
                : moment(slotStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end:
              queue_type === "Session"
                ? moment(seshEnd, "hh:mmA").format("HH:mm:ss")
                : moment(slotEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            queue_type: queue_type,
          };

          booking_ids.map(async (data: any) => {
            const res = await rescheduleBooking(data.booking_id, req);

            if (res?.status === 200) {
              toast.success(
                `Appointment rescheduled to ${moment(bookingDate).format(
                  "DD MMM YYYY"
                )}!`
              );
              setBookingDate(SelectedDate);
              setCurrentIndex(0);
              console.log(res.data);

              const booked_data = await getBookingListByAvailabilityId(
                String(session),
                SelectedDate
              );
              if (booked_data?.status === 200) {
                setBookings(booked_data.data.result);
              } else {
                setBookings(undefined);
              }
            }
          });
        }
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async () => {
    if (bookingDate && docSession) {
      closeModal();

      if (type === "each") {
        if (booking_id && queue_type === "Session") {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            queue_type: queue_type,
          };
          console.log(booking_id);
          console.log(req);

          const res = await rescheduleBooking(booking_id, req);

          if (res?.status === 200) {
            toast.success(
              `Appointment rescheduled to ${moment(bookingDate).format(
                "DD MMM YYYY"
              )}!`
            );
            setBookingDate(SelectedDate);
            setCurrentIndex(0);
            console.log(res.data);

            const booked_data = await getBookingListByAvailabilityId(
              String(session),
              SelectedDate
            );
            if (booked_data?.status === 200) {
              setBookings(booked_data.data.result);
            } else {
              setBookings(undefined);
            }
          }
        }
      } else {
        if (booking_ids && queue_type === "Session") {
          const [seshStart, seshEnd] = String(docSession?.label).split(" - ");

          const req = {
            added_by: userData?.user_id,
            booking_mode: "reschedule",
            booked_date: bookingDate,
            booking_session_start_time: moment(seshStart, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booking_session_end_time: moment(seshEnd, "hh:mmA").format(
              "HH:mm:ss"
            ),
            booked_slot_start: moment(seshStart, "hh:mmA").format("HH:mm:ss"),
            booked_slot_end: moment(seshEnd, "hh:mmA").format("HH:mm:ss"),
            availability_id: String(docSession?.value),
            queue_type: queue_type,
          };
          console.log(booking_id);
          console.log(req);

          booking_ids.map(async (data: any) => {
            const res = await rescheduleBooking(data.booking_id, req);

            if (res?.status === 200) {
              toast.success(
                `Appointment rescheduled to ${moment(bookingDate).format(
                  "DD MMM YYYY"
                )}!`
              );
              setBookingDate(SelectedDate);
              setCurrentIndex(0);
              console.log(res.data);

              const booked_data = await getBookingListByAvailabilityId(
                String(session),
                SelectedDate
              );
              if (booked_data?.status === 200) {
                setBookings(booked_data.data.result);
              } else {
                setBookings(undefined);
              }
            }
          });
        }
      }
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    queue_type === "Token"
      ? handleTokenBooking()
      : queue_type === "Session"
      ? handleSessionBooking()
      : handleSlotBooking();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Reschedule</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            setBookingDate(SelectedDate);
            setCurrentIndex(0);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          {hospital_id && trialClinics.includes(hospital_id) ? (
            <div className="px-6 py-5 bg-lightGrey flex justify-center items-center">
              <p className="font-semibold inline-block my-5">
                Reschedule is locked in trial.
              </p>
            </div>
          ) : (
            <div className="px-6 py-5 bg-lightGrey">
              <p className=" text-modalText text-sm mb-3">
                Note: Reschedule a patient's appointment, either by changing the
                date, session or altering the time docSession.
              </p>
              <div className="">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Booking For
                </p>
                <div className="flex flex-row">
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-2"
                    placeholder="Select Date"
                    type="date"
                    value={bookingDate}
                    min={moment().format("YYYY-MM-DD")}
                    max={moment().add(3, "months").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setBookingDate(e.target.value);
                      const selectedDate = e.target.value;
                      const dayOfWeek = moment(selectedDate).day() + 1;
                      setIndex(dayOfWeek);
                    }}
                  />
                  {allSessions && allSessions.length > 0 ? (
                    <select
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                      onChange={(e) => {
                        setAllSlots([""]);
                        setAllTokens([0]);
                        const label =
                          e.target.options[e.target.selectedIndex].text;
                        const value = e.target.value;
                        setDocSession({ label, value });
                        console.log(
                          allSessions?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        );
                        setQueue_type(
                          allSessions?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        );
                      }}
                    >
                      <option disabled hidden>
                        {String(docSession?.label).split(" - ")[0] +
                          " - " +
                          String(docSession?.label).split(" - ")[1]}
                      </option>
                      {allSessions.map((session) => (
                        <option key={session.value} value={session.value}>
                          {String(session?.label).split(" - ")[0] +
                            " - " +
                            String(session?.label).split(" - ")[1]}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <NotFound text="No sessions" />
                  )}
                </div>
              </div>
              {queue_type !== "Session" && allSessions && allSessions?.length>0 && (
                <div className="flex flex-col w-full my-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    {estimateVisitTime.includes(String(hospital_id))
                      ? "Estimate Visit Time"
                      : "Choose slot timings"}
                  </p>
                  {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                  <div>
                    {/* {currentIndex > 0 && (
                      <button
                        type="button"
                        className="bg-white p-2 rounded-lg hover:opacity-70"
                        onClick={showPrevious}
                      >
                        <LeftArrow />
                      </button>
                    )} */}
                    <div className="flex flex-wrap justify-start my-3 gap-3">
                      {queue_type === "Token" ? (
                        allTokens.length !== 0 && allTokens[0] !== 0 ? (
                          allTokens.map((item, index) => (
                            <React.Fragment key={index}>
                              <SelectToken
                                selected={token === item}
                                setToken={setToken}
                                item={item}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <NotFound text="No Tokens Available" />
                        )
                      ) : allSlots.length !== 1 || allSlots[0] !== "" ? (
                        allSlots.map((item, index) => (
                          <React.Fragment key={index}>
                            <SelectSlot
                              item={item}
                              setSlot={setBookingSlot}
                              selected={bookingSlot?.includes(item) || false}
                              people={1}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <NotFound text="No Slots Available" />
                      )}
                    </div>
                    {/* <button
                      type="button"
                      className="bg-white p-2 rounded-lg hover:opacity-70"
                      onClick={showNext}
                    >
                      <RightArrow />
                    </button> */}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            className="w-full h-14"
            handleClick={() => {
              closeModal();
              setBookingDate(SelectedDate);
              setCurrentIndex(0);
            }}
          />
          {allSessions?.length !== 0 &&
            hospital_id &&
            !trialClinics.includes(hospital_id) && (
              <DarkBlueButton
                name="Save"
                type="submit"
                className={!sessionExp?"w-full h-14":"w-full h-14 !bg-gray-500 !hover:bg-gray-500 cursor-not-allowed"}
              />
            )}
        </div>
      </form>
    </Modal>
  );
};

export default RescheduleModal;
