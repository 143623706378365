import React, { useCallback, useEffect, useState } from "react";
import ExistingPatient from "./ExistingPatient";
import { PatientState, Relations } from "../../../services/types";
import { getPatientRelations } from "../../../lib/apis/patient";
import NewPatient from "./NewPatient";
import { LeftArrowWithTail } from "../../../assets/icons/Icons";

const Step2 = ({
  relations,
  patient,
  relationStep,
  setPatient,
  setRelations,
  setRelationStep,
}: {
  relations: Relations[];
  patient: PatientState;
  relationStep: string;
  setPatient: React.Dispatch<React.SetStateAction<PatientState>>;
  setRelations: React.Dispatch<React.SetStateAction<Relations[]>>;
  setRelationStep: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const fetchRelations = useCallback(async () => {
    const api_data = await getPatientRelations(patient.patient_id);
    if (api_data?.status === 200) {
      const relations = api_data.data.result;
      console.log("relations", relations);
      setRelations(relations);
    } else
      setRelations([{ relationship_type: "", patient_id: "", full_name: "" }]);
  }, []);

  useEffect(() => {
    fetchRelations();
  }, [relationStep]);

  return (
    <>
      {relationStep === "add" ? (
        <div>
          <button
            type="button"
            className="w-6"
            onClick={() => setRelationStep("")}
          >
            <LeftArrowWithTail />
          </button>
          <div className="flex flex-row justify-center space-x-5">
            <button
              type="button"
              className="rounded-lg opacity-80 p-4 text-sbTextHover border-[1px] border-sbTextHover hover:bg-sbTextHover hover:text-white"
              onClick={() => setRelationStep("existing")}
            >
              Existing Patient
            </button>
            <button
              type="button"
              className="rounded-lg opacity-80 p-4 text-sbTextHover border-[1px] border-sbTextHover hover:bg-sbTextHover hover:text-white"
              onClick={() => setRelationStep("new")}
            >
              New Patient
            </button>
          </div>
        </div>
      ) : relationStep === "existing" ? (
        <ExistingPatient patient={patient} setRelationStep={setRelationStep} />
      ) : relationStep === "new" ? (
        <NewPatient patient={patient} setRelationStep={setRelationStep} />
      ) : (
        <div className="space-y-3">
          <p className=" text-modalText">Book for a relation instead</p>
          <div className="flex flex-row space-x-3 !mb-3">
            {relations[0].patient_id === "" ? (
              <></>
            ) : (
              relations.map((item) => (
                <div className="flex flex-col items-center">
                  <button
                    type="button"
                    className={`${
                      patient.patient_id === item.patient_id
                        ? "bg-editBG text-white"
                        : " hover:bg-purple-400 hover:text-white"
                    } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
                    onClick={() => {
                      console.log(item);
                      setPatient((prevState) => ({
                        ...prevState,
                        patient_id: item.patient_id,
                        full_name: item.full_name,
                      }));
                    }}
                  >
                    {item.full_name}
                  </button>
                  <p className="text-sm text-docDetail">
                    {
                      // item.patient_id === patient.patient_id? "":
                      item.relationship_type
                    }
                  </p>
                </div>
              ))
            )}
          </div>
          <button
            type="button"
            className="rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover !mt-0"
            onClick={() => setRelationStep("add")}
          >
            + Add relation
          </button>
        </div>
      )}
    </>
  );
};

export default Step2;
