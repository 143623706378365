import axios from "axios";
import { header } from "../utils/header";
import { LoginData } from "../../services/types";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

//Get Medicines Names
export const getMedicines = async () => {
  try {
    const res = await apiClient.get(`${baseURL}/medicines`);
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Insert Medicines Names
export const insertMedicine = async (name: string | undefined) => {
  try {
    const res = await apiClient.post(`${baseURL}/medicines/insert`, {
      name: name,
    });
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};
