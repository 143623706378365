import React, { useEffect, useState } from "react";
import { usePatientBooking } from "../../lib/contexts/PatientBookingContext";
import { LeftArrow, RightArrow } from "../../assets/icons/Icons";

type TabsProps = {
  tabsConfig: any;
  rightSide?: any;
  defaultIndex?: number;
};

const Tabs: React.FC<TabsProps> = ({ tabsConfig, rightSide, defaultIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);
  const { SelectedDate } = usePatientBooking();

  useEffect(() => {
    setSelectedIndex(0);
  }, [SelectedDate]);

  const handleClick = (index: number) => setSelectedIndex(index);

  // const tabsBox: HTMLElement | null = document.querySelector(".tabs-box");
  // if (tabsBox !== null) {
  //   const arrowIcons: NodeListOf<Element> =
  //     document.querySelectorAll(".icon svg");

  //   let isDragging: boolean = false;

  //   const handleIcons = (scrollVal: number): void => {
  //     let maxScrollableWidth: number =
  //       tabsBox.scrollWidth - tabsBox.clientWidth;

  //     (arrowIcons[0].parentElement as HTMLElement).style.display =
  //       scrollVal <= 0 ? "none" : "flex";
  //     (arrowIcons[1].parentElement as HTMLElement).style.display =
  //       maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
  //   };

  //   arrowIcons.forEach((icon: Element) => {
  //     icon.addEventListener("click", () => {
  //       let scrollWidth: number = (tabsBox.scrollLeft +=
  //         icon.id === "left" ? -340 : 340);

  //       handleIcons(scrollWidth);
  //     });
  //   });

  //   const dragging = (e: MouseEvent): void => {
  //     if (!isDragging) return;
  //     tabsBox.classList.add("dragging");
  //     tabsBox.scrollLeft -= e.movementX;
  //     handleIcons(tabsBox.scrollLeft);
  //   };

  //   const dragStop = (): void => {
  //     isDragging = false;
  //     tabsBox.classList.remove("dragging");
  //   };

  //   tabsBox.addEventListener("mousedown", () => (isDragging = true));
  //   tabsBox.addEventListener("mousemove", dragging);
  //   document.addEventListener("mouseup", dragStop);
  // }

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between shadow-sm border-0 max-w-full md:max-w-[90vw] xl:max-w-[80vw]">
        {/* <div className="flex flex-wrap md:w-3/5 pl-5 space-x-6 text-queueText text-sm font-medium text-center overflow-x-auto max-h-[43px]">
          {tabsConfig.map((tab: any, index: number) => (
            <button
              key={`tab-${index}`}
              onClick={() => handleClick(index)}
              className={
                selectedIndex === index
                  ? "inline-block pt-2 px-1 pb-5 text-queueHover border-b-[1px] border-queueHover rounded-t-lg"
                  : "inline-block pt-2 px-1 pb-5 border-b-[1px] border-transparent rounded-t-lg hover:text-gray-600 hover:border-queueText"
              }
            >
              {tab.label}
              {tab.count}
            </button>
          ))}
        </div> */}
        <div className=" side_bar flex flex-nowrap px-5 md:w-3/5 gap-6 md:gap-3 text-queueText text-sm font-medium overflow-x-auto">
          {/* <div
            className={
              tabsConfig[0].label === "All" && tabsConfig.length > 7
                ? "icon cursor-pointer  mt-[-5px] flex"
                : "icon cursor-pointer  mt-[-5px] flex lg:hidden"
            }
            style={{ width: "max-width" }}
          >
            <LeftArrow />
          </div> */}

          {tabsConfig.map((tab: any, index: number) => (
            <button
              key={`tab-${index}`}
              onClick={() => handleClick(index)}
              className={
                selectedIndex === index
                  ? "tab inline-block pt-2 px-1 pb-2 text-queueHover border-b-[1px] border-queueHover rounded-t-lg min-w-max mb-3"
                  : "tab inline-block pt-2 px-1 pb-2 border-b-[1px] border-transparent rounded-t-lg hover:text-gray-600hover:border-queueText min-w-max mb-3"
              }
            >
              {tab.label}
              {tab.count}
            </button>
          ))}

          {/* <div
            className={
              tabsConfig[0].label === "All" && tabsConfig.length > 7
                ? "icon cursor-pointer  mt-[-5px] flex"
                : "icon cursor-pointer  mt-[-5px] flex lg:hidden"
            }
            style={{ width: "max-width" }}
          >
            <RightArrow />
          </div> */}
        </div>

        <div className="w-full md:w-2/5 flex md:justify-end">{rightSide}</div>
      </div>
      <div>
        {tabsConfig.map((tab: any, index: number) => (
          <div key={`tabpanel-${index}`} hidden={selectedIndex !== index}>
            {tab.content}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
