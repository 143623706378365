import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import { useUserData } from "../lib/contexts/UserContext";
import { Logout } from "../assets/icons/Icons";
import PasswordModal from "../components/organisms/modals/PasswordModal";
import { modalStyles } from "../assets/styles/modalStyles";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getRouteSegment } from "../lib/utils/funcs";
import DoctorButtonMap from "../components/organisms/DoctorButtonMap";
import { getButtonsByHospitalId } from "../lib/apis/button";
import { Buttons, Doctor } from "../lib/utils/types";
import { getDoctorListByHospitalId } from "../lib/apis/doctor";
import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import AddButtonModal from "../components/organisms/modals/AddButtonModal";

const Settings = () => {
  const id = getRouteSegment(1);
  const { userData, handleLogout } = useUserData();
  const { hospitalID, setHospitalID, setDoctorsData } = useHospDocData();

  const [visible, setVisible] = useState(false);
  const [addvisible, setAddVisible] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(false);
  const [buttons, setButtons] = useState<Buttons[]>();
  const [doctors, setDoctors] = useState<Doctor[]>();

  // const buttons = [
  //   {
  //     button_id: "9d70f3ef-f8c8-4e52-ad2b-2c5298c4ebdd",
  //     mapped_doctors: ["Shekar Subbaiah", "Javagal"],
  //   },
  //   {
  //     button_id: "8e69b6f0-3338-4352-ad9f-f816e105aa4e",
  //     mapped_doctors: ["Ramapriya Kalkuntep"],
  //   },
  // ];

  useEffect(() => {
    const fetchButtonsDoctors = async () => {
      const res = await getButtonsByHospitalId(hospitalID);
      setButtons(undefined);
      const docs = await getDoctorListByHospitalId(hospitalID);
      setDoctors(undefined);
      if (docs?.status === 200) {
        console.log("doctorsData", docs.data.result);
        setDoctorsData(docs.data.result);
        setDoctors(docs.data.result);
      }
      if (res?.status === 200) {
        console.log("buttonsData", res.data.result);
        setButtons(res.data.result);
      } else console.log(res.data.error);
    };

    fetchButtonsDoctors();
  }, [hospitalID, userData, addvisible]);

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Settings"} />
      <div className="bg-white flex flex-col mt-6 ml-7 mr-11 rounded-lg">
        <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
          <p className="text-dark font-medium">User Credentials</p>
          <button
            className="bg-white hover:bg-onLeave hover:text-white flex flex-row items-center space-x-1 text-onLeave border-[1px] border-onLeave hover:border-red-400 px-5 py-[6px] rounded-[20px]"
            onClick={handleLogout}
          >
            <Logout />
            <p className="text-xs lg:text-base">Logout</p>
          </button>
        </div>
        <div className="rounded-b-lg p-4 border-[1px] border-t-0 border-doctorsBorder space-y-6">
          <div className="flex flex-col md:grid md:grid-cols-12">
            <div className="flex flex-col col-span-4">
              <p className="text-docDetail text-xs mb-[6px]">Email Address</p>
              <p className="text-dark md:text-sm">{userData?.email}</p>
            </div>
            <div className="flex flex-row col-span-4 space-x-5 mt-10 md:mt-0">
              <div className="flex flex-col">
                <p className="text-docDetail text-xs mb-[6px]">Password</p>
                <p className="text-dark md:text-sm">************</p>
              </div>
              <button
                className="bg-editBG hover:bg-queueHover px-4 md:py-0 lg:py-1 rounded-[20px] flex justify-center items-center"
                onClick={() => setVisible(true)}
              >
                <p className="text-white text-sm">Change</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {userData?.role !== "Doctor" && (
        <div className="bg-white flex flex-col mt-6 ml-7 mr-11 rounded-lg">
          <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
            <p className="text-dark font-medium">Doctor-Button Mapping</p>
            <DarkBlueButton
              name="Add Button"
              handleClick={() => setAddVisible(true)}
            />
          </div>
          <div className="rounded-b-lg p-4 border-[1px] border-t-0 border-doctorsBorder space-y-6">
            {buttons &&
              buttons.map((button, index) => (
                <DoctorButtonMap
                  key={button.button_id}
                  button_id={button.button_id}
                  button_name={index + 1}
                  mapped_doctors={button.mapped_doctors}
                  doctors={doctors}
                  setButtons={setButtons}
                />
              ))}
            {!buttons && <p>No buttons found</p>}
          </div>
        </div>
      )}
      <PasswordModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
      />
      <AddButtonModal
        modalIsOpen={addvisible}
        closeModal={() => setAddVisible(false)}
        customStyles={modalStyles}
        doctors={doctors}
        setButtons={setButtons}
      />
    </div>
  );
};

export default Settings;
