import React, { useEffect, useState } from "react";
import { Logo } from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import SidebarButton from "../atoms/buttons/SidebarButton";
import { getRouteSegment } from "../../lib/utils/funcs";
import { mainItems, supportItems } from "../../assets/data/sidebar";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";

const Sidebar = () => {
  const [isHovered, setIsHovered] = useState("");

  const navigate = useNavigate();
  const { hospitalID, hospData } = useHospDocData();

  return (
    <div className="bg-white hidden xl:flex xl:flex-col w-1/5 min-h-screen px-6 border-solid border-e-[1px] border-sbBorder">
      <div
        className="mt-3 cursor-pointer"
        onClick={() => navigate("/" + hospitalID + "/dashboard")}
      >
        {hospData?.is_easy_heal ? (
          <img
            src={require("../../assets/images/easyHealsLogo.jpg")}
            className="md:w-28 lg:w-[150px]"
            alt="EasyHeals"
          />
        ) : (
          <Logo style={"md:w-28 lg:w-[150px]"} />
        )}
      </div>
      <div className="mt-8 flex flex-col">
        <p className="ml-5 uppercase font-medium md:text-[9px] lg:text-[11px] mb-1">
          Main
        </p>
        <div className="flex flex-col">
          {mainItems.map((item, index) => (
            <SidebarButton
              key={index}
              index={index}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              name={item.name}
              icon={item.icon}
              hoverIcon={item.hoverIcon}
              onPress={() => {
                navigate(hospitalID + "/" + item.name.toLowerCase());
              }}
              isSelected={getRouteSegment(2) === item.name.toLowerCase()}
            />
          ))}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <p className="ml-5 uppercase font-medium md:text-[9px] lg:text-[11px] mb-1">
          Support
        </p>
        <div className="flex flex-col">
          {supportItems.map((item, index) => (
            <SidebarButton
              key={index}
              index={index}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              name={item.name}
              icon={item.icon}
              hoverIcon={item.hoverIcon}
              onPress={() => {
                navigate(
                  hospitalID + "/" + item.name.toLowerCase().split(" ")[0]
                );
              }}
              isSelected={
                getRouteSegment(2) === item.name.split(" ")[0].toLowerCase()
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
