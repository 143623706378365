import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import apiClient from "../../../lib/apis/fetch";
import { setCookie, getCookie } from "../../../lib/utils/cookies";
import { getRouteSegment } from "../../../lib/utils/funcs";

export default function BarGraph(type: any) {
  const [myData, setMyData] = useState(
    JSON.parse(String(getCookie("bookingOverview")))
  );
  const [dailyData, setDailyData] = useState(
    JSON.parse(String(getCookie("dailyOverview")))
  );
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const state = {
    series: [
      {
        name: "No show",
        data:
          String(type.type) === "daily"
            ? dailyData.daily_counts.map((item: any) => parseInt(item.no_shows))
            : myData.no_shows.map((item: any) => parseInt(item.no_shows)),
      },
      {
        name: "Show",
        data:
          String(type.type) === "daily"
            ? dailyData.daily_counts.map((item: any) => parseInt(item.bookings))
            : myData.monthly_bookings.map((item: any) =>
                parseInt(item.total_bookings)
              ),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories:
          String(type.type) === "daily"
            ? dailyData.daily_counts.map((item: any) => {
                const monthYear = item.day.split("/");
                const monthIndex = parseInt(monthYear[1]) - 1;
                return parseInt(monthYear[0]) + " " + monthNames[monthIndex];
              })
            : myData.monthly_bookings.map((item: any) => {
                const monthYear = item.month.split("/");
                const monthIndex = parseInt(monthYear[0]) - 1;
                return monthNames[monthIndex];
              }),
      },

      fill: {
        opacity: 1,
      },
    } as ApexOptions,
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}
