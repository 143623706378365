import React, { useState, ChangeEvent, useEffect } from "react";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import {
  addButtonMapping,
  getButtonsByHospitalId,
  removeButtonMapping,
} from "../../lib/apis/button";
import { toast } from "react-toastify";
import { Buttons, Doctor } from "../../lib/utils/types";
import { MdDelete } from "react-icons/md";

const DoctorButtonMap = ({
  button_id,
  button_name,
  mapped_doctors,
  doctors,
  setButtons,
}: {
  button_id: string;
  button_name: number;
  mapped_doctors: [
    {
      doctor_id: string;
      full_name: string;
    }
  ];
  doctors: Doctor[] | undefined;
  setButtons: (value: React.SetStateAction<Buttons[] | undefined>) => void;
}) => {
  const { hospitalID } = useHospDocData();

  const [addDoctor, setAddDoctor] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState(doctors);

  useEffect(() => {
    setFilteredDoctors(doctors);
  }, [doctors]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDoctorName(value);
    const filtered = doctors?.filter((doc) =>
      doc.full_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDoctors(filtered);
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    console.log(value);
    setSelectedDoctor(value);
  };

  const handleAddDoctor = async (button_id: string) => {
    if (addDoctor === false) {
      console.log(button_id);
      setAddDoctor(true);
    } else {
      console.log("Selected Doctor:", selectedDoctor);
      console.log(button_id, { mappingIds: [selectedDoctor] });
      const res = await addButtonMapping(button_id, {
        mappingIds: [selectedDoctor],
      });
      if (res?.status === 200) {
        setAddDoctor(false);
        const res = await getButtonsByHospitalId(hospitalID);
        if (res?.status === 200) {
          setButtons(res.data.result);
        }
        toast.success("Doctor added successfully!");
      } else {
        toast.error("Error occured while adding doctor.");
        setAddDoctor(false);
      }
    }
  };

  const handleRemoveDoctor = async (button_id: string, mapping_id: string) => {
    const res = await removeButtonMapping(button_id, mapping_id);
    if (res?.status === 200) {
      setAddDoctor(false);
      const res = await getButtonsByHospitalId(hospitalID);
      if (res?.status === 200) {
        setButtons(res.data.result);
      }
      toast.success("Doctor removed successfully!");
    } else {
      toast.error("Error occured while removing doctor.");
      setAddDoctor(false);
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = (buttonId: string) => {
    navigator.clipboard
      .writeText("https://api.doctrue.in/api/v1/queue/update/board/" + buttonId)
      .then(() => {
        setCopied(true);
        toast.success("Button Url Copied");
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center pb-4 border-b-[1px] border-doctorsBorder">
      <p className="font-medium">Button {button_name}</p>

      {addDoctor ? (
        <div className="flex flex-col space-y-3">
          <input
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            placeholder="Filter doctors..."
            type="text"
            value={doctorName}
            onChange={handleInputChange}
          />
          <select
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            value={selectedDoctor}
            onChange={handleSelectChange}
          >
            <option disabled hidden value="">
              Select doctor
            </option>
            {filteredDoctors?.map((doc) => (
              <option key={doc.mapping_id} value={doc.mapping_id}>
                {doc.full_name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div>
          <p className="font-medium">Mapped Doctors</p>
          <ol className="list-decimal list-inside">
            {mapped_doctors && mapped_doctors.length > 0 ? (
              mapped_doctors.map((doctor, index) => (
                <li key={index} className="flex gap-1">
                  <p className="w-40">
                    {" "}
                    {doctor ? doctor.full_name : "No Name Available"}
                  </p>

                  <MdDelete
                    className="w-5 h-5 text-red-600 relative top-0 transition-all hover:top-[-3px] cursor-pointer"
                    onClick={() => {
                      const mappingId = doctors?.filter(
                        (e) => e.doctor_id === doctor.doctor_id
                      );
                      if (mappingId) {
                        handleRemoveDoctor(button_id, mappingId[0].mapping_id);
                      }
                    }}
                  />
                </li>
              ))
            ) : (
              <li>No doctors available</li>
            )}
          </ol>
        </div>
      )}
      <div className="flex flex-row space-x-4">
        <button
          className="bg-editBG hover:bg-queueHover px-4 py-3 rounded-[20px] flex justify-center items-center"
          onClick={() => handleAddDoctor(button_id)}
        >
          <p className="text-white text-sm">Add Doctor</p>
        </button>
        {addDoctor && (
          <button
            className="border-[1px] border-onLeave text-onLeave hover:text-white hover:bg-onLeave px-4 py-3 rounded-[20px] flex justify-center items-center"
            onClick={() => setAddDoctor(false)}
          >
            <p className="text-sm">Cancel</p>
          </button>
        )}
        <button
          className="bg-editBG hover:bg-queueHover px-4 py-3 rounded-[20px] flex justify-center items-center"
          onClick={() => handleCopy(button_id)}
        >
          <p className="text-white text-sm">Copy Button Url</p>
        </button>
      </div>
    </div>
  );
};

export default DoctorButtonMap;
