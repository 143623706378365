import React, { useEffect, useState } from "react";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import { Option } from "react-dropdown";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import moment from "moment";
import { extendSession } from "../../../lib/apis/booking";

const ExtendSession = ({
  session,
  setSession,
  closeModal,
  resetStates,
  setStep,
}: {
  session: Option | undefined;
  setSession: (value: React.SetStateAction<Option | undefined>) => void;
  closeModal: () => void;
  resetStates: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [extend, setExtend] = useState(30);
  const [load, setLoad] = useState(true);

  const { SelectedDate } = usePatientBooking();

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 500);
  }, []);

  return (
    <>
      {!load && (
        <div>
          <p className="text-onLeave mb-3">
            Note: Maximum number of patients for this session has reached.
            Please press on extend session to add more bookings.
          </p>
          <div className="flex flex-row items-center space-x-3">
            <button
              type="button"
              className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
              onClick={async () => {
                setStep(1);
                resetStates();
                console.log(extend);
                const req = {
                  availability_id: session?.value,
                  adjustment_date: SelectedDate,
                  session_start_time: moment(
                    String(session?.label)?.split(" - ")[0],
                    "HH:mmA"
                  ).format("HH:mm:ss"),
                  extended_end_time: moment(
                    String(session?.label)?.split(" - ")[1],
                    "HH:mmA"
                  )
                    .add(extend, "minutes")
                    .format("HH:mm:ss"),
                };
                console.log(req);
                const res = await extendSession(req);
                const data = res.data.result;
                console.log(data);
                setSession({
                  ...session,
                  value: data.availability_id,
                  label: `${moment(data.session_start_time, "HH:mm:ss").format(
                    "hh:mmA"
                  )} - ${moment(data.extended_end_time, "HH:mm:ss").format(
                    "hh:mmA"
                  )}`,
                });
              }}
            >
              Extend session ({extend < 60 ? extend : extend / 60}
              {extend < 60 ? "min" : "hr"})
            </button>
            <WhiteButton
              name="+"
              handleClick={() => {
                if (extend < 120) setExtend(extend + 30);
              }}
              className="border-sbTextHover text-2xl !py-1 !px-3"
            />
            <WhiteButton
              name="-"
              handleClick={() => {
                if (extend > 30) setExtend(extend - 30);
              }}
              className="border-sbTextHover text-2xl !py-1 !px-4"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ExtendSession;
