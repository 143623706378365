import {
  AppointmentsHover,
  DashboardHover,
  DoctorHover,
  PatientHover,
  SettingsHover,
} from "../icons/HoverIcons";
import {
  Dashboard,
  Schedule,
  Doctor,
  Patient,
  Appointments,
  Help,
  Settings,
} from "../icons/Icons";

import { MdOutlineContactSupport } from "react-icons/md";

export const mainItems = [
  {
    name: "Dashboard",
    icon: <Dashboard style={"md:w-5 lg:w-6"} />,
    hoverIcon: <DashboardHover style={"md:w-5 lg:w-6"} />,
  },
  {
    name: "Doctors",
    icon: <Doctor style={"md:w-5 lg:w-6"} />,
    hoverIcon: <DoctorHover style={"md:w-5 lg:w-6"} />,
  },
  {
    name: "Appointments",
    icon: <Appointments style={"md:w-5 lg:w-6"} />,
    hoverIcon: <AppointmentsHover style={"md:w-5 lg:w-6"} />,
  },
  {
    name: "Patients",
    icon: <Patient style={"md:w-5 lg:w-6"} />,
    hoverIcon: <PatientHover style={"md:w-5 lg:w-6"} />,
  },
];

export const supportItems = [
  // {
  //   name: "Help Center",
  //   icon: <Help />,
  //   hoverIcon: <HelpHover />,
  // },
  {
    name: "Settings",
    icon: <Settings style={"md:w-5 lg:w-6"} />,
    hoverIcon: <SettingsHover style={"md:w-5 lg:w-6"} />,
  },
  {
    name: "Support",
    icon: <MdOutlineContactSupport className="md:w-5 md:h-5 lg:w-6 lg:h-6" />,
    hoverIcon: (
      <MdOutlineContactSupport className="md:w-5 md:h-5 lg:w-6 lg:h-6" />
    ),
  },
];
