import React from "react";
import moment from "moment";

const SelectSlot = ({
  selected,
  setSlot,
  item,
  people,
}: {
  selected: boolean;
  setSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
  item: string;
  people: number;
}) => {
  const handleTokenClick = () => {
    if (item !== undefined && typeof item === "string") {
      console.log(item);
      setSlot((prevToken) => {
        // Assuming 'people' is still relevant and determines whether to set or reset the token
        if (prevToken === undefined) {
          return item; // Set the new token if there was no previous token
        } else {
          // Compare the new token with the previous one; this logic may change depending on your requirements
          return prevToken === item ? undefined : item; // Toggle the token or update it
        }
      });

      // For debugging
      // console.log(`Token updated to: ${item}`);
    }
  };

  // Display logic remains unchanged, adjust as necessary for the new 'item' structure
  if (selected) {
    return (
      <button
        className="p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-full md:w-[40%] lg:w-[31%]"
        type="button"
      >
        <p>
          {moment(item.split(" - ")[0], "HH:mm:ss").format("hh:mmA") +
            " - " +
            moment(item.split(" - ")[1], "HH:mm:ss").format("hh:mmA")}
        </p>
      </button>
    );
  }

  return (
    <button
      className="p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-full md:w-[40%] lg:w-[31%]"
      type="button"
      onClick={handleTokenClick}
    >
      <p>
        {moment(item.split(" - ")[0], "HH:mm:ss").format("hh:mmA") +
          " - " +
          moment(item.split(" - ")[1], "HH:mm:ss").format("hh:mmA")}
      </p>
    </button>
  );
};

export default SelectSlot;
