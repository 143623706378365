import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Option } from "react-dropdown";
import moment from "moment";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import { DocAvailability, Doctor } from "../../../lib/utils/types";
import {
  getDoctorAvailability,
  getDoctorListByHospitalId,
} from "../../../lib/apis/doctor";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { useUserData } from "../../../lib/contexts/UserContext";
import { createPatient, getPatientByPhoneNo } from "../../../lib/apis/patient";
import { toast } from "react-toastify";
import { addBooking, getSlots, getTokens } from "../../../lib/apis/booking";
import NotFound from "../../atoms/buttons/NotFound";
import { LeftArrow, RightArrow } from "../../../assets/icons/Icons";
import SelectToken from "../../atoms/SelectToken";
import SelectSlot from "../../atoms/SelectSlot";
import { estimateVisitTime } from "../../../lib/utils/constants";

const MasterAddPatient = ({
  closeModal,
  modalIsOpen,
  customStyles,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
}) => {
  const { userData } = useUserData();
  const { allHospData, hospData } = useHospDocData();
  const { SelectedDate } = usePatientBooking();
  const [next, setNext] = useState(false);
  const [patient, setPatient] = useState({
    full_name: "",
    contact_number: "",
    gender: "Male",
    patient_id: "",
    registered: false,
  });
  const [formState, setFormState] = useState({
    comments: "",
    type: "booking",
    people: 1,
    bookingDate: SelectedDate,
    index: moment(SelectedDate).day() + 1,
    docSession: {
      value: "",
      label: "",
    },
    currentIndex: 0,
    allSlots: [""],
    slicedSlots: [""],
    allTokens: [0],
    slicedTokens: [0],
  });

  const [bookingSlot, setBookingSlot] = useState<string>();
  const [token, setToken] = useState<number>();
  const [allSessions, setAllSessions] = useState<Option[] | undefined>();
  const [doctorsData, setDoctorsData] = useState<Doctor[]>();
  const [hospID, setHospID] = useState<string>();
  const [mappingID, setMappingID] = useState<string>();
  const [doctor_id, setDoctor_id] = useState<string>();
  const [queue_type, setQueue_type] = useState("");

  const showNext = () => {
    // Ensure we don't go beyond the length of the data array
    console.log(formState.currentIndex + 10, formState.allSlots.length);
    if (formState.currentIndex + 10 < formState.allSlots.length) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex + 10,
      }));
    }
  };

  // Function to show the previous 10 items
  const showPrevious = () => {
    // Ensure we don't go below 0
    if (formState.currentIndex - 10 >= 0) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex - 10,
      }));
    }
  };

  const showSlotsTokens = useCallback(async () => {
    console.log("Session change");
    if (!mappingID || !formState.docSession) {
      console.log("No mapping id/session");
      setFormState((prevState) => ({
        ...prevState,
        allSlots: [""],
        slicedSlots: [""],
        allTokens: [0],
        slicedTokens: [0],
      }));
    } else {
      const [start_time, end_time] = String(formState.docSession?.label)?.split(
        " - "
      );
      const data = {
        mapping_id: mappingID,
        booked_date: formState.bookingDate,
        number_of_people: formState.people,
        availability_id: formState.docSession.value,
        start_time: moment(start_time, "hh:mmA").format("HH:mm:ss"),
        end_time: moment(end_time, "hh:mmA").format("HH:mm:ss"),
      };
      const res = await getSlots(data);
      // console.log(res);
      if (res?.status === 200) {
        const api_data: {
          start: string;
          end: string;
        }[] = res.data.result;
        const allSlots =
          formState.bookingDate === moment().format("YYYY-MM-DD")
            ? api_data
                .filter((slot) => moment(slot.end, "HH:mm").isAfter(moment()))
                .map((slot) => `${slot.start} - ${slot.end}`)
            : api_data.map((slot) => `${slot.start} - ${slot.end}`);
        const slicedData = allSlots.slice(
          formState.currentIndex,
          formState.currentIndex + 10
        );
        if (allSlots) {
          setFormState((prevState) => ({
            ...prevState,
            allSlots: allSlots.length === 0 ? [""] : allSlots,
            slicedSlots: allSlots.length === 0 ? [""] : slicedData,
          }));
          setBookingSlot(allSlots.slice(0, formState.people)[0]);
          // console.log(allSlots.slice(0, formState.people));
        }
      }
      // console.log(data);
      const token_data = await getTokens(data);
      // console.log(token_data?.data);

      if (token_data?.status === 200 && token_data.data.result.length !== 0) {
        const api_data: Array<number> = token_data.data.result;
        const allTokens =
          formState.bookingDate === moment().format("YYYY-MM-DD") &&
          moment().isAfter(
            moment(
              String(formState.docSession?.label).split(" - ")[1],
              "hh:mmA"
            ).add(30, "minutes")
          )
            ? [0]
            : api_data;
        // moment().isAfter(moment(String(session?.label).split(" - ")[1], "hh:mmA"))

        // console.log("allTokens", allTokens);

        const slicedData = allTokens.slice(
          formState.currentIndex,
          formState.currentIndex + 10
        );

        // console.log("slicedData", slicedData);

        if (allTokens.length !== 0) {
          setFormState((prevState) => ({
            ...prevState,
            allTokens: allTokens,
            slicedTokens: slicedData,
          }));
          setToken(slicedData.slice(0, formState.people)[0]);
          // console.log(slicedData.slice(0, formState.people)[0]);
        }
      }
    }
  }, [
    mappingID,
    formState.docSession,
    formState.bookingDate,
    formState.people,
    formState.currentIndex,
  ]);

  const fetchDocAvailability = useCallback(async () => {
    if (!mappingID) return;

    const res = await getDoctorAvailability(mappingID);
    if (res?.status === 200) {
      const api_data: DocAvailability[] = res.data.result.doctor_availability;
      const data = api_data
        .filter((i) => i.day_of_week === formState.index)
        .map((item) => {
          return {
            value: String(item.availability_id),
            label: `${item.start_time} - ${item.end_time}`,
            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
            },
            start_time: item.start_time,
          };
        })
        .sort((a, b) => {
          // Sort by ascending order of time
          return moment(a.start_time, "HH:mm:ss").diff(
            moment(b.start_time, "HH:mm:ss")
          );
        });

      setAllSessions(data);
      setFormState((prevState) => ({
        ...prevState,
        docSession: data && data[0],
      }));
      console.log(data[0]?.data?.queue_type);
      setQueue_type(String(data[0]?.data?.queue_type));
      console.log(data);
    } else {
      setFormState((prevState) => ({
        ...prevState,
        allSlots: [""],
        allTokens: [0],
      }));
    }
  }, [doctorsData, mappingID, formState.index]);

  const getDoctors = useCallback(async () => {
    if (!hospID) return;

    const doc_data = await getDoctorListByHospitalId(hospID);
    if (doc_data?.status === 200) {
      setDoctorsData(doc_data.data.result);
      setMappingID(doc_data.data.result[0].mapping_id);
    } else {
      setDoctorsData(undefined);
      setFormState((prevState) => ({
        ...prevState,
        allSlots: [""],
      }));
      setAllSessions([]);
    }
  }, [allHospData, hospID]);

  const initializeData = useCallback(async () => {
    if (!userData) return;

    setHospID(allHospData && allHospData[0]?.hospital_id);
    const doc_data = await getDoctorListByHospitalId(
      allHospData && allHospData[0]?.hospital_id
    );
    if (doc_data?.status === 200) {
      setDoctorsData(doc_data.data.result);
      setMappingID(doc_data.data.result[0].mapping_id);
      setDoctor_id(doc_data.data.result[0].doctor_id);
    } else {
      setDoctorsData(undefined);
      setFormState((prevState) => ({
        ...prevState,
        allSlots: [""],
      }));
      setAllSessions([]);
    }
  }, [userData, allHospData, hospData]);

  useEffect(() => {
    fetchDocAvailability();
  }, [formState.bookingDate, fetchDocAvailability]);

  useEffect(() => {
    showSlotsTokens();
  }, [formState.docSession, showSlotsTokens]);

  useEffect(() => {
    getDoctors();
  }, [hospID, getDoctors]);

  useEffect(() => {
    if (!modalIsOpen) return;
    initializeData();
  }, [modalIsOpen, next, initializeData]);

  const resetStates = () => {
    setPatient({
      full_name: "",
      contact_number: "",
      gender: "Male",
      patient_id: "",
      registered: false,
    });
  };

  const handleTokenBooking = async (patient_id: string) => {
    console.log("token");

    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      userData &&
      hospID &&
      token
    ) {
      const availability_id = String(formState.docSession?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(formState.docSession?.label)?.split(" - ")[0],
        booked_slot_end: String(formState.docSession?.label)?.split(" - ")[1],
        booking_session_start_time: String(formState.docSession?.label)?.split(
          " - "
        )[0],
        booking_session_end_time: String(formState.docSession?.label)?.split(
          " - "
        )[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospID,
        patient_id: patient_id,
        doctor_id: doctor_id,
        queue_type: allSessions?.filter(
          (item) => item.value === formState.docSession?.value
        )[0].data?.queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
        token_number: token,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setNext(false);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );
      }
    } else if (patient.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else if (token === null) {
      toast.error("Please select a token.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSlotBooking = async (patient_id: string) => {
    console.log("slot");

    if (
      patient_id !== "" &&
      bookingSlot?.length === formState.people &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      userData &&
      hospID
    ) {
      const availability_id = String(formState.docSession?.value);

      for (let index = 0; index < bookingSlot?.length; index++) {
        const slot = bookingSlot[index];

        const req = {
          booking_mode: formState.type,
          booked_date: SelectedDate,
          booked_slot_start: slot.split(" - ")[0] + ":00",
          booked_slot_end: slot.split(" - ")[1] + ":00",
          booking_session_start_time: String(
            formState.docSession?.label
          )?.split(" - ")[0],
          booking_session_end_time: String(formState.docSession?.label)?.split(
            " - "
          )[1],
          is_confirmed: true,
          symptoms: "test",
          comments: formState.comments,
          availability_id: availability_id,
          hospital_id: hospID,
          patient_id: patient_id,
          doctor_id: doctor_id,
          queue_type: allSessions?.filter(
            (session) => session.value === formState.docSession?.value
          )[0].data?.queue_type,
          status: formState.type === "walk-in" ? 1 : 0,
          added_by: userData.user_id,
        };

        const res = await addBooking(req);

        if (res?.status === 201) {
          resetStates();
          setNext(false);

          toast.success(
            `${
              formState.type === "booking"
                ? "Patient booking added!"
                : "In-clinic patient added!"
            }`
          );
        }
      }
    } else if (patient.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else if (bookingSlot === undefined) {
      toast.error("Please select a slot.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async (patient_id: string) => {
    console.log("session");

    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      hospID &&
      userData
    ) {
      const availability_id = String(formState.docSession?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(formState.docSession?.label)?.split(" - ")[0],
        booked_slot_end: String(formState.docSession?.label)?.split(" - ")[1],
        booking_session_start_time: String(formState.docSession?.label)?.split(
          " - "
        )[0],
        booking_session_end_time: String(formState.docSession?.label)?.split(
          " - "
        )[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospID,
        patient_id: patient_id,
        doctor_id: doctor_id,
        queue_type: allSessions?.filter(
          (session) => session.value === formState.docSession?.value
        )[0].data?.queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();
        setNext(false);

        toast.success(
          `${
            formState.type === "booking"
              ? "Patient booking added!"
              : "In-clinic patient added!"
          }`
        );
      }
    } else if (patient.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (
      !next &&
      !isNaN(Number(patient.contact_number)) &&
      String(patient.contact_number).length === 10 &&
      /^[6-9]\d{9}$/.test(patient.contact_number)
    ) {
      console.log("1");
      const res = await getPatientByPhoneNo("91" + patient.contact_number);
      if (res?.status === 200) {
        const data = res.data.result[0];
        setPatient((prevState) => ({
          ...prevState,
          full_name: data.full_name.trim(),
          contact_number: data.contact_number,
          gender: data.gender,
          patient_id: data.patient_id,
          registered: true,
        }));
      }

      setNext(true);
    } else if (
      !next &&
      (patient.contact_number === "" ||
        isNaN(Number(patient.contact_number)) ||
        patient.contact_number.length !== 10)
    ) {
      console.log("2");
      toast.error("Please enter a valid phone number.");
    } else if (
      next &&
      patient.registered === true &&
      bookingSlot?.length === formState.people &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      formState.bookingDate &&
      formState.docSession
    ) {
      console.log("3");
      closeModal();
      queue_type === "Token"
        ? handleTokenBooking(patient.patient_id)
        : queue_type === "Session"
        ? handleSessionBooking(patient.patient_id)
        : handleSlotBooking(patient.patient_id);
    } else if (
      next &&
      patient.registered === false &&
      bookingSlot?.length === formState.people &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      formState.bookingDate &&
      formState.docSession
    ) {
      console.log("4");
      closeModal();
      //Create patient then make booking
      const req = {
        full_name: patient.full_name.trim(),
        contact_number: "91" + patient.contact_number,
        gender: patient.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };
      const res = await createPatient(req);
      console.log(res);
      if (res?.status === 201) {
        setPatient((prevState) => ({
          ...prevState,
          patient_id: res.data.result.patient_id,
        }));

        queue_type === "Token"
          ? handleTokenBooking(res.data.result.patient_id)
          : queue_type === "Session"
          ? handleSessionBooking(res.data.result.patient_id)
          : handleSlotBooking(res.data.result.patient_id);
      }
    } else if (!/^[6-9]\d{9}$/.test(patient.contact_number)) {
      toast.error("Please enter a valid phone number.");
    } else console.log("Unknown error");
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Add Booking</p>
        <CloseButton
          handleClick={() => {
            closeModal();
            resetStates();
            setNext(false);
          }}
        />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            {!next ? (
              <div className="flex flex-col w-full">
                <p className="font-light text-modalText text-sm mb-1">
                  Phone Number
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter phone number"
                  value={patient.contact_number}
                  onChange={(e) =>
                    setPatient((prevState) => ({
                      ...prevState,
                      contact_number: e.target.value,
                    }))
                  }
                  minLength={10}
                  maxLength={10}
                  required
                  autoFocus
                />
              </div>
            ) : (
              <>
                {formState.bookingDate === moment().format("YYYY-MM-DD") && (
                  <div className="flex flex-col w-full mb-3">
                    <p className="font-light text-modalText text-sm mb-1">
                      Booking Type
                    </p>
                    <div className="flex flex-row space-x-3">
                      <button
                        type="button"
                        className={`${
                          formState.type === "booking"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            type: "booking",
                          }))
                        }
                      >
                        Booking
                      </button>
                      <button
                        type="button"
                        className={`${
                          formState.type === "walk-in"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            type: "walk-in",
                          }))
                        }
                      >
                        Walk-In
                      </button>
                    </div>
                  </div>
                )}

                <div>
                  <p className="font-light text-modalText text-sm mb-1">
                    Full Name
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Enter full name"
                    type="text"
                    value={patient.full_name}
                    disabled={patient.registered}
                    onChange={(e) =>
                      setPatient((prevState) => ({
                        ...prevState,
                        full_name: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-3 w-full">
                  <div className="mt-3 w-full">
                    <p className="font-light text-modalText text-sm mb-1">
                      Comments
                    </p>
                    <input
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                      placeholder="Add comments"
                      value={formState.comments}
                      onChange={(e) =>
                        setFormState((prevState) => ({
                          ...prevState,
                          comments: e.target.value,
                        }))
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="flex flex-col mt-3 w-full">
                    <p className="font-light text-modalText text-sm mb-1 ">
                      Gender
                    </p>
                    <div className="flex flex-row space-x-3">
                      <button
                        type="button"
                        disabled={patient.registered}
                        className={`${
                          patient.gender === "Male"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setPatient((prevState) => ({
                            ...prevState,
                            gender: "Male",
                          }))
                        }
                      >
                        Male
                      </button>
                      <button
                        type="button"
                        disabled={patient.registered}
                        className={`${
                          patient.gender === "Female"
                            ? "bg-editBG text-white"
                            : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                        } px-4 py-2 rounded-[20px]`}
                        onClick={() =>
                          setPatient((prevState) => ({
                            ...prevState,
                            gender: "Female",
                          }))
                        }
                      >
                        Female
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row space-x-2 w-full mt-3">
                  <div className="flex flex-col w-full">
                    <p className="font-light text-modalText text-sm mb-1">
                      Clinic or Hospital
                    </p>
                    {allHospData ? (
                      <select
                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                        onChange={async (e) => {
                          console.log(e.target.value);
                          setHospID(e.target.value);
                          const doc_data = await getDoctorListByHospitalId(
                            e.target.value
                          );
                          if (doc_data?.status === 200) {
                            setDoctorsData(doc_data.data.result);
                            setMappingID(doc_data.data.result[0].mappingID);
                            setDoctor_id(doc_data.data.result[0].doctor_id);
                          } else {
                            setDoctorsData(undefined);
                            setFormState((prevState) => ({
                              ...prevState,
                              allSlots: [""],
                            }));
                          }
                        }}
                      >
                        <option disabled selected hidden>
                          {allHospData && allHospData[0].hospital_name}
                        </option>
                        {allHospData?.map((hospital) => (
                          <option value={hospital.hospital_id}>
                            {hospital.hospital_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <NotFound text="No branches found" />
                    )}
                  </div>
                </div>
                <div className="flex flex-row space-x-2 w-full mt-3">
                  <div className="flex flex-col w-full">
                    <p className="font-light text-modalText text-sm mb-1">
                      Choose Doctor
                    </p>
                    {doctorsData ? (
                      <select
                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                        onChange={async (e) => {
                          setDoctor_id(e.target.value.split("_")[0]);
                          setMappingID(e.target.value.split("_")[1]);
                          console.log(e.target.value);
                        }}
                      >
                        <option disabled selected hidden>
                          {doctorsData &&
                            doctorsData[0] &&
                            doctorsData[0].full_name}
                        </option>
                        {doctorsData?.map((doctor) => (
                          <option
                            value={doctor.doctor_id + "_" + doctor.mapping_id}
                          >
                            {doctor.full_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <NotFound text="No doctors found" />
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="font-light text-modalText text-sm mb-1 ">
                    Booking For
                  </p>
                  <div className="flex flex-row">
                    <input
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-2"
                      placeholder="Select Date"
                      type="date"
                      value={formState.bookingDate}
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().add(2, "weeks").format("YYYY-MM-DD")}
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        const dayOfWeek = moment(selectedDate).day() + 1;

                        setFormState((prevState) => ({
                          ...prevState,
                          bookingDate: e.target.value,
                          index: dayOfWeek,
                        }));
                      }}
                    />
                    {allSessions && allSessions.length > 0 ? (
                      <select
                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                        onChange={(e) => {
                          const label =
                            e.target.options[e.target.selectedIndex].text;
                          const value = e.target.value;
                          setFormState((prevState) => ({
                            ...prevState,
                            docSession: { label, value },
                          }));
                          console.log(
                            allSessions?.filter(
                              (session) => session.value === value
                            )[0].data?.queue_type
                          );
                          setQueue_type(
                            String(
                              allSessions?.filter(
                                (session) => session.value === value
                              )[0].data?.queue_type
                            )
                          );
                        }}
                        defaultValue={String(formState.docSession?.label)}
                      >
                        <option disabled hidden>
                          {moment(
                            String(formState.docSession?.label).split(" - ")[0],
                            "HH:mm:ss"
                          ).format("hh:mmA") +
                            " - " +
                            moment(
                              String(formState.docSession?.label).split(
                                " - "
                              )[1],
                              "HH:mm:ss"
                            ).format("hh:mmA")}
                        </option>
                        {allSessions.map((session) => (
                          <option key={session.value} value={session.value}>
                            {moment(
                              String(session?.label).split(" - ")[0],
                              "HH:mm:ss"
                            ).format("hh:mmA") +
                              " - " +
                              moment(
                                String(session.label).split(" - ")[1],
                                "HH:mm:ss"
                              ).format("hh:mmA")}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <NotFound text="No sessions" />
                    )}
                  </div>
                </div>
                {queue_type !== "Session" &&
                  allSessions &&
                  allSessions.length > 0 && (
                    <div className="flex flex-col w-full my-3">
                      <p className="font-light text-modalText text-sm mb-1">
                        {estimateVisitTime.includes(String(hospID))
                          ? "Estimate Visit Time"
                          : "Choose slot timings"}
                      </p>
                      {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                      <div>
                        {formState.currentIndex > 0 && (
                          <button
                            type="button"
                            className="bg-white p-2 rounded-lg hover:opacity-70"
                            onClick={showPrevious}
                          >
                            <LeftArrow />
                          </button>
                        )}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-3">
                          {queue_type === "Token" ? (
                            formState.slicedTokens.length !== 0 &&
                            formState.slicedTokens[0] !== 0 ? (
                              formState.slicedTokens.map((item, index) => (
                                <React.Fragment key={index}>
                                  <SelectToken
                                    selected={token === item}
                                    setToken={setToken}
                                    item={item}
                                  />
                                </React.Fragment>
                              ))
                            ) : (
                              <NotFound text="No Tokens Available" />
                            )
                          ) : formState.slicedSlots.length !== 1 ||
                            formState.slicedSlots[0] !== "" ? (
                            formState.slicedSlots.map((item, index) => (
                              <React.Fragment key={index}>
                                <SelectSlot
                                  item={item}
                                  setSlot={setBookingSlot}
                                  selected={
                                    bookingSlot?.includes(item) || false
                                  }
                                  people={formState.people}
                                />
                              </React.Fragment>
                            ))
                          ) : (
                            <NotFound text="No Slots Available" />
                          )}
                        </div>
                        {(formState.currentIndex <= formState.allSlots.length &&
                          formState.slicedSlots.length !== 1) ||
                          (formState.slicedSlots[0] !== "" && (
                            <button
                              type="button"
                              className="bg-white p-2 rounded-lg hover:opacity-70"
                              onClick={showNext}
                            >
                              <RightArrow />
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          {next && (
            <WhiteButton
              className="w-full h-14"
              name="Cancel"
              handleClick={() => {
                setNext(false);
                resetStates();
              }}
            />
          )}
          {allSessions?.length !== 0 && next && (
            <DarkBlueButton
              className="w-full h-14"
              type="submit"
              name="Save"
              handleClick={handleSubmit}
            />
          )}

          <div className="flex justify-end mr-4">
            {!next && (
              <DarkBlueButton
                className="w-full h-14"
                type="submit"
                name="Next"
                handleClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default MasterAddPatient;
