import React from "react";
import moment from "moment";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";

const AdjacentDates = ({
  tareek,
  day,
  date,
  onPress,
  isSelected,
}: {
  tareek: number;
  day: string;
  date: string;
  onPress: () => void;
  isSelected?: boolean;
}) => {
  const { SelectedDate } = usePatientBooking();
  if (isSelected) {
    return (
      <button
        className="md:mx-4 p-1 w-14 rounded-lg bg-gradient-to-br from-sbTextHover to-selectedDate hover:from-selectedDate hover:to-sbTextHover"
        onClick={onPress}
      >
        {SelectedDate === moment().format("YYYY-MM-DD") && (
          <p className="text-[8px] text-white mb-1">Today</p>
        )}
        <div className="flex justify-center items-center bg-selectedTareek rounded-md h-8">
          <p className="font-semibold text-white">{tareek}</p>
        </div>
        <div className="flex flex-col justify-center items-center h-8">
          <p className="text-xs text-white uppercase">{day}</p>
        </div>
      </button>
    );
  }
  return (
    <button
      className="md:mx-4 p-1 w-14 rounded-lg bg-dates hover:bg-blue-300"
      onClick={onPress}
    >
      {date === moment().format("YYYY-MM-DD") && (
        <p className="text-[8px] text-dark mb-1">Today</p>
      )}
      <div className="flex justify-center items-center bg-white rounded-md h-8">
        <p className="font-semibold">{tareek}</p>
      </div>
      <div className="flex justify-center items-center h-8">
        <p className="text-xs uppercase">{day}</p>
      </div>
    </button>
  );
};

export default AdjacentDates;
