import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import UserContext from "./lib/contexts/UserContext";
import HospitalDoctorContext from "./lib/contexts/HospitalDoctorContext";
import PatientBookingContext from "./lib/contexts/PatientBookingContext";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "react-dropdown/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-toggle/style.css";

function App() {
  return (
    <BrowserRouter>
      <UserContext>
        <HospitalDoctorContext>
          <PatientBookingContext>
            <ToastContainer />
            <AppRoutes />
          </PatientBookingContext>
        </HospitalDoctorContext>
      </UserContext>
    </BrowserRouter>
  );
}

export default App;
