import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AvailabilitySlot, EditDaySessions } from "../../services/types";
import moment from "moment";
import { modalStyles } from "../../assets/styles/modalStyles";
import EachDaySession from "../molecules/EachDaySession";
import { toast } from "react-toastify";
import { useUserData } from "../../lib/contexts/UserContext";
import {
  Calendar,
  DateRange,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import { useParams } from "react-router-dom";
import { getAvailabilityForCalendar } from "../../lib/apis/booking";
import Toggle from "react-toggle";
import HolidayModal from "./modals/HolidayModal";
import {
  Adjustment,
  SessionEvent,
  UpdateVideoAvailability,
  VideoAvailability,
} from "../../lib/utils/types";
import RangeHolidayModal from "./modals/RangeHolidayModal";
import EditDayToggle from "../molecules/EditDayToggle";
import ReactDropdown from "react-dropdown";
import {
  convertToMinutes,
  generateTimeOptions,
  updateAvailability,
} from "../../lib/utils/funcs";
import {
  getDoctorVideoAvailability,
  updateAvailabilityBulk,
  updateVideoAvailabilityBulk,
} from "../../lib/apis/doctor";
import WhiteButton from "../atoms/buttons/WhiteButton";
import DarkBlueButton from "../atoms/buttons/DarkBlueButton";

const SessionInfo = ({
  docAvail,
  setDocAvail,
  doctor_id,
}: {
  docAvail: AvailabilitySlot[] | undefined;
  setDocAvail: React.Dispatch<
    React.SetStateAction<AvailabilitySlot[] | undefined>
  >;
  doctor_id: string | undefined;
}) => {
  const { userData } = useUserData();
  const [visible, setVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [holiday, setHoliday] = useState(false);
  const [rangeholiday, setRangeHoliday] = useState(false);
  const [session, setSession] = useState<SessionEvent>();
  const { mapping_id } = useParams();
  const [video, setVideo] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState(2);
  const [weekVideoSchedule, setWeekVideoSchedule] = useState<
    VideoAvailability[]
  >([]);

  useEffect(() => {
    async function getVideoData() {
      const res = await getDoctorVideoAvailability(String(mapping_id));
      console.log(res.data);
      
      if (res?.status === 200) {
        setWeekVideoSchedule(res.data.result);
      }
    }
    getVideoData();
  }, [videoVisible]);

  const handleVideoChange = (
    availability_id: string,
    field: string, // String to allow nested field handling
    value: any // The new value for that field
  ) => {
    setWeekVideoSchedule((prevSchedule) => {
      // Check if the availability_id already exists in the schedule
      const availabilityIndex = prevSchedule.findIndex(
        (item) => item.availability_id === availability_id
      );

      if (availabilityIndex !== -1) {
        // If it exists, update the specific field
        const updatedSchedule = [...prevSchedule];
        const updatedAvailability = { ...updatedSchedule[availabilityIndex] };

        if (field === "max_consultation_duration.minutes") {
          // Handle nested field update for max_consultation_duration.minutes
          updatedAvailability.max_consultation_duration = {
            ...updatedAvailability.max_consultation_duration,
            minutes: value,
          };
          const start = convertToMinutes(
            updatedSchedule[availabilityIndex].start_time
          );
          const end = convertToMinutes(
            updatedSchedule[availabilityIndex].end_time
          );
          (updatedAvailability as any)["max_consultations_per_session"] =
            Number.isNaN(Number(((end - start) / Number(value)).toFixed(0))) ||
            !Number.isFinite(Number(((end - start) / Number(value)).toFixed(0)))
              ? 0
              : Number(((end - start) / Number(value)).toFixed(0));
        } else if (field === "start_time" || field === "end_time") {
          (updatedAvailability as any)[field] = moment(value, "hh:mm A").format(
            "HH:mm:ss"
          );
        } else if(field === "selected"){
          (updatedAvailability as any)[field] = value;
        } else {
          // Update top-level fields
          (updatedAvailability as any)[field] = value;
        }

        updatedSchedule[availabilityIndex] = updatedAvailability;
        return updatedSchedule;
      } else {
        // If availability_id doesn't exist, create a new entry
        const newEntry: VideoAvailability = {
          availability_id,
          start_time: "", // Default start time
          end_time: "", // Default end time
          max_consultation_duration: {
            minutes: 0,
          },
          max_consultations_per_session: 0,
          selected:true
        };

        // Update the specific field for new entries
        if (field === "max_consultation_duration.minutes") {
          newEntry.max_consultation_duration.minutes = value;
        } else {
          (newEntry as any)[field] = value;
        }
        console.log([...prevSchedule, newEntry]);

        return [...prevSchedule, newEntry];
      }
    });

    console.log(weekVideoSchedule);
  };

  const week = [
    "",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const defaultDaySessions: EditDaySessions = {
    sessions: {
      morning: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      afternoon: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      evening: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
    },
    selected: false,
  };

  const reset = () => {
    setWeekSchedule({
      1: {
        ...defaultDaySessions,
        sessions: {
          morning: {
            ...defaultDaySessions.sessions.morning,
            day_of_week: 1,
            selected: false,
          },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 1,
            selected: false,
          },
          evening: {
            ...defaultDaySessions.sessions.evening,
            day_of_week: 1,
            selected: false,
          },
        },
        selected: false,
      },
      2: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 2,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
        },
        selected: false,
      },
      3: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 3,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
        },
        selected: false,
      },
      4: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 4,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
        },
        selected: false,
      },
      5: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 5,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
        },
        selected: false,
      },
      6: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 6,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
        },
        selected: false,
      },
      7: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 7,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
        },
        selected: false,
      },
    });
    setWeekVideoSchedule([]);
  };

  const [weekSchedule, setWeekSchedule] = useState<{
    [key: number]: EditDaySessions;
  }>({
    1: {
      ...defaultDaySessions,
      sessions: {
        morning: {
          ...defaultDaySessions.sessions.morning,
          day_of_week: 1,
          selected: false,
        },
        afternoon: {
          ...defaultDaySessions.sessions.afternoon,
          day_of_week: 1,
          selected: false,
        },
        evening: {
          ...defaultDaySessions.sessions.evening,
          day_of_week: 1,
          selected: false,
        },
      },
      selected: false,
    },
    2: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 2 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
      },
      selected: false,
    },
    3: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 3 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
      },
      selected: false,
    },
    4: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 4 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
      },
      selected: false,
    },
    5: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 5 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
      },
      selected: false,
    },
    6: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 6 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
      },
      selected: false,
    },
    7: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 7 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
      },
      selected: false,
    },
  });

  const setData = (data: AvailabilitySlot[] | undefined) => {
    const temp: {
      [key: number]: EditDaySessions;
    } = {
      1: {
        ...defaultDaySessions,
        sessions: {
          morning: {
            ...defaultDaySessions.sessions.morning,
            day_of_week: 1,
            selected: false,
          },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 1,
            selected: false,
          },
          evening: {
            ...defaultDaySessions.sessions.evening,
            day_of_week: 1,
            selected: false,
          },
        },
        selected: false,
      },
      2: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 2,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
        },
        selected: false,
      },
      3: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 3,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
        },
        selected: false,
      },
      4: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 4,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
        },
        selected: false,
      },
      5: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 5,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
        },
        selected: false,
      },
      6: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 6,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
        },
        selected: false,
      },
      7: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 7,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
        },
        selected: false,
      },
    };
    if (data !== undefined)
      for (const availability of data) {
        const {
          availability_id,
          day_of_week,
          start_time,
          end_time,
          max_patients_per_slot,
          wait_time,
          max_wa_tokens,
          queue_type,
          token_type,
        } = availability;

        if (temp[day_of_week]) {
          temp[day_of_week].selected = true;
          if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("05:59:00", "HH:mm:ss"),
              moment("11:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.morning.availability_id =
              availability_id;
            temp[day_of_week].sessions.morning.start_time = start_time;
            temp[day_of_week].sessions.morning.end_time = end_time;
            temp[day_of_week].sessions.morning.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.morning.wait_time = wait_time;
            temp[day_of_week].sessions.morning.selected = true;
            temp[day_of_week].sessions.morning.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.morning.queue_type = queue_type;
            temp[day_of_week].sessions.morning.token_type = token_type;
          } else if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("11:59:00", "HH:mm:ss"),
              moment("16:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.afternoon.availability_id =
              availability_id;
            temp[day_of_week].sessions.afternoon.start_time = start_time;
            temp[day_of_week].sessions.afternoon.end_time = end_time;
            temp[day_of_week].sessions.afternoon.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.afternoon.wait_time = wait_time;
            temp[day_of_week].sessions.afternoon.selected = true;
            temp[day_of_week].sessions.afternoon.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.afternoon.queue_type = queue_type;
            temp[day_of_week].sessions.afternoon.token_type = token_type;
          } else {
            temp[day_of_week].sessions.evening.availability_id =
              availability_id;
            temp[day_of_week].sessions.evening.start_time = start_time;
            temp[day_of_week].sessions.evening.end_time = end_time;
            temp[day_of_week].sessions.evening.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.evening.wait_time = wait_time;
            temp[day_of_week].sessions.evening.selected = true;
            temp[day_of_week].sessions.evening.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.evening.queue_type = queue_type;
            temp[day_of_week].sessions.evening.token_type = token_type;
          }
        }
      }

    setWeekSchedule({ ...temp });
  };

  useEffect(() => {
    // console.log("changed", docAvail);
    setData(docAvail);
  }, [docAvail, visible, videoVisible]);

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<
    {
      id: string;
      title: string;
      start: Date;
      end: Date;
      resourceId: string;
    }[]
  >([]);
  const [calendar, setCalendar] = useState(true);
  const [start, setStart] = useState<Date>(moment().startOf("month").toDate());
  const [end, setEnd] = useState<Date>(moment().endOf("month").toDate());

  const fetchNewAvailability = useCallback(
    async (start: Date, end: Date) => {
      if (mapping_id) {
        const res = await getAvailabilityForCalendar(
          mapping_id,
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD")
        );
        if (res?.status === 200) {
          // console.log(res.data.result);
          return res.data.result;
        }
      }
    },
    [mapping_id]
  );

  const eventStyleGetter = (event: SessionEvent) => {
    let backgroundColor = "#335fe4"; // default color for available slots
    if (event.unavailable) {
      backgroundColor = "red"; // color for unavailable slots
    }
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
      },
    };
  };
  const generateEventsForMonth = useCallback(
    async (availabilityData: AvailabilitySlot[], start: Date, end: Date) => {
      const events: SessionEvent[] = [];
      const startOfMonth = moment(start).startOf("month");
      const endOfMonth = moment(end).endOf("month");

      const adjustments: Adjustment[] = await fetchNewAvailability(start, end);
      // console.log(adjustments);

      availabilityData.forEach((session, index) => {
        let currentDate = startOfMonth.clone().day(session.day_of_week - 1);
        if (currentDate.date() > 7) currentDate.add(7, "d");

        while (currentDate.isBefore(endOfMonth)) {
          const startTime = currentDate.clone().set({
            hour: parseInt(session.start_time.split(":")[0], 10),
            minute: parseInt(session.start_time.split(":")[1], 10),
          });
          const endTime = currentDate.clone().set({
            hour: parseInt(session.end_time.split(":")[0], 10),
            minute: parseInt(session.end_time.split(":")[1], 10),
          });

          const adjustment = adjustments?.find(
            (adjustment) =>
              adjustment.availability_id === session.availability_id &&
              currentDate.isSame(moment(adjustment.adjustment_date), "day")
          );

          if (adjustment) {
            if (adjustment.type === "Unavailability") {
              events.push({
                id: `${currentDate.format("YYYY-MM-DD")}`,
                title: `${startTime.format("hh:mmA")} - ${endTime.format(
                  "hh:mmA"
                )}`,
                start: startTime.toDate(),
                end: endTime.toDate(),
                resourceId: session.availability_id,
                adjustment_id: adjustment.adjustment_id,
                unavailable: true,
              });
              currentDate.add(7, "d");
              continue;
            } else if (adjustment.type === "Extension") {
              startTime.set({
                hour: parseInt(adjustment.session_start_time.split(":")[0], 10),
                minute: parseInt(
                  adjustment.session_start_time.split(":")[1],
                  10
                ),
              });
              endTime.set({
                hour: parseInt(adjustment.extended_end_time.split(":")[0], 10),
                minute: parseInt(
                  adjustment.extended_end_time.split(":")[1],
                  10
                ),
              });
            }
          }

          events.push({
            id: `${currentDate.format("YYYY-MM-DD")}`,
            title: `${startTime.format("hh:mmA")} - ${endTime.format(
              "hh:mmA"
            )}`,
            start: startTime.toDate(),
            end: endTime.toDate(),
            resourceId: session.availability_id,
            unavailable: false,
          });

          currentDate.add(7, "d");
        }
      });

      // console.log(events);
      return events;
    },
    [holiday]
  );

  const fetchAndSetEvents = async (start: Date, end: Date) => {
    if (docAvail) {
      const newEvents = await generateEventsForMonth(docAvail, start, end);
      setEvents(newEvents);
    }
  };
  // Usage within useEffect
  useEffect(() => {
    const fetchAndSetEventsOnDocAvail = async () => {
      const startOfMonth = moment().startOf("month").toDate();
      const endOfMonth = moment().endOf("month").toDate();
      await fetchAndSetEvents(startOfMonth, endOfMonth);
    };

    fetchAndSetEventsOnDocAvail();
  }, [docAvail, rangeholiday]);

  const handleRangeChange = async (range: Date[] | DateRange) => {
    // console.log("Range change:", range);
    let start: Date;
    let end: Date;

    if (Array.isArray(range)) {
      // Assuming the range is an array of dates (e.g., when calendar view is "week" or "day")
      start = range[0];
      end = range[range.length - 1];
    } else {
      // Assuming the range is an object with start and end dates (e.g., when calendar view is "month")
      start = range.start;
      end = range.end;
      setStart(start);
      setEnd(end);
    }

    if (docAvail) {
      const newEvents = await generateEventsForMonth(docAvail, start, end);
      setEvents(newEvents);
    }
  };

  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const handleSelectSlot = ({ start, end }: DateRange) => {
    // console.log("Selected dates:", start, end);
    setSelectedDates({ start, end });
    setRangeHoliday(true);
  };

  const daySchedule = weekSchedule[Number(dayOfWeek)];

  const shift_start: { [key: string]: string[] } = {
    morning: generateTimeOptions("morningEnd"),
    afternoon: generateTimeOptions(
      "afternoon",
      weekSchedule[dayOfWeek].sessions.morning.end_time
    ),
    evening: generateTimeOptions(
      "evening",
      weekSchedule[dayOfWeek].sessions.afternoon.end_time
    ),
  };

  const shift_end: {
    [key: string]: string[];
  } = {
    morning: generateTimeOptions("morningEnd"),
    afternoon: generateTimeOptions("afternoonEnd"),
    evening: generateTimeOptions("eveningEnd"),
  };

  const handleChange = (
    value: string,
    dayOfWeek: number,
    sessionType: "morning" | "afternoon" | "evening" | string,
    type:
      | "start_time"
      | "end_time"
      | "max_patients_per_slot"
      | "wait_time"
      | "queue_type"
      | "max_wa_tokens"
      | "token_type"
  ) => {
    // Create a copy of the weekSchedule
    const updatedWeekSchedule = { ...weekSchedule };
    if (type === "start_time" || type === "end_time") {
      // Handling time updates
      updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = moment(
        value,
        "hh:mm A"
      ).format("HH:mm:ss");
    } else if (type === "queue_type" || type === "max_wa_tokens") {
      // Handling queue type updates or WhatsApp booking limits
      if (
        updatedWeekSchedule[dayOfWeek].sessions[sessionType]["queue_type"] ===
        "Token"
      ) {
        updatedWeekSchedule[dayOfWeek].sessions[sessionType]["max_wa_tokens"] =
          null;
      }

      if (type === "max_wa_tokens") {
        // Ensure that value is a valid number or handle conversion
        console.log("value", value);
        var intValue;
        if (value === "") {
          intValue = 0;
        } else {
          intValue = parseInt(value, 10);
        }

        if (!isNaN(intValue) && intValue >= 0) {
          updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = intValue;
        } else {
          console.warn("Invalid input for max WhatsApp patients.");
          return; // Exit the function early if the input is invalid
        }
      } else {
        updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = value;
      }
    } else {
      // Handling data updates (max_patients_per_slot or wait_time)
      if (
        updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"] &&
        updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
      ) {
        const start = convertToMinutes(
          updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"]
        );
        const end = convertToMinutes(
          updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
        );
        if (type === "max_patients_per_slot" && value) {
          updatedWeekSchedule[dayOfWeek].sessions[sessionType]["wait_time"] = {
            minutes: Number(((end - start) / Number(value)).toFixed(0)),
          };
        }
        if (type === "wait_time" && value) {
          updatedWeekSchedule[dayOfWeek].sessions[sessionType][
            "max_patients_per_slot"
          ] = Number(((end - start) / Number(value)).toFixed(0));
          if (
            updatedWeekSchedule[dayOfWeek].sessions[sessionType][
              "queue_type"
            ] === "Token"
          ) {
            updatedWeekSchedule[dayOfWeek].sessions[sessionType][
              "max_wa_tokens"
            ] =
              updatedWeekSchedule[dayOfWeek].sessions[sessionType][
                "max_patients_per_slot"
              ];
          }
        }
        (updatedWeekSchedule[dayOfWeek].sessions[sessionType] as any)[type] =
          value;
      }
    }
    // Update the state with the new weekSchedule
    setWeekSchedule(updatedWeekSchedule);
  };

  // const handleVideoChange = (
  //   value: string,
  //   dayOfWeek: number,
  //   sessionType: "morning" | "afternoon" | "evening" | string,
  //   type:
  //     | "start_time"
  //     | "end_time"
  //     | "max_patients_per_slot"
  //     | "wait_time"
  //     | "queue_type"
  //     | "max_wa_tokens"
  //     | "token_type"
  // ) => {
  //   // Create a copy of the weekSchedule
  //   const updatedWeekSchedule = { ...weekVideoSchedule };
  //   if (type === "start_time" || type === "end_time") {
  //     // Handling time updates
  //     updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = moment(
  //       value,
  //       "hh:mm A"
  //     ).format("HH:mm:ss");
  //   } else if (type === "queue_type" || type === "max_wa_tokens") {
  //     // Handling queue type updates or WhatsApp booking limits
  //     if (
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["queue_type"] ===
  //       "Token"
  //     ) {
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["max_wa_tokens"] =
  //         null;
  //     }

  //     if (type === "max_wa_tokens") {
  //       // Ensure that value is a valid number or handle conversion
  //       console.log("value", value);
  //       const intValue = parseInt(value, 10);
  //       if (!isNaN(intValue) && intValue >= 0) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = intValue;
  //       } else {
  //         console.warn("Invalid input for max WhatsApp patients.");
  //         return; // Exit the function early if the input is invalid
  //       }
  //     } else {
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType][type] = value;
  //     }
  //   } else {
  //     // Handling data updates (max_patients_per_slot or wait_time)
  //     if (
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"] &&
  //       updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
  //     ) {
  //       const start = convertToMinutes(
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["start_time"]
  //       );
  //       const end = convertToMinutes(
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["end_time"]
  //       );
  //       if (type === "max_patients_per_slot" && value) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType]["wait_time"] = {
  //           minutes: Number(((end - start) / Number(value)).toFixed(0)),
  //         };
  //       }
  //       if (type === "wait_time" && value) {
  //         updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //           "max_patients_per_slot"
  //         ] = Number(((end - start) / Number(value)).toFixed(0));
  //         if (
  //           updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //             "queue_type"
  //           ] === "Token"
  //         ) {
  //           updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //             "max_wa_tokens"
  //           ] =
  //             updatedWeekSchedule[dayOfWeek].sessions[sessionType][
  //               "max_patients_per_slot"
  //             ];
  //         }
  //       }
  //       (updatedWeekSchedule[dayOfWeek].sessions[sessionType] as any)[type] =
  //         value;
  //     }
  //   }
  //   // Update the state with the new weekSchedule
  //   setWeekVideoSchedule(updatedWeekSchedule);
  // };

  const queueOptions = [
    { value: "Slot_Token", label: "Slot Token" },
    { value: "Slot", label: "Slot" },
    { value: "Token", label: "Token" },
    { value: "Session", label: "Session" },
  ];
  const tokenOptions = [
    { value: "Even", label: "Even" },
    { value: "Odd", label: "Odd" },
    { value: "Both", label: "Both" },
    { value: "Multiple3", label: "Multiple of 3" },
  ];

  const update = updateAvailability(weekSchedule, String(mapping_id));

  const renderTimeDropdown = (
    dayOfWeek: number,
    shift: string,
    timeType: "start_time" | "end_time",
    timeOptions: string[],
    type: "video" | "physical",
    availability_id: string
  ) => {
    console.log(availability_id);

    if (type === "video") {
      console.log(weekVideoSchedule);

      var data = weekVideoSchedule.filter(
        (e) => e.availability_id === availability_id
      );
      console.log("data=>", data);

      return (
        <ReactDropdown
          key={`${dayOfWeek}-${shift}-${timeType}`}
          options={timeOptions.filter((val) => {
            const valInMinutes = convertToMinutes(val);

            if (
              timeType === "start_time" &&
              weekSchedule[dayOfWeek].sessions[shift].end_time
            ) {
              const endTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].end_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              const startTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].start_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              return (
                valInMinutes < endTimeInMinutes &&
                valInMinutes >= startTimeInMinutes
              );
            }

            if (
              timeType === "end_time" &&
              weekSchedule[dayOfWeek].sessions[shift].start_time
            ) {
              const startTimeInMinutes = convertToMinutes(
                moment(
                  data.length > 0
                    ? data[0].start_time
                    : weekSchedule[dayOfWeek].sessions[shift].start_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              const endTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].end_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              return (
                valInMinutes > startTimeInMinutes &&
                valInMinutes <= endTimeInMinutes
              );
            }

            return true;
          })}
          onChange={(selectedOption) =>
            handleVideoChange(availability_id, timeType, selectedOption.value)
          }
          value={
            data.length > 0 && data[0].end_time !== ""
              ? moment(
                  timeType === "start_time"
                    ? data[0].start_time
                    : data[0].end_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              : "Select Time"
          }
          placeholder="Select time"
          className="w-full text-sm"
          controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
        />
      );
    } else {
      return (
        <ReactDropdown
          key={`${dayOfWeek}-${shift}-${timeType}`}
          options={timeOptions.filter((val) => {
            const valInMinutes = convertToMinutes(val);

            if (
              timeType === "start_time" &&
              weekSchedule[dayOfWeek].sessions[shift].end_time
            ) {
              const endTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].end_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              const startTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].start_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              return valInMinutes < endTimeInMinutes;
            }

            if (
              timeType === "end_time" &&
              weekSchedule[dayOfWeek].sessions[shift].start_time
            ) {
              const startTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].start_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              const endTimeInMinutes = convertToMinutes(
                moment(
                  weekSchedule[dayOfWeek].sessions[shift].end_time,
                  "HH:mm:ss"
                ).format("hh:mm A")
              );
              return valInMinutes > startTimeInMinutes;
            }

            return true;
          })}
          onChange={(selectedOption) =>
            handleChange(selectedOption.value, dayOfWeek, shift, timeType)
          }
          value={
            weekSchedule[dayOfWeek].sessions[shift][timeType]
              ? moment(
                  weekSchedule[dayOfWeek].sessions[shift][timeType],
                  "HH:mm:ss"
                ).format("hh:mm A")
              : "Select Time"
          }
          placeholder="Select time"
          className="w-full text-sm"
          controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
        />
      );
    }
  };

  return (
    <>
      <div className="bg-white flex flex-col rounded-lg mb-2">
        <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4 h-20">
          <p className="text-dark font-medium">Session Details</p>
          {/* <div>
            <p className="text-xs text-docDetail">Calendar View</p>
            <Toggle
              icons={false}
              checked={calendar}
              onChange={async () => setCalendar(!calendar)}
            />
          </div> */}
          {userData?.role === "MasterAdmin" &&
            !calendar &&
            !visible &&
            !videoVisible && (
              <div className="w-80 hidden lg:flex justify-end gap-3">
                <button
                  className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
                  onClick={() => {
                    if (docAvail !== undefined) setVideoVisible(true);
                    else toast.error("Sessions data loading.");
                  }}
                >
                  Edit Video Session
                </button>
                <button
                  className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
                  onClick={() => {
                    if (docAvail !== undefined) setVisible(true);
                    else toast.error("Sessions data loading.");
                  }}
                >
                  Edit
                </button>
              </div>
            )}
          {visible && (
            <div className="hidden lg:flex flex-row items-center justify-end">
              <WhiteButton
                name="Cancel"
                className="w-full !p-2 !px-8 !rounded-[20px] border-[1px] border-[#335fe4] !text-black !opacity-100"
                handleClick={() => {
                  setVisible(false);
                  reset();
                }}
              />
              <DarkBlueButton
                name="Save"
                className="w-full !p-2 !px-10 !rounded-[20px]"
                handleClick={async () => {
                  if (update.length !== 0) {
                    console.log(update);

                    const res = await updateAvailabilityBulk(
                      String(mapping_id),
                      update
                    );
                    if (res?.status === 201) {
                      console.log(res.data.result);
                      setDocAvail(res.data.result);
                      setData(res.data.result);
                      toast.success("Sessions updated!");
                      reset();
                      setVisible(false);
                    }
                  } else {
                    toast.error("Unexpected error occured.");
                    reset();
                    setVisible(false);
                  }
                }}
              />
            </div>
          )}
          {videoVisible && (
            <div className="hidden lg:flex flex-row items-center justify-end">
              <WhiteButton
                name="Cancel"
                className="w-full !p-2 !px-8 !rounded-[20px] border-[1px] border-[#335fe4] !text-black !opacity-100"
                handleClick={() => {
                  setVideoVisible(false);
                  reset();
                }}
              />
              <DarkBlueButton
                name="Save"
                className="w-full !p-2 !px-10 !rounded-[20px] bg-[#335fe4]"
                handleClick={async () => {
                  const formattedData: UpdateVideoAvailability[] =
                    weekVideoSchedule.map((item) => ({
                      availability_id: item.availability_id,
                      start_time: moment(item.start_time, "hh:mm A").format(
                        "HH:mm:ss"
                      ), // Assuming start_time is already in the correct format
                      end_time: item.end_time, // Assuming end_time is already in the correct format
                      max_consultation_duration: `00:${item.max_consultation_duration.minutes}:00`, // Formatting the duration
                      max_consultations_per_session:
                        item.max_consultations_per_session,
                      is_active: item.selected, // Setting is_active to true
                      ...(item.video_consultation_availability_id && {
                        video_consultation_availability_id:
                          item.video_consultation_availability_id,
                      }), // Conditionally add video_consultation_availability_id if it exists
                    }));

                  if (formattedData.length !== 0) {
                    console.log(formattedData);
                    const res = await updateVideoAvailabilityBulk(
                      formattedData
                    );
                    if (res?.status === 201) {
                      console.log(res.data.result);
                      toast.success("Video Sessions updated!");
                      reset();
                      setVideoVisible(false);
                    }
                  } else {
                    toast.error("Unexpected error occured.");
                    reset();
                    setVideoVisible(false);
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 px-7 mb-6 gap-3">
          <button
            onClick={async () => setCalendar(true)}
            className={
              calendar
                ? `!rounded-lg !border-[0.5px] !border-sbBorder p-2 py-3 bg-[#C3D2FF] text-[#335FE4]`
                : `!rounded-lg !border-[0.5px] !border-sbBorder p-2 py-3 hover:bg-[#C3D2FF] hover:text-[#335FE4]`
            }
          >
            Monthly View
          </button>
          <button
            onClick={async () => setCalendar(false)}
            className={
              !calendar
                ? `!rounded-lg !border-[0.5px] !border-sbBorder p-2 py-3 bg-[#C3D2FF] text-[#335FE4]`
                : `!rounded-lg !border-[0.5px] !border-sbBorder p-2 py-3 hover:bg-[#C3D2FF] hover:text-[#335FE4]`
            }
          >
            Weekly View
          </button>
        </div>
        {userData?.role === "MasterAdmin" &&
          !calendar &&
          !visible &&
          !videoVisible && (
            <div className="flex lg:hidden justify-center gap-3">
              <button
                className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
                onClick={() => {
                  if (docAvail !== undefined) setVideoVisible(true);
                  else toast.error("Sessions data loading.");
                }}
              >
                Edit Video Session
              </button>
              <button
                className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
                onClick={() => {
                  if (docAvail !== undefined) setVisible(true);
                  else toast.error("Sessions data loading.");
                }}
              >
                Edit
              </button>
            </div>
          )}
        {(visible || videoVisible) && (
          <div className="flex flex-row lg:hidden items-center justify-between px-2">
            <WhiteButton
              name="Cancel"
              className="w-[48%] !p-2 !mr-0"
              handleClick={() => {
                setVisible(false);
                reset();
              }}
            />
            <DarkBlueButton
              name="Save"
              className="w-[48%] !p-2 !mr-0 !bg-[#335FE4] !rounded-[20px]"
              handleClick={async () => {
                if (update.length !== 0) {
                  console.log(update);

                  const res = await updateAvailabilityBulk(
                    String(mapping_id),
                    update
                  );
                  if (res?.status === 201) {
                    console.log(res.data.result);
                    setDocAvail(res.data.result);
                    setData(res.data.result);
                    toast.success("Sessions updated!");
                    reset();
                    setVisible(false);
                  }
                } else {
                  toast.error("Unexpected error occured.");
                  reset();
                  setVisible(false);
                }
              }}
            />
          </div>
        )}
        {calendar ? (
          <div style={{ height: 500 }}>
            <Calendar
              dayLayoutAlgorithm="no-overlap"
              defaultDate={new Date()}
              defaultView={Views.MONTH}
              localizer={localizer}
              events={events}
              eventPropGetter={eventStyleGetter}
              //@ts-ignore
              onRangeChange={handleRangeChange}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={(event) => {
                // console.log(event);
                setSession(event);
                setHoliday(true);
              }}
              selectable
            />
          </div>
        ) : !visible && !videoVisible ? (
          <div className="rounded-b-lg px-4 border-[1px] border-t-0 border-doctorsBorder">
            {Object.keys(weekSchedule).map((dayOfWeek) => (
              <EachDaySession
                day={week[Number(dayOfWeek)]}
                daySchedule={weekSchedule[Number(dayOfWeek)]}
                videoSchedule={weekVideoSchedule}
              />
            ))}
          </div>
        ) : visible ? (
          <div className="rounded-b-lg px-6 border-[1px] border-t-0 border-doctorsBorder p-4 lg:h-[78vh]">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-xl text-gray-500 font-bold">
                Edit Week Pattern
              </h1>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 justify-between gap-3 lg:h-[90%]">
              <div className="w-[100%] flex flex-col gap-3 mt-3 overflow-y-auto edit-week-days">
                {Object.keys(weekSchedule).map((dayOfWeek2) => (
                  <EditDayToggle
                    day={week[Number(dayOfWeek2)]}
                    dayOfWeek={Number(dayOfWeek2)}
                    weekSchedule={weekSchedule}
                    setSchedule={setWeekSchedule}
                    daySchedule={weekSchedule[Number(dayOfWeek2)]}
                    setDayOfWeek={setDayOfWeek}
                    active={dayOfWeek}
                    video={false}
                    videoSchedule={weekVideoSchedule}
                  />
                ))}
              </div>
              {Object.keys(daySchedule.sessions).map((shift: string) => {
                return (
                  <div className="bg-[#F6F6F6] p-4 rounded-xl mt-3">
                    <div className="flex items-center justify-between">
                      {((daySchedule.sessions[shift].selected && video) ||
                        !video) && (
                        <p
                          className={`text-md text-[#8A8A8A] ${daySchedule.selected}`}
                        >
                          {week[dayOfWeek]}{" "}
                          {shift.charAt(0).toUpperCase() + shift.slice(1)}{" "}
                          Session
                        </p>
                      )}
                      {!video && (
                        <Toggle
                          icons={false}
                          checked={
                            daySchedule.sessions[shift].selected &&
                            weekSchedule[dayOfWeek].selected
                          }
                          onChange={() =>
                            setWeekSchedule((val) => {
                              var data = val;
                              data[dayOfWeek].sessions[shift].selected =
                                !data[dayOfWeek].sessions[shift].selected;
                              return { ...data };
                            })
                          }
                        />
                      )}
                    </div>
                    {daySchedule.sessions[shift].selected &&
                      weekSchedule[dayOfWeek].selected && (
                        <>
                          <div className="flex flex-row items-center justify-between mt-4 gap-2">
                            <div className="w-40%">
                              <p className="text-black font-semibold text-sm mb-1">
                                From
                              </p>
                              {renderTimeDropdown(
                                dayOfWeek,
                                shift,
                                "start_time",
                                shift_start[shift],
                                "physical",
                                daySchedule.sessions[shift].availability_id
                              )}
                            </div>

                            <div>
                              <p className="text-black font-semibold text-sm mb-1">
                                To
                              </p>
                              {renderTimeDropdown(
                                dayOfWeek,
                                shift,
                                "end_time",
                                shift_end[shift],
                                "physical",
                                daySchedule.sessions[shift].availability_id
                              )}
                            </div>
                          </div>
                          {userData?.role === "MasterAdmin" && (
                            <>
                              <div>
                                <div>
                                  <p className="font-semibold text-black text-sm mt-4">
                                    Queue Type
                                  </p>
                                  <div className="mt-2 grid grid-cols-2 justify-between gap-3 w-full mb-4">
                                    {queueOptions.map((e) =>
                                      weekSchedule[dayOfWeek].sessions[shift]
                                        .queue_type !== e.value ? (
                                        <button
                                          className="text-sm !rounded-lg !border-[0.5px] !border-sbBorder p-2 hover:bg-[#C3D2FF] hover:text-[#335FE4]"
                                          onClick={() =>
                                            handleChange(
                                              e.value,
                                              dayOfWeek,
                                              shift,
                                              "queue_type"
                                            )
                                          }
                                        >
                                          {e.label}
                                        </button>
                                      ) : (
                                        <button
                                          className="text-sm !rounded-lg !border-[0.5px] !border-sbBorder p-2 bg-[#C3D2FF] text-[#335FE4]"
                                          onClick={() =>
                                            handleChange(
                                              e.value,
                                              dayOfWeek,
                                              shift,
                                              "queue_type"
                                            )
                                          }
                                        >
                                          {e.label}
                                        </button>
                                      )
                                    )}
                                  </div>

                                  {/* <ReactDropdown
                                    options={queueOptions}
                                    onChange={(e) => {
                                      console.log("e.value", e.value);
                                      handleChange(
                                        e.value,
                                        dayOfWeek,
                                        shift,
                                        "queue_type"
                                      );
                                    }}
                                    value={
                                      weekSchedule[dayOfWeek].sessions[shift]
                                        .queue_type
                                    }
                                    placeholder="Select Queue Type"
                                    className="w-32 md:w-44"
                                    controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                                  /> */}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-3">
                                {weekSchedule[dayOfWeek].sessions[shift]
                                  .queue_type === "Token" && (
                                  <div>
                                    <p className="font-semibold text-black text-sm mb-1 mt-2">
                                      Max whatsapp patients
                                    </p>
                                    <input
                                      type="text"
                                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full text-sm"
                                      placeholder="Enter number"
                                      // max={50}
                                      // maxLength={2}
                                      min={0}
                                      value={
                                        weekSchedule[dayOfWeek].sessions[shift]
                                          .max_wa_tokens || ""
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          dayOfWeek,
                                          shift,
                                          "max_wa_tokens"
                                        )
                                      }
                                    />
                                  </div>
                                )}
                                {weekSchedule[dayOfWeek].sessions[shift]
                                  .queue_type === "Token" && (
                                  <div className="flex flex-col mt-2">
                                    <p className="font-semibold text-black text-sm mb-1 h-10">
                                      Token Type
                                    </p>
                                    <ReactDropdown
                                      options={tokenOptions}
                                      onChange={(e) => {
                                        handleChange(
                                          e.value,
                                          dayOfWeek,
                                          shift,
                                          "token_type"
                                        );
                                      }}
                                      value={
                                        weekSchedule[dayOfWeek].sessions[shift]
                                          .token_type
                                      }
                                      placeholder="Select"
                                      className="w-full"
                                      controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder text-sm"
                                    />
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          <div className="flex flex-row mt-2 gap-3">
                            <div className="flex flex-col w-[60%]">
                              <p className="text-sm mb-1 font-semibold text-black">
                                Duration For Each (mins)
                              </p>
                              <input
                                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full text-sm"
                                placeholder="Enter duration"
                                // disabled
                                type="text"
                                min={1}
                                max={60}
                                maxLength={2}
                                value={
                                  weekSchedule[dayOfWeek].sessions[shift]
                                    .wait_time.minutes
                                }
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    dayOfWeek,
                                    shift,
                                    "wait_time"
                                  )
                                }
                              />
                            </div>
                            <div className="flex flex-col w-[40%]">
                              <p className="font-semibold text-black text-sm mb-1">
                                No. of Patients
                              </p>
                              <input
                                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full text-sm"
                                placeholder="Enter number"
                                disabled
                                type="text"
                                max={50}
                                maxLength={2}
                                value={
                                  weekSchedule[dayOfWeek].sessions[shift]
                                    .max_patients_per_slot
                                }
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    dayOfWeek,
                                    shift,
                                    "max_patients_per_slot"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          videoVisible && (
            <div className="rounded-b-lg px-6 border-[1px] border-t-0 border-doctorsBorder p-4 lg:h-[78vh]">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl text-gray-500 font-bold">
                  Edit Video Week Pattern
                </h1>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-4 justify-between gap-3 lg:h-[90%]">
                <div className="w-[100%] flex flex-col gap-3 mt-3 overflow-y-auto edit-week-days">
                  {Object.keys(weekSchedule).map((dayOfWeek2) => (
                    <EditDayToggle
                      day={week[Number(dayOfWeek2)]}
                      dayOfWeek={Number(dayOfWeek2)}
                      weekSchedule={weekSchedule}
                      setSchedule={setWeekSchedule}
                      daySchedule={weekSchedule[Number(dayOfWeek2)]}
                      setDayOfWeek={setDayOfWeek}
                      active={dayOfWeek}
                      video={true}
                      videoSchedule={weekVideoSchedule}
                    />
                  ))}
                </div>
                {Object.keys(daySchedule.sessions).map((shift: string) => {
                  return (
                    <>
                      {daySchedule.sessions[shift].selected &&
                        daySchedule.sessions[shift].queue_type !== "Token" && (
                          <div className="bg-[#F6F6F6] p-4 rounded-xl mt-3">
                            <div className="flex items-center justify-between">
                              {((daySchedule.sessions[shift].selected &&
                                video) ||
                                !video) && (
                                <p
                                  className={`text-md text-[#8A8A8A] ${daySchedule.selected}`}
                                >
                                  {week[dayOfWeek]}{" "}
                                  {shift.charAt(0).toUpperCase() +
                                    shift.slice(1)}{" "}
                                  Session
                                </p>
                              )}
                              {!video && (weekVideoSchedule.filter(
                                (e) =>
                                  e.availability_id ===
                                  daySchedule.sessions[shift]
                                    .availability_id
                              ).length>0) && (
                                <Toggle
                                  icons={false}
                                  checked={
                                    weekVideoSchedule.filter(
                                      (e) =>
                                        e.availability_id ===
                                        daySchedule.sessions[shift]
                                          .availability_id
                                    ).length > 0
                                      ? weekVideoSchedule.filter(
                                          (e) =>
                                            e.availability_id ===
                                            daySchedule.sessions[shift]
                                              .availability_id
                                        )[0].selected
                                      : false
                                  }
                                  onChange={() =>
                                    handleVideoChange(
                                      daySchedule.sessions[shift]
                                        .availability_id,
                                      "selected",
                                      !weekVideoSchedule.filter(
                                        (e) =>
                                          e.availability_id ===
                                          daySchedule.sessions[shift]
                                            .availability_id
                                      )[0].selected
                                    )
                                  }
                                />
                              )}
                            </div>
                            {daySchedule.sessions[shift].selected &&
                              weekSchedule[dayOfWeek].selected && (
                                <>
                                  <div className="flex flex-row items-center justify-between mt-4 gap-2">
                                    <div className="w-40%">
                                      <p className="text-black font-semibold text-sm mb-1">
                                        From
                                      </p>
                                      {renderTimeDropdown(
                                        dayOfWeek,
                                        shift,
                                        "start_time",
                                        shift_start[shift],
                                        "video",
                                        daySchedule.sessions[shift]
                                          .availability_id
                                      )}
                                    </div>

                                    <div>
                                      <p className="text-black font-semibold text-sm mb-1">
                                        To
                                      </p>
                                      {renderTimeDropdown(
                                        dayOfWeek,
                                        shift,
                                        "end_time",
                                        shift_end[shift],
                                        "video",
                                        daySchedule.sessions[shift]
                                          .availability_id
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex flex-row mt-6 gap-3">
                                    <div className="flex flex-col w-[60%]">
                                      <p className="text-sm mb-1 font-semibold text-black">
                                        Duration For Each (mins)
                                      </p>
                                      <input
                                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full text-sm"
                                        placeholder="Enter duration"
                                        // disabled
                                        type="text"
                                        min={1}
                                        max={60}
                                        maxLength={2}
                                        value={
                                          weekVideoSchedule.filter(
                                            (e) =>
                                              e.availability_id ===
                                              daySchedule.sessions[shift]
                                                .availability_id
                                          ).length > 0
                                            ? weekVideoSchedule.filter(
                                                (e) =>
                                                  e.availability_id ===
                                                  daySchedule.sessions[shift]
                                                    .availability_id
                                              )[0].max_consultation_duration
                                                .minutes
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleVideoChange(
                                            daySchedule.sessions[shift]
                                              .availability_id,
                                            "max_consultation_duration.minutes",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex flex-col w-[40%]">
                                      <p className="font-semibold text-black text-sm mb-1">
                                        No. of Patients
                                      </p>
                                      <input
                                        className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full text-sm"
                                        placeholder="Enter number"
                                        disabled
                                        type="text"
                                        min={0}
                                        max={50}
                                        maxLength={2}
                                        value={
                                          weekVideoSchedule.filter(
                                            (e) =>
                                              e.availability_id ===
                                              daySchedule.sessions[shift]
                                                .availability_id
                                          ).length > 0
                                            ? weekVideoSchedule.filter(
                                                (e) =>
                                                  e.availability_id ===
                                                  daySchedule.sessions[shift]
                                                    .availability_id
                                              )[0].max_consultations_per_session
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleVideoChange(
                                            daySchedule.sessions[shift]
                                              .availability_id,
                                            "max_consultations_per_session",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        )}
                    </>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
      {holiday && (
        <HolidayModal
          modalIsOpen={holiday}
          closeModal={() => setHoliday(false)}
          customStyles={modalStyles}
          session={session}
          docAvail={docAvail}
          startDate={start}
          endDate={end}
          generateEventsForMonth={generateEventsForMonth}
          setEvents={setEvents}
        />
      )}

      {rangeholiday && (
        <RangeHolidayModal
          modalIsOpen={rangeholiday}
          closeModal={() => {
            setRangeHoliday(false);
          }}
          customStyles={modalStyles}
          session={session}
          docAvail={docAvail}
          doctor_id={doctor_id}
          startDate={String(selectedDates?.start)}
          endDate={String(selectedDates?.end)}
          generateEventsForMonth={generateEventsForMonth}
          setEvents={setEvents}
        />
      )}
    </>
  );
};

export default SessionInfo;
