import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/organisms/Header";
// import { useUserData } from "../lib/contexts/UserContext";
// import { Logout } from "../assets/icons/Icons";
// import PasswordModal from "../components/organisms/modals/PasswordModal";
// import { modalStyles } from "../assets/styles/modalStyles";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
// import { getRouteSegment } from "../lib/utils/funcs";
// import DoctorButtonMap from "../components/organisms/DoctorButtonMap";
// import { getButtonsByHospitalId } from "../lib/apis/button";
// import { Buttons, Doctor } from "../lib/utils/types";
// import { getDoctorListByHospitalId } from "../lib/apis/doctor";
// import { useDropzone } from "react-dropzone";
import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
// import { storage, app } from "../firebase";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
// import { doc, getFirestore, setDoc } from "firebase/firestore";
import { insertSupport } from "../lib/apis/support";
import { useNavigate } from "react-router-dom";

interface ReportFields {
  email: string | undefined;
  hospital_id: string | undefined;
  request_type: string | undefined;
  request_subject: string | undefined;
  request_description: string | undefined;
  request_status: string | undefined;
  request_priority: string | undefined;
}

const ReportBug = () => {
  // const db = getFirestore(app);
  const { hospData, hospitalID } = useHospDocData();

  const [fields, setFields] = useState<ReportFields>({
    hospital_id: hospitalID,
    email: "",
    request_type: "",
    request_subject: "",
    request_description: "",
    request_status: "Open",
    request_priority: "High",
  });

  const handleChange = (e: any) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  // const [files, setFiles] = useState<File[]>([]);
  const [other, setOther] = useState("");

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   setFiles(acceptedFiles);
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: {
  //     "image/jpeg": [],
  //     "image/png": [],
  //     "image/jpg": [],
  //   },
  // });
  // const fileList = files.map((file) => (
  //   <li key={file.name}>
  //     {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
  //   </li>
  // ));
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (
      !fields.request_type ||
      !fields.request_description ||
      !fields.request_status ||
      !fields.request_subject ||
      !fields.email
    ) {
      toast.error("Enter all fields");
      return;
    } else {
      const res = await insertSupport(fields);
      console.log(res);

      if (res?.status === 200 || res?.status === 201) {
        toast.success("Bug Reported");
        setFields({
          hospital_id: hospitalID,
          email: "",
          request_type: "",
          request_subject: "",
          request_description: "",
          request_status: "Open",
          request_priority: "High",
        });
        navigate("/" + hospitalID + "/dashboard");
      } else toast.error("Failed to Report");
    }

    // try {
    //   const storageRef = ref(storage, `reportBug/${fields.hosp_name}`);
    //   const uploadTask = uploadBytesResumable(storageRef, files[0]);

    //   uploadTask.on(
    //     "state_changed",
    //     (snapshot) => {
    //       const progress =
    //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       console.log(`Upload is ${progress}% done`);
    //     },
    //     (error) => {
    //       console.log(error);
    //     },
    //     async () => {
    //       const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    //       console.log("Download URL:", downloadURL);
    //       setFields((prevFields) => ({ ...prevFields, ss: downloadURL }));

    //       await setDoc(doc(db, "reportbug", String(fields.hosp_name)), {
    //         hospitalName: fields.hosp_name,
    //         description: fields.desc,
    //         screenshot: downloadURL,
    //       });

    //       toast.success("Bug submitted successfully");
    //     }
    //   );
    // } catch (error) {
    //   console.error("Error submitting report:", error);
    //   toast.error("Error submitting report");
    // }
  };

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Support"} />
      <div className="bg-white flex flex-col mt-6 ml-7 mr-11 rounded-lg p-4">
        {/* <div>
          <p className="text-modalText text-md mb-2 font-bold">Hospital Name</p>
          <input
            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full"
            placeholder="Hospital Name"
            name="hosp_name"
            value={fields.hosp_name}
            onChange={handleChange}
            required
          />
        </div> */}
        <div>
          <p className="text-modalText text-md mb-2 font-bold mt-8">
            Email{" "}
            <span className="text-red-500 text-sm relative bottom-1 font-medium">
              *
            </span>
          </p>
          <input
            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full"
            placeholder="youremail@domain.com"
            name="email"
            value={fields.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-row space-x-6 mt-8">
          <div className="flex flex-col w-full">
            <p className="text-modalText text-md mb-1 font-bold">
              Issue Type{" "}
              <span className="text-red-500 text-sm relative bottom-1 font-medium">
                *
              </span>
            </p>
            <select
              name="type"
              id="type"
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full h-12 mt-2"
              onChange={(e) => {
                setFields({ ...fields, request_type: e.target.value });
              }}
            >
              <option value="">Select Type</option>
              <option value="Operational">Operational</option>
              <option value="Technical">Technical</option>
              <option value="Billing">Billing</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        {fields.request_type === "Other" && (
          <input
            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full mt-4"
            placeholder="Issue type"
            name="request_type"
            value={other}
            onChange={(e) => setOther(e.target.value)}
            required
          />
        )}
        <div>
          <p className="text-modalText text-md mb-2 font-bold mt-8">
            Issue Title{" "}
            <span className="text-red-500 text-sm relative bottom-1 font-medium">
              *
            </span>
          </p>
          <input
            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full"
            placeholder="System Login Issue"
            name="request_subject"
            value={fields.request_subject}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <p className="text-modalText text-md mb-2 mt-8 font-bold">
            Brief description of the issue{" "}
            <span className="text-red-500 text-sm relative bottom-1 font-medium">
              *
            </span>
          </p>
          <textarea
            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full min-h-32 max-h-32"
            placeholder="Unable to log into the patient management system. The login screen is not responding after entering credentials."
            name="request_description"
            value={fields.request_description}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div className="p-0 max-h-96 overflow-y-auto">
          <p className="text-modalText text-md mb-4 mt-8 font-bold">
            Upload screenshot of issue
          </p>
          <div>
            {files.length === 0 && (
              <div
                {...getRootProps({
                  className:
                    "dropzone flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 h-[100px]",
                })}
              >
                <input {...getInputProps()} type="file" />
                <p className="text-gray-500">
                  {isDragActive
                    ? "Drop the files here ..."
                    : "Drag & drop files here, or click to select files"}
                </p>
              </div>
            )}
            {files.length !== 0 && (
              <aside>
                <h4 className="font-semibold mt-3">Uploaded file:</h4>
                {fileList}
              </aside>
            )}
          </div>
          
        </div> */}
        <DarkBlueButton
          name="Submit"
          type="button"
          handleClick={handleSubmit}
          className="w-full !m-0 !mt-10 h-14"
        />
      </div>
    </div>
  );
};

export default ReportBug;
