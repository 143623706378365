import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import ReactDropdown, { Option } from "react-dropdown";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import {
  Booking,
  Doctor,
  Hospital,
  MedicineField,
} from "../../../lib/utils/types";
import { getDate, getRouteSegment } from "../../../lib/utils/funcs";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PrescriptionPdf from "../../../screens/PrescriptionPdf";
import { getMedicines, insertMedicine } from "../../../lib/apis/medicines";
import axios from "axios";
import { FaPlus } from "react-icons/fa6";
import { baseURL } from "../../../lib/utils/constants";
import { MdDelete } from "react-icons/md";
import QRCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import { whatsappLogoBase64 } from "../../../assets/icons/Icons";
import { getHosptialDetails } from "../../../lib/apis/hospital";

const PrescriptionModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  bookings,
  docDetails,
  booking_id,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  docDetails: Doctor | undefined;
  bookings: Array<Booking> | undefined;
  booking_id: string | undefined;
}) => {
  const hospital_id = getRouteSegment(1);
  const [hospData, setHospData] = useState<Hospital>();

  const generateQRCodeWithLogo = async (text: string) => {
    try {
      const qrCodeDataUrl = await QRCode.toDataURL(text);

      const canvas = createCanvas(500, 500);
      const ctx = canvas.getContext("2d");
      const qrImage = await loadImage(qrCodeDataUrl);
      if (qrImage) {
        ctx.drawImage(qrImage, 0, 0, 500, 500);
      } else {
        throw new Error("QR image failed to load");
      }

      const logoImage = await loadImage(whatsappLogoBase64);
      if (logoImage && logoImage.width) {
        const logoSize = 100;
        const logoX = (canvas.width - logoSize) / 2;
        const logoY = (canvas.height - logoSize) / 2;
        ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
      } else {
        throw new Error("Logo image failed to load or is undefined");
      }
      return canvas.toDataURL();
    } catch (error) {
      console.error("Error generating QR code with logo", error);
    }
  };
  useEffect(() => {
    const fetchHosData = async () => {
      const hosp_data = await getHosptialDetails(hospital_id);

      if (hosp_data?.status === 200) {
        setHospData(hosp_data.data.result);
      }

      const qrCodeUrl = await generateQRCodeWithLogo(
        "https://api.whatsapp.com/send/?phone=%2B" +
          hosp_data.data.result.hospital_contact_number +
          "&text=Hi&type=phone_number&app_absent=0"
      );
      setQRCodeDataUrl(qrCodeUrl);
    };
    fetchHosData();
  }, [hospital_id]);
  // console.log(hospData);

  const [qrCodeDataUrl, setQRCodeDataUrl] = useState<string | undefined>(
    undefined
  );

  const [medicineNames, setMedicineNames] = useState([{ product_name: "" }]);
  useEffect(() => {
    const fetchMedicines = async () => {
      const res = await getMedicines();
      if (res?.status === 200) {
        setMedicineNames(res.data.result);
        // console.log(res.data.result);
      }
    };
    fetchMedicines();
  }, []);

  // const options = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const [submitted, setSubmitted] = useState(false);

  const [fields, setFields] = useState<MedicineField[]>([
    {
      name: "",
      morning: "",
      afternoon: "",
      night: "",
      days: "",
      morningTime: "BF",
      afternoonTime: "BF",
      nightTime: "BF",
      new: true,
    },
  ]);
  const [search, setSearch] = useState("");
  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], [name]: value };
    if (name === "name") {
      setSearch(newFields[index].name);
    }

    setFields(newFields);
  };

  // const handleQtyChange = (index: number, event: Option, name: string) => {
  //   const newFields = [...fields];
  //   newFields[index] = { ...newFields[index], [name]: event.value };
  //   setFields(newFields);
  // };

  const handleAddField = () => {
    setFields([
      ...fields,
      {
        name: "",
        morning: "",
        afternoon: "",
        night: "",
        days: "",
        morningTime: "BF",
        afternoonTime: "BF",
        nightTime: "BF",
        new: true,
      },
    ]);
    setSearch("");
  };

  const deleteField = (index: number) => {
    if (fields.length > 1) {
      setFields(fields.filter((_, i) => i !== index));
    } else {
      toast.error("Only one field present");
    }
  };

  const uploadFile = async (url: string, file: File) => {
    await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    // const presignedUrlResponse = await axios.post(
    //   `${baseURL}/prescriptions/put-presigned-url`,
    //   {
    //     bookingId: booking_id,
    //     patientId: String(
    //       bookings?.filter((data) => data.booking_id === booking_id)[0]
    //         .patient_id
    //     ),
    //   }
    // );
    // console.log(presignedUrlResponse);

    // const putUrl = presignedUrlResponse.data.url;
    // console.log("putUrl", putUrl);
  };

  const generatePdfAndUpload = async () => {
    const doc = (
      <PrescriptionPdf
        data={fields}
        hospital_id={hospital_id}
        hospData={hospData}
        qrCodeDataUrl={qrCodeDataUrl}
        doctorName={docDetails?.full_name}
        doctor={docDetails}
        date={getDate(
          String(
            bookings?.filter((data) => data.booking_id === booking_id)[0]
              .booked_date
          )
        )}
        patientName={String(
          bookings?.filter((data) => data.booking_id === booking_id)[0]
            .full_name
        )}
        gender={String(
          bookings?.filter((data) => data.booking_id === booking_id)[0].gender
        )}
      />
    );
    const asPdf = pdf(); // [] is an empty cache
    asPdf.updateContainer(doc);

    const blob = await asPdf.toBlob();

    // Convert Blob to File
    const file = new File([blob], "prescription.pdf", {
      type: "application/pdf",
    });

    return file;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log(fields);
    fields.map(async (val) => {
      if (val.new) {
        const res = await insertMedicine(val.name);
        if (res.status === 200) {
          console.log("Medicine Added");
        }
      }
    });
    let count = 0;
    for (let index = 0; index < fields.length; index++) {
      if (fields[index].name === "") {
        toast.error("Please fill all the inputs");
        return;
      } else if (
        fields[index].morning === "" ||
        fields[index].afternoon === "" ||
        fields[index].night === ""
      ) {
        toast.error("Please fill all the inputs of " + fields[index].name);
        return;
      } else if (fields[index].days === "0") {
        toast.error("Please enter days more than 0");
        return;
      }
      count++;
    }
    if (count === fields.length) {
      setSubmitted(true);
      try {
        const file = await generatePdfAndUpload();
        console.log(file);

        const presignedUrlResponse = await axios.post(
          `${baseURL}/prescriptions/put-presigned-url`,
          {
            bookingId: booking_id,
            patientId: String(
              bookings?.filter((data) => data.booking_id === booking_id)[0]
                .patient_id
            ),
          }
        );
        const putUrl = presignedUrlResponse.data.url;
        console.log("GetUrl: ", putUrl);

        await uploadFile(putUrl, file);
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Failed to upload file.");
      }
    }
  };

  const filteredAndSearchedMedicine =
    search !== ""
      ? medicineNames?.filter((d) =>
          d.product_name.toLowerCase().includes(search.toLowerCase())
        )
      : [];
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Create Prescription</p>
        {submitted && (
          <CloseButton
            handleClick={() => {
              closeModal();
              setFields([
                {
                  name: "",
                  morning: "",
                  afternoon: "",
                  night: "",
                  days: "",
                  morningTime: "BF",
                  afternoonTime: "BF",
                  nightTime: "BF",
                  new: true,
                },
              ]);
              setSubmitted(false);
            }}
          />
        )}
        {!submitted && (
          <button
            className="btn flex items-center bg-white text-black border border-[#242220] rounded-md text-md font-[500] p-4 py-2 hover:bg-black hover:text-white"
            onClick={handleAddField}
          >
            <FaPlus className="mr-2" />
            Add more medicine
          </button>
        )}
      </div>

      {/* Body */}
      {submitted && (
        <>
          <PDFViewer className="w-full h-[70vh] overflow-y-auto">
            <PrescriptionPdf
              data={fields}
              hospital_id={hospital_id}
              doctor={docDetails}
              hospData={hospData}
              qrCodeDataUrl={qrCodeDataUrl}
              doctorName={docDetails?.full_name}
              date={getDate(
                String(
                  bookings?.filter((data) => data.booking_id === booking_id)[0]
                    .booked_date
                )
              )}
              patientName={String(
                bookings?.filter((data) => data.booking_id === booking_id)[0]
                  .full_name
              )}
              gender={String(
                bookings?.filter((data) => data.booking_id === booking_id)[0]
                  .gender
              )}
            />
          </PDFViewer>
          {/* Footer */}
          <div className="py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
            <WhiteButton
              name="Edit"
              className="w-full h-14"
              handleClick={() => {
                setSubmitted(false);
              }}
            />
            {/* <DarkBlueButton name="Save" type="submit" className="w-full h-14" /> */}
            <PDFDownloadLink
              document={
                <PrescriptionPdf
                  data={fields}
                  hospital_id={hospital_id}
                  hospData={hospData}
                  qrCodeDataUrl={qrCodeDataUrl}
                  doctor={docDetails}
                  doctorName={docDetails?.full_name}
                  date={getDate(
                    String(
                      bookings?.filter(
                        (data) => data.booking_id === booking_id
                      )[0].booked_date
                    )
                  )}
                  patientName={String(
                    bookings?.filter(
                      (data) => data.booking_id === booking_id
                    )[0].full_name
                  )}
                  gender={String(
                    bookings?.filter(
                      (data) => data.booking_id === booking_id
                    )[0].gender
                  )}
                />
              }
              fileName="Prescription.pdf"
              className="w-full h-14"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading..."
                ) : (
                  <DarkBlueButton
                    name="Download"
                    type="button"
                    className="w-full h-14"
                  />
                )
              }
            </PDFDownloadLink>
          </div>
        </>
      )}
      {!submitted && (
        <form onSubmit={handleSubmit} className="mb-0 px-6">
          <div className=" max-h-96 overflow-y-auto">
            {fields.map((field, index) => (
              <div className="bg-[#F6F6F6] p-4 rounded-lg mt-4 mb-4">
                <div className="flex justify-between mb-2">
                  <p className="text-[#949494] text-sm">Medicine {index + 1}</p>
                  <MdDelete
                    className="w-6 h-6 text-red-600 relative top-0 transition-all hover:top-[-3px] cursor-pointer"
                    onClick={() => {
                      deleteField(index);
                    }}
                  />
                </div>
                <div className="border border-t-0 border-x-0 pb-6 border-sbBorder border-b-0">
                  <div className="flex justify-between gap-4">
                    <div className="w-full">
                      <div className="flex justify-between gap-4">
                        <p className="font-medium w-[40%] text-left">
                          Medicine Name
                        </p>
                      </div>
                      <input
                        type="text"
                        name="name"
                        placeholder="Medicine Name"
                        className="w-full mt-2 rounded-lg px-3 py-2 border-[0.5px] border-sbBorder"
                        value={field.name}
                        onChange={(event) => handleChange(index, event)}
                      />
                      {search !== "" && index === fields.length - 1 && (
                        <div className="px-0 rounded-lg bg-white  relative shadow-lg mt-2 w-full z-20 max-h-60 overflow-y-auto">
                          {" "}
                          {filteredAndSearchedMedicine?.map((medData, ind) => (
                            <div
                              className="flex gap-4 items-center  hover:bg-queueHover hover:text-white rounded-md p-4 cursor-pointer"
                              onClick={() => {
                                const newFields = [...fields];
                                newFields[index] = {
                                  ...newFields[index],
                                  name: medData.product_name,
                                };
                                newFields[index] = {
                                  ...newFields[index],
                                  new: false,
                                };
                                setSearch("");
                                setFields(newFields);
                              }}
                            >
                              <div>
                                <p className="text-md">
                                  {medData.product_name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="flex justify-between gap-4">
                        <p className="font-medium w-[40%] text-left">Days</p>
                      </div>
                      <input
                        type="text"
                        name="days"
                        placeholder="Days"
                        className="w-full mt-2 rounded-lg px-3 py-2 border-[0.5px] border-sbBorder"
                        value={field.days}
                        onChange={(event) => handleChange(index, event)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 mt-4">
                    <p className="font-medium w-full text-left">Morning</p>
                    <p className="font-medium w-full text-left">Afternoon</p>
                    <p className="font-medium w-full text-left">Night</p>
                  </div>
                  <div
                    key={index}
                    className="flex w-full justify-between gap-4 mt-2"
                  >
                    {/* <ReactDropdown
                    options={options}
                    value={field.morning}
                    onChange={(event) =>
                      handleQtyChange(index, event, "morning")
                    }
                    placeholder="Qty"
                    className="w-[20%]"
                    controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                  /> */}
                    <div className="flex-col items-center justify-center w-full">
                      <input
                        type="text"
                        name="morning"
                        placeholder="Qty"
                        className="w-full rounded-lg px-3 py-2 border-[0.5px] border-sbBorder"
                        value={field.morning}
                        onChange={(event) => handleChange(index, event)}
                      />
                      <div className="flex items-center justify-center w-full mt-3">
                        <label
                          id={"toggleA" + index}
                          className="flex items-center cursor-pointer"
                        >
                          {/* label */}
                          <div className="text-gray-700 font-medium mr-3">
                            BF
                          </div>
                          {/* toggle */}
                          <div className="relative">
                            {/* input */}
                            <input
                              type="checkbox"
                              id={"toggleA" + index}
                              className="sr-only"
                              onChange={() => {
                                const newFields = [...fields];
                                newFields[index] = {
                                  ...newFields[index],
                                  morningTime:
                                    fields[index].morningTime === "BF"
                                      ? "AF"
                                      : "BF",
                                };
                                setFields(newFields);
                                console.log(newFields);
                              }}
                            />
                            {/* line */}
                            <div className="block bg-white border border-[#335FE4] w-10 h-6 rounded-full" />
                            {/* dot */}
                            <div className="dot absolute left-1 top-[3px] bg-[#335FE4] w-4 h-4 rounded-full transition" />
                          </div>
                          {/* label */}
                          <div className=" text-gray-700 font-medium ml-3">
                            AF
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="flex-col items-center justify-center w-full">
                      <input
                        type="text"
                        name="afternoon"
                        placeholder="Qty"
                        className="w-full rounded-lg px-3 py-2 border-[0.5px] border-sbBorder"
                        value={field.afternoon}
                        onChange={(event) => handleChange(index, event)}
                      />
                      <div className="flex items-center justify-center w-full mt-3">
                        <label
                          id={"toggleB" + index}
                          className="flex items-center cursor-pointer"
                        >
                          {/* label */}
                          <div className="text-gray-700 font-medium mr-3">
                            BL
                          </div>
                          {/* toggle */}
                          <div className="relative">
                            {/* input */}
                            <input
                              type="checkbox"
                              id={"toggleB" + index}
                              className="sr-only"
                              onChange={() => {
                                const newFields = [...fields];
                                newFields[index] = {
                                  ...newFields[index],
                                  afternoonTime:
                                    fields[index].afternoonTime === "BF"
                                      ? "AF"
                                      : "BF",
                                };
                                setFields(newFields);
                                console.log(newFields);
                              }}
                            />
                            {/* line */}
                            <div className="block bg-white border border-[#335FE4] w-10 h-6 rounded-full" />
                            {/* dot */}
                            <div className="dot absolute left-1 top-[3px] bg-[#335FE4] w-4 h-4 rounded-full transition" />
                          </div>
                          {/* label */}
                          <div className=" text-gray-700 font-medium ml-3">
                            AL
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="flex-col items-center justify-center w-full">
                      <input
                        type="text"
                        name="night"
                        placeholder="Qty"
                        className="w-full rounded-lg px-3 py-2 border-[0.5px] border-sbBorder"
                        value={field.night}
                        onChange={(event) => handleChange(index, event)}
                      />
                      <div className="flex items-center justify-center w-full mt-3">
                        <label
                          id={"toggleC" + index}
                          className="flex items-center cursor-pointer"
                        >
                          {/* label */}
                          <div className="text-gray-700 font-medium mr-3">
                            BL
                          </div>
                          {/* toggle */}
                          <div className="relative">
                            {/* input */}
                            <input
                              type="checkbox"
                              id={"toggleC" + index}
                              className="sr-only"
                              onChange={() => {
                                const newFields = [...fields];
                                newFields[index] = {
                                  ...newFields[index],
                                  nightTime:
                                    fields[index].nightTime === "BF"
                                      ? "AF"
                                      : "BF",
                                };
                                setFields(newFields);
                                console.log(newFields);
                              }}
                            />
                            {/* line */}
                            <div className="block bg-white border border-[#335FE4] w-10 h-6 rounded-full" />
                            {/* dot */}
                            <div className="dot absolute left-1 top-[3px] bg-[#335FE4] w-4 h-4 rounded-full transition" />
                          </div>
                          {/* label */}
                          <div className=" text-gray-700 font-medium ml-3">
                            AL
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <DarkBlueButton
              name="Add More Fields"
              type="button"
              className="w-max mt-8 mb-8"
              handleClick={handleAddField}
            /> */}
          </div>

          {/* Footer */}
          <div className="py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
            <WhiteButton
              name="Cancel"
              className="w-full h-14"
              handleClick={() => {
                closeModal();
                setFields([
                  {
                    name: "",
                    morning: "",
                    afternoon: "",
                    night: "",
                    days: "",
                    morningTime: "BF",
                    afternoonTime: "BF",
                    nightTime: "BF",
                    new: true,
                  },
                ]);
                setSubmitted(false);
              }}
            />
            <DarkBlueButton name="Save" type="submit" className="w-full h-14" />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default PrescriptionModal;
