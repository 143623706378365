import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { Doctor, Hospital, MedicineField } from "../lib/utils/types";
import moment from "moment";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 900,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.5,
  },
  header: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  headerImg: {
    width: "100%",
    objectFit: "cover",
  },
  section: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 10,
    fontFamily: "Open Sans",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    fontWeight: "bold",
  },
  text: {
    fontWeight: "normal",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#AFAFAF",
    borderBottomStyle: "solid",
    paddingBottom: 5,
    marginBottom: 5,
    fontSize: 14,
  },
  tableRow: {
    flexDirection: "row",
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableCol: {
    flex: 1,
    textAlign: "center",
  },
  tableColLeft: {
    flex: 1,
    textAlign: "left",
  },
  tableColRight: {
    flex: 1,
    textAlign: "right",
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  doctorContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});

interface PrescriptionPdfProps {
  data: MedicineField[];
  hospital_id: string;
  doctorName: string | undefined;
  patientName: string;
  gender: string;
  date: string;
  doctor: Doctor | undefined;
  hospData: Hospital | undefined;
  qrCodeDataUrl: string | undefined;
}

const PrescriptionPdf: React.FC<PrescriptionPdfProps> = ({
  data,
  hospital_id,
  hospData,
  date,
  qrCodeDataUrl,
  doctorName,
  gender,
  patientName,
  doctor,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Document>
      {loading && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.header, { height: "auto" }]} fixed>
            <View
              style={[
                styles.row,
                {
                  marginTop: 20,
                  justifyContent: "space-between",
                },
              ]}
            >
              <View
                style={[
                  styles.row,
                  {
                    gap: "10px",
                    width: "250px",
                  },
                ]}
              >
                <Image
                  src={hospData?.logo}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "contain",
                  }}
                />
                <View>
                  <Text
                    style={[
                      styles.label,
                      {
                        fontWeight: 900,
                        fontFamily: "Open Sans",
                        fontSize: 20,
                        marginTop: 10,
                      },
                    ]}
                  >
                    {hospData?.hospital_name}
                  </Text>
                  <Text style={[styles.label, { fontStyle: "italic" }]}>
                    {hospData?.hospital_address}
                  </Text>
                  <Text
                    style={[
                      styles.label,
                      {
                        fontWeight: 900,
                        fontFamily: "Open Sans",
                      },
                    ]}
                  >
                    Contact: +91 {hospData?.hospital_contact_number}
                  </Text>
                </View>
              </View>
              <View>
                <Image
                  src={String(qrCodeDataUrl)}
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "auto",
                  }}
                />
                <Text style={[styles.label, { fontSize: 8, margin: "auto" }]}>
                  Scan to book appointment
                </Text>
              </View>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={[styles.section]}>
              <View style={styles.doctorContainer}>
                <View style={{ width: "80%" }}>
                  <View
                    style={[
                      styles.row,
                      {
                        marginTop: 10,
                        justifyContent: "flex-start",
                        gap: "10px",
                      },
                    ]}
                  >
                    <Text style={[styles.sectionTitle, { marginBottom: 0 }]}>
                      Dr. {doctorName}
                    </Text>
                    <Text
                      style={[
                        styles.text,
                        {
                          fontSize: 14,
                          fontWeight: "thin",
                          fontFamily: "Open Sans",
                          marginTop: 1,
                        },
                      ]}
                    >
                      {doctor?.specialization}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row,
                      {
                        justifyContent: "flex-start",
                        gap: "10px",
                        marginTop: 0,
                        maxWidth: "60%",
                      },
                    ]}
                  >
                    <Text style={[styles.label, { fontStyle: "italic" }]}>
                      {doctor?.qualification}
                    </Text>
                  </View>
                </View>
                <View style={{ width: "20%" }}>
                  <View
                    style={[
                      styles.row,
                      {
                        marginTop: 10,
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.label,
                        {
                          fontWeight: 900,
                          fontFamily: "Open Sans",
                        },
                      ]}
                    >
                      Date
                    </Text>
                    <Text style={[styles.text, { marginTop: 2 }]}>
                      {moment(date).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Patient Information</Text>
              <View
                style={[
                  styles.row,
                  { justifyContent: "flex-start", gap: "20px" },
                ]}
              >
                <Text style={[styles.label, { width: "100px" }]}>
                  Patient Name
                </Text>
                <Text
                  style={[
                    styles.text,
                    {
                      fontWeight: "bold",
                      fontFamily: "Open Sans",
                    },
                  ]}
                >
                  {patientName}
                </Text>
              </View>
              <View
                style={[
                  styles.row,
                  { justifyContent: "flex-start", gap: "20px" },
                ]}
              >
                <Text style={[styles.label, { width: "100px" }]}>Gender</Text>
                <Text
                  style={[
                    styles.text,
                    {
                      fontWeight: "bold",
                      fontFamily: "Open Sans",
                    },
                  ]}
                >
                  {gender}
                </Text>
              </View>
            </View>

            <View style={[styles.section, { border: "0" }]}>
              <Text style={styles.sectionTitle}>Prescription</Text>
              <View style={styles.tableHeader}>
                <View style={styles.tableColLeft}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontWeight: "bold",
                        fontFamily: "Open Sans",
                        fontSize: 12,
                      },
                    ]}
                  >
                    Medicine Name
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontWeight: "bold",
                        fontFamily: "Open Sans",
                        fontSize: 12,
                      },
                    ]}
                  >
                    Dosage
                  </Text>
                </View>
                <View style={styles.tableColRight}>
                  <Text
                    style={[
                      styles.tableCell,
                      {
                        fontWeight: "bold",
                        fontSize: 12,
                        fontFamily: "Open Sans",
                      },
                    ]}
                  >
                    Duration
                  </Text>
                </View>
              </View>
              {data.map((row, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableColLeft}>
                    <Text style={styles.tableCell}>{row.name}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <View style={{ textAlign: "left", marginLeft: "60px" }}>
                      {parseInt(row.morning) !== 0 ? (
                        <Text style={[styles.tableCell, { margin: 2 }]}>
                          {row.morning} Morning [{row.morningTime}]
                        </Text>
                      ) : (
                        ""
                      )}
                      {parseInt(row.afternoon) !== 0 ? (
                        <Text style={[styles.tableCell, { margin: 2 }]}>
                          {row.afternoon} Afternoon [{row.afternoonTime}]
                        </Text>
                      ) : (
                        ""
                      )}
                      {parseInt(row.night) !== 0 ? (
                        <Text style={[styles.tableCell, { margin: 2 }]}>
                          {row.night} Night [{row.nightTime}]
                        </Text>
                      ) : (
                        ""
                      )}
                    </View>
                  </View>
                  <View style={[styles.tableColRight]}>
                    <Text style={styles.tableCell}>
                      {row.days} {row.days === "1" ? "Day" : "Days"}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default PrescriptionPdf;
