import React, { useCallback, useEffect, useState } from "react";
import { LeftArrow, RightArrow } from "../../../assets/icons/Icons";
import NotFound from "../../atoms/buttons/NotFound";
import SelectSlot from "../../atoms/SelectSlot";
import SelectToken from "../../atoms/SelectToken";
import {
  estimateVisitTime,
  noExtendSession,
} from "../../../lib/utils/constants";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import moment from "moment";
import { AddBookingState, PatientState } from "../../../services/types";
import { Option } from "react-dropdown";
import { getRouteSegment } from "../../../lib/utils/funcs";
import { useParams } from "react-router-dom";
import { getSlots, getTokens } from "../../../lib/apis/booking";
import { useUserData } from "../../../lib/contexts/UserContext";
import ExtendSession from "./ExtendSession";

const Step3 = ({
  patient,
  formState,
  options,
  session,
  queue_type,
  bookingSlot,
  token,
  setPatient,
  setFormState,
  setSession,
  setQueue_type,
  setBookingSlot,
  setToken,
  closeModal,
  resetStates,
  setStep,
  hospital_id,
}: {
  formState: AddBookingState;
  patient: PatientState;
  session: Option | undefined;
  queue_type: string;
  hospital_id: string;
  options: Option[] | undefined;
  bookingSlot: string | undefined;
  token: number | undefined;
  setFormState: React.Dispatch<React.SetStateAction<AddBookingState>>;
  setPatient: React.Dispatch<React.SetStateAction<PatientState>>;
  setSession: React.Dispatch<React.SetStateAction<Option | undefined>>;
  setQueue_type: React.Dispatch<React.SetStateAction<string>>;
  setBookingSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
  setToken: React.Dispatch<React.SetStateAction<number | undefined>>;
  closeModal: () => void;
  resetStates: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { SelectedDate, setSelectedDate, setIndex } = usePatientBooking();
  const param = useParams();
  const mapping_id = String(param.mapping_id);
  const { userData } = useUserData();

  const showNext = () => {
    // Ensure we don't go beyond the length of the data array
    setLoading(true);

    console.log(formState.currentIndex + 10, formState.allSlots.length);
    if (formState.currentIndex + 10 < formState.allSlots.length) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex + 10,
      }));
      setLoading(false);
    }
  };

  // Function to show the previous 10 items
  const showPrevious = () => {
    // Ensure we don't go below 0
    if (formState.currentIndex - 10 >= 0) {
      setFormState((prevState) => ({
        ...prevState,
        currentIndex: formState.currentIndex - 10,
      }));
    }
  };

  const [loading, setLoading] = useState(true);
  const showSlotsTokens = useCallback(async () => {
    setFormState((prevState) => ({
      ...prevState,
      allTokens: [0],
      allSlots: [""],
    }));
    if (!mapping_id || !session) return;
    // console.log(session);
    const [start_time, end_time] = String(session?.label)?.split(" - ");

    const data = {
      mapping_id: mapping_id,
      booked_date: SelectedDate,
      number_of_people: 1,
      availability_id: session.value,
      start_time: moment(start_time, "hh:mmA").format("HH:mm:ss"),
      end_time: moment(end_time, "hh:mmA").format("HH:mm:ss"),
    };

    if (queue_type !== "Token") {
      // console.log(data);
      const slots_data = await getSlots(data);
      // console.log(slots_data?.data);

      if (slots_data?.status === 200 && slots_data.data.result.length !== 0) {
        const api_data: { start: string; end: string }[] =
          slots_data.data.result;

        // console.log("API Data:", api_data);

        const allSlots =
          SelectedDate === moment().format("YYYY-MM-DD")
            ? api_data
                .filter((slot) => moment(slot.end, "HH:mm").isAfter(moment()))
                .map((slot) => `${slot.start} - ${slot.end}`)
            : api_data.map((slot) => `${slot.start} - ${slot.end}`);

        // console.log("All Slots:", allSlots);

        const slicedData = allSlots.slice(
          formState.currentIndex,
          formState.currentIndex + 10
        );

        // console.log("Sliced Data:", slicedData);

        if (allSlots) {
          setFormState((prevState) => ({
            ...prevState,
            allSlots: allSlots.length === 0 ? [""] : allSlots,
            slicedSlots: allSlots.length === 0 ? [""] : slicedData,
          }));
          setBookingSlot(slicedData.slice(0, 1)[0]);
        }
        setLoading(false);
      } else if (slots_data?.data.length === 0) {
        setFormState((prevState) => ({
          ...prevState,
          allSlots: [""],
        }));
        setLoading(false);
      }
    }

    if (queue_type === "Token") {
      // console.log(data);
      const token_data = await getTokens(data);
      console.log(token_data?.data);

      if (token_data?.status === 200 && token_data.data.result.length !== 0) {
        const api_data: Array<number> = token_data.data.result;
        const allTokens =
          SelectedDate === moment().format("YYYY-MM-DD") &&
          moment().isAfter(
            moment(String(session?.label).split(" - ")[1], "hh:mmA").add(
              30,
              "minutes"
            )
          )
            ? [0]
            : userData?.user_id === "5a6155df-6b50-4621-a035-c1953bb0f249"
            ? api_data.filter((token) => token % 2 !== 0)
            : api_data;
        // moment().isAfter(moment(String(session?.label).split(" - ")[1], "hh:mmA"))

        // console.log("allTokens", allTokens);

        const slicedData = allTokens.slice(
          formState.currentIndex,
          formState.currentIndex + 10
        );

        // console.log("slicedData", slicedData);

        if (allTokens.length !== 0) {
          setFormState((prevState) => ({
            ...prevState,
            allTokens: allTokens,
            slicedTokens: slicedData,
          }));
          setToken(slicedData.slice(0, 1)[0]);
          // console.log(slicedData.slice(0, formState.people)[0]);
        }
        setLoading(false);
      } else if (token_data?.data.length === 0) {
        setFormState((prevState) => ({
          ...prevState,
          allTokens: [0],
        }));
        setLoading(false);
      }
    }
  }, [mapping_id, session, SelectedDate, formState.currentIndex]);

  useEffect(() => {
    showSlotsTokens();
  }, [, session, showSlotsTokens]);
  console.log(
    !(
      queue_type === "Token" &&
      formState.allTokens.length === 1 &&
      formState.allTokens[0] === 0
    ) ||
      (queue_type !== "Token" &&
        formState.allSlots.length === 1 &&
        formState.allSlots[0] === "")
  );
  console.log(!noExtendSession.includes(hospital_id));

  return (
    <>
      <>
        {!(
          (queue_type === "Token" &&
            formState.allTokens.length === 1 &&
            formState.allTokens[0] === 0) ||
          (queue_type !== "Token" &&
            formState.allSlots.length === 1 &&
            formState.allSlots[0] === "")
        ) &&
          !noExtendSession.includes(hospital_id) && (
            <div>
              {SelectedDate === moment().format("YYYY-MM-DD") && (
                <div className="flex flex-col w-full mb-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    Booking Type
                  </p>
                  <div className="flex flex-row space-x-3">
                    <button
                      className={`${
                        formState.type === "booking"
                          ? "bg-editBG text-white"
                          : "hover:bg-purple-400 hover:text-white"
                      } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
                      onClick={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          type: "booking",
                        }))
                      }
                      type="button"
                    >
                      Booking
                    </button>
                    <button
                      className={`${
                        formState.type === "walk-in"
                          ? "bg-editBG text-white"
                          : " hover:bg-purple-400 hover:text-white"
                      } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
                      onClick={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          type: "walk-in",
                        }))
                      }
                      type="button"
                    >
                      Walk-In
                    </button>
                  </div>
                </div>
              )}
              <div>
                <p className="font-light text-modalText text-sm mb-1">
                  Full Name
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter full name"
                  type="text"
                  value={patient.full_name}
                  disabled={patient.registered}
                  onChange={(e) =>
                    setPatient((prevState) => ({
                      ...prevState,
                      full_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row md:space-x-3 w-full">
                <div className="mt-3 w-full md:w-1/2">
                  <p className="font-light text-modalText text-sm mb-1">
                    Comments
                  </p>
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    placeholder="Add comments"
                    value={formState.comments}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        comments: e.target.value,
                      }))
                    }
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-row mt-3">
                  <div className="flex flex-col w-full">
                    <p className="font-light text-modalText text-sm mb-1 ">
                      Gender
                    </p>
                    <div className="flex flex-row space-x-3">
                      <button
                        className={`${
                          patient.gender?.toLowerCase() === "male"
                            ? "bg-editBG text-white"
                            : "hover:bg-purple-400 hover:text-white"
                        } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
                        disabled={patient.registered}
                        onClick={() =>
                          setPatient((prevState) => ({
                            ...prevState,
                            gender: "Male",
                          }))
                        }
                        type="button"
                      >
                        Male
                      </button>
                      <button
                        className={`${
                          patient.gender?.toLowerCase() === "female"
                            ? "bg-editBG text-white"
                            : "hover:bg-purple-400 hover:text-white"
                        } border-[1px] border-editBG text-editBG px-4 py-2 rounded-[20px]`}
                        disabled={patient.registered}
                        onClick={() =>
                          setPatient((prevState) => ({
                            ...prevState,
                            gender: "Female",
                          }))
                        }
                        type="button"
                      >
                        Female
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Booking For
                </p>
                <div className="flex flex-row">
                  <input
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-2"
                    placeholder="Select Date"
                    type="date"
                    value={SelectedDate}
                    min={moment().format("YYYY-MM-DD")}
                    max={moment().add(2, "weeks").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      const dayOfWeek = moment(selectedDate).day() + 1;
                      setIndex(dayOfWeek);
                      setSelectedDate(e.target.value);
                    }}
                  />
                  {options && options.length > 0 ? (
                    <select
                      value={session?.value}
                      className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                      onChange={(e) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          allSlots: [""],
                          allTokens: [0],
                        }));
                        const label =
                          e.target.options[e.target.selectedIndex].text;
                        const value = e.target.value;
                        setSession({ label, value });
                        console.log(
                          "queue_type",
                          options?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        );
                        setQueue_type(
                          String(
                            options?.filter(
                              (session) => session.value === value
                            )[0].data?.queue_type
                          )
                        );
                      }}
                    >
                      <option disabled hidden>
                        {String(session?.label).split(" - ")[0] +
                          " - " +
                          String(session?.label).split(" - ")[1]}
                      </option>
                      {options.map((session) => (
                        <option key={session.value} value={session.value}>
                          {String(session?.label).split(" - ")[0] +
                            " - " +
                            String(session?.label).split(" - ")[1]}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <NotFound text="No sessions" />
                  )}
                </div>
              </div>
              {queue_type !== "Session" &&
              noExtendSession.includes(hospital_id) ? (
                formState.type !== "walk-in" && (
                  <div className="flex flex-col w-full my-3">
                    <p className="font-light text-modalText text-sm mb-1">
                      {estimateVisitTime.includes(String(hospital_id))
                        ? "Estimate Visit Time"
                        : "Choose slot timings"}
                    </p>
                    {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                    <div>
                      {formState.currentIndex > 0 && (
                        <button
                          type="button"
                          className="bg-white p-2 rounded-lg hover:opacity-70"
                          onClick={showPrevious}
                        >
                          <LeftArrow />
                        </button>
                      )}
                      <div className="flex flex-wrap justify-start my-3 gap-3 mt-0">
                        {queue_type === "Token" ? (
                          formState.slicedTokens.length !== 0 &&
                          formState.slicedTokens[0] !== 0 ? (
                            formState.slicedTokens.map((item, index) => (
                              <React.Fragment key={index}>
                                <SelectToken
                                  selected={token === item}
                                  setToken={setToken}
                                  item={item}
                                />
                              </React.Fragment>
                            ))
                          ) : (
                            <NotFound text="No Tokens Available" />
                          )
                        ) : formState.slicedSlots.length !== 1 ||
                          formState.slicedSlots[0] !== "" ? (
                          formState.slicedSlots.map((item, index) => (
                            <React.Fragment key={index}>
                              <SelectSlot
                                item={item}
                                setSlot={setBookingSlot}
                                selected={bookingSlot?.includes(item) || false}
                                people={1}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <NotFound text="No Slots Available" />
                        )}
                      </div>
                      {formState.currentIndex + 10 <
                        formState.allSlots.length && (
                        <button
                          type="button"
                          className="bg-white p-2 rounded-lg hover:opacity-70"
                          onClick={showNext}
                        >
                          <RightArrow />
                        </button>
                      )}
                    </div>
                  </div>
                )
              ) : (
                <div className="flex flex-col w-full my-3">
                  <p className="font-light text-modalText text-sm mb-1">
                    {estimateVisitTime.includes(String(hospital_id))
                      ? "Estimate Visit Time"
                      : "Choose slot timings"}
                  </p>
                  {/* <div className="flex flex-row space-x-3 w-[292px] md:w-[490px] overflow-x-auto"> */}
                  <div>
                    {formState.currentIndex > 0 && (
                      <button
                        type="button"
                        className="bg-white p-2 rounded-lg hover:opacity-70"
                        onClick={showPrevious}
                      >
                        <LeftArrow />
                      </button>
                    )}
                    <div className="flex flex-wrap justify-start gap-3 my-3">
                      {queue_type === "Token" ? (
                        formState.slicedTokens.length !== 0 &&
                        formState.slicedTokens[0] !== 0 ? (
                          formState.slicedTokens.map((item, index) => (
                            <React.Fragment key={index}>
                              <SelectToken
                                selected={token === item}
                                setToken={setToken}
                                item={item}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <NotFound text="No Tokens Available" />
                        )
                      ) : formState.slicedSlots.length !== 1 ||
                        formState.slicedSlots[0] !== "" ? (
                        formState.slicedSlots.map((item, index) => (
                          <React.Fragment key={index}>
                            <SelectSlot
                              item={item}
                              setSlot={setBookingSlot}
                              selected={bookingSlot?.includes(item) || false}
                              people={1}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <NotFound text="No Slots Available" />
                      )}
                    </div>
                    {formState.currentIndex + 10 <
                      formState.allSlots.length && (
                      <button
                        type="button"
                        className="bg-white p-2 rounded-lg hover:opacity-70"
                        onClick={showNext}
                      >
                        <RightArrow />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        {((queue_type === "Token" &&
          formState.allTokens.length === 1 &&
          formState.allTokens[0] === 0) ||
          (queue_type !== "Token" &&
            formState.allSlots.length === 1 &&
            formState.allSlots[0] === "")) &&
          !noExtendSession.includes(hospital_id) &&
          !loading && (
            <ExtendSession
              session={session}
              setSession={setSession}
              closeModal={closeModal}
              resetStates={resetStates}
              setStep={setStep}
            />
          )}
      </>
    </>
  );
};

export default Step3;
