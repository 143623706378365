import axios from "axios";
import { baseURL } from "../utils/constants";
import { header } from "../utils/header";

// {{base-url}}/api/v1/queue/hospital/70665cc2-a5e2-407a-8feb-0a5578f9f853/buttons

export const getButtonsByHospitalId = async (
  hospitalId: string | undefined
) => {
  try {
    const res = await axios.get(
      `${baseURL}/queue/hospital/${hospitalId}/buttons`,
      {
        headers: header(),
      }
    );
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Add button mapping
export const addButtonMapping = async (
  buttonId: string,
  mappingIds: Record<string, Array<string>>
) => {
  try {
    const res = await axios.post(
      `${baseURL}/queue/button/${buttonId}`,
      mappingIds,
      {
        headers: header(),
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Remove button mapping
export const removeButtonMapping = async (
  buttonId: string,
  mappingId: string
) => {
  try {
    const res = await axios.delete(
      `${baseURL}/queue/mapping/${buttonId}/${mappingId}`,
      {
        headers: header(),
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
