import React from "react";

const QueueEmpty = ({ text }: { text: string }) => {
  return (
    <div className="py-10 flex flex-col justify-center items-center">
      <img
        src={require("../../assets/images/queueEmpty.png")}
        alt="Queue Empty"
        className="w-1/2 h-auto"
      />
      <p className="text-sbTextHover pt-4 font-medium">{text}</p>
    </div>
  );
};

export default QueueEmpty;
