const getModalStyles = () => {
  const isMobile = window.innerWidth < 768;

  return {
    overlay: {
      backgroundColor: "rgba(2, 11, 23, 0.32)",
      blurRadius: 2,
      zIndex: "999",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: 0,
      borderRadius: 0,
      zIndex: 10,
      minWidth: isMobile ? "340px" : "550px",
      maxWidth: isMobile ? "340px" : "550px",
      maxHeight: "90vh",
      overflow: "hidden",
    },
  };
};

export const switchBranch = {
  overlay: {
    backgroundColor: "rgba(2, 11, 23, 0.32)",
    blurRadius: 2,
  },
  content: {
    top: "62px",
    right: "20px",
    left: "auto",
    bottom: "auto",
    transform: "translate(0, 0)",
    padding: 0,
    borderRadius: "8px",
    minWidth: "280px",
  },
};

export const modalStyles = getModalStyles();
